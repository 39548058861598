import React, { useEffect, useState } from 'react';

import {
  Button,
  Card, Col, Form, Row, Select,
} from 'antd';

import {
  FormItemSelect,
  FormItemInput,
  FormItemRangePicker,
} from '../../../components/ant-wrapper/form-item';
import { ESTADO_CIVIL_FIELDS, SEXO_FIELDS } from '../../../components/utils';
import { getClinicas } from '../../../services/clinicas';
import { getCidades, getDistricts } from '../../../services/districts';
import { getPacientes } from '../../../services/pacientes';
import { getProfissionais } from '../../../services/profissional';
import { gerarRelatorioClinicas } from '../../../services/relatorios';
import { getUnidades } from '../../../services/unidades';

const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
const cardStyle = { borderColor: 'white' };
export default function RelatorioClinicas() {
  const [form] = Form.useForm();
  const [cidades, setCidades] = useState([]);

  const onSubmit = async (values) => {
    await gerarRelatorioClinicas(values);
  };
  const getData = async () => {
    const cidadesData = await getCidades();
    setCidades(cidadesData);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ width: '100%' }}>
      <Form
        form={form}
        name="register"
        onFinish={onSubmit}
        scrollToFirstError
        {...formItemLayout}
      >
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <FormItemInput name="nome" label="Nome" />

          </Col>

          <Col span={8}>
            <FormItemSelect
              name="cidade"
              label="Cidade"
              items={cidades}
              itemsLabel="cidade"
              itemsValue="cidade"
            />
          </Col>

        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
