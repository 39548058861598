import React, { useEffect, useState } from 'react';

import {
  Alert, Button, Checkbox, Form, Input, Spin,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  BackgroundStyle,
  Center,
  InlineContainer,
  LoginContainer,
  SecondaryText,
  // SecondaryText,
  Title,
} from '../../components/styled';
import { getEntities } from '../../services/entity';
import { getLayout } from '../../services/layout';
import { Creators as AuthActions } from '../../store/ducks/auth';

export default function Login() {
  const dispath = useDispatch();
  const [edit, setEdit] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [entidade, setEntidade] = useState();
  const [background, setBackground] = useState();
  const onFinish = (values) => {
    dispath(AuthActions.loginRequest(values));
    setEdit(true);
  };
  const error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);

  const getData = async () => {
    setLoadingData(true);
    const data = await getEntities();
    try {
      setBackground(await getLayout());
      setEntidade(data);
    } finally {
      setLoadingData(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>

      <Center>
        {loadingData ? <center><Spin /></center>
          : (
            <>
              <BackgroundStyle background={background?.background} />
              <LoginContainer>
                <Title style={{ textAlign: 'center' }}>
                  {entidade ? <img style={{ width: '150px' }} src={entidade?.imagem} alt="LOGIN" /> : 'LOGIN'}
                </Title>
                {entidade && (
                <>
                  {' '}
                  <center><div style={{ fontSize: '20px' }}>{entidade?.pessoa_juridica?.nome}</div></center>
                  <SecondaryText>{entidade?.pessoa_juridica?.endereco?.endereco}</SecondaryText>
                  <SecondaryText>{`CEP - ${entidade?.pessoa_juridica?.endereco?.cep} - CNPJ ${entidade?.pessoa_juridica?.cnpj}`}</SecondaryText>
                </>
                )}
                {edit && error.length > 0 && <Alert message={error} type="error" />}
                <br />
                <Form onChange={() => setEdit(false)} name="basic" onFinish={onFinish}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Email é obrigatório!',
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Senha obrigatória!',
                      },
                    ]}
                  >
                    <Input.Password placeholder="Senha" />
                  </Form.Item>
                  <InlineContainer>
                    <Checkbox>Lembrar senha</Checkbox>
                    <Link to="resetar-senha">Esqueceu a senha?</Link>
                  </InlineContainer>
                  <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                      Entrar
                    </Button>
                  </Form.Item>
                  {/* <Link to="register">
            <SecondaryText>
              Não tem uma conta? Faça seu registro aqui
            </SecondaryText>
          </Link> */}
                </Form>
              </LoginContainer>
            </>
          )}
      </Center>

    </div>
  );
}
