export default class ApiException extends Error {
  constructor(error) {
    super(error.error);
    this.name = this.constructor.name;
    this.error = error;
    Object.setPrototypeOf(this, ApiException.prototype);
  }

  error() {
    return this.error;
  }
}
