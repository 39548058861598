import { failureNotification, successNotification } from '../../../utils';
import api from '../../api';
import ApiException from '../../exception/ApiException';

const registerAgenteSaude = async (values) => {
  try {
    await api.post('agentes', values);
    successNotification('Agente de saúde adicionado com sucesso!');
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteAgenteSaude = async (id) => {
  try {
    await api.delete(`agentes/${id}`);
    successNotification('Agente de saúde deletado com sucesso');
  } catch (error) {
    failureNotification('Não foi possível deletar o agente de saúde');
  }
};

const getAgentesSaudes = async (
  page,
  distrito,
  paginated = '',
) => {
  try {
    const idDistrito = distrito.id;
    const { data } = await api.get(
      `agentes?page=${page}&distrito=${idDistrito}&paginated=${paginated}`,
    );
    return data;
  } catch (err) {
    throw new ApiException('Não foi possível obter os dados dos agentes de saúde do distrito');
  }
};

const updateAgenteSaude = async (agente, values) => {
  try {
    values.id = agente.id;
    await api.put('agentes', values);
    successNotification('Agente de saúde atualizado com sucesso!');
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerAgenteSaude,
  deleteAgenteSaude,
  getAgentesSaudes,
  updateAgenteSaude,
};
