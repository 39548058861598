import React from 'react';

import {
  Alert, Button, Card, Col, Form, Row,
} from 'antd';

import EnderecoForm from '../../address/forms';
import { FormItemInput } from '../../ant-wrapper/form-item';
import PessoaFisicaForm from '../../pessoa_fisica/forms';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
const cardStyle = { borderColor: 'white' };

export default function ProfissionalForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
}) {
  const [form] = Form.useForm();

  const onEdit = () => {
    setError();
  };

  return (
    <>
      {error && (
        <>
          <Alert
            message={error}
            type="error"
          />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <Card title="Dados básicos" style={{ ...cardStyle }}>
          <PessoaFisicaForm viewMode={viewMode} form={form} />
          <Row>
            <Col span={8}>
              <FormItemInput
                name="registro"
                label="Registro"
                viewMode={viewMode}
                required
              />
            </Col>
          </Row>
        </Card>
        <Card title="Endereço" style={{ ...cardStyle }}>
          <EnderecoForm
            viewMode={viewMode}
            dataIndex="pessoa_fisica"
            form={form}
          />
        </Card>
        <Row>
          <Col style={{ marginLeft: 25 }} span={16}>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Enviar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
