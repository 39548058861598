import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import FilaEsperaForm from "../../../components/fila_espera/forms";
import { VerticalContainer } from "../../../components/styled";

import { registerFilaEspera } from "../../../services/fila_espera";

export default function AddFilaEspera() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await registerFilaEspera(values);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <VerticalContainer>
      <div />
      <FilaEsperaForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </VerticalContainer>
  );
}
