import React from 'react';

import {
  Alert, Button, Form,
} from 'antd';

import { FormItemInput } from '../../ant-wrapper/form-item';

export default function AgenteSaudeForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
}) {
  const [form] = Form.useForm();
  const onEdit = () => {
    setError();
  };
  return (
    <>
      {error && (
        <>
          <Alert
            message={error}
            type="error"
          />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={initialValues}
      >
        <FormItemInput
          name="nome"
          label="Nome"
          required
        />

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
