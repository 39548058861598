import React from 'react';

import { Result } from 'antd';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="A pagina solicitada não existe."
      extra={<Link to="/">Voltar para o sistema</Link>}
    />
  );
}
