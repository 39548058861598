import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  registerPaciente, updatePaciente, getPacientes, deletePaciente,
} from '../../services/pacientes';
import { Creators as PacienteActions, Types } from '../ducks/pacientes';

function* register(action) {
  try {
    yield call(registerPaciente, action.paciente);
    yield put(PacienteActions.registerPacienteSuccess());
  } catch (error) {
    yield put(PacienteActions.requestPacienteFailure(error?.error));
  }
}

function* update(action) {
  try {
    console.log(action);
    yield call(updatePaciente, action.paciente, action.values);
    yield put(PacienteActions.updatePacienteSuccess());
  } catch (error) {
    console.log(error);
    yield put(PacienteActions.requestPacienteFailure(error?.error));
  }
}

function* getAll() {
  try {
    const pacientes = yield call(getPacientes, '', '', false);
    yield put(PacienteActions.getAllPacienteSuccess(pacientes));
  } catch (error) {
    yield put(PacienteActions.requestPacienteFailure(error?.error));
  }
}

function* deletePacienteSaga(action) {
  try {
    yield call(deletePaciente, action.id);
    yield put(PacienteActions.deletePacienteSuccess());
  } catch (error) {
    yield put(PacienteActions.requestPacienteFailure(error?.error));
  }
}

export function* rootPacienteSaga() {
  yield all([takeLatest(Types.REGISTER_PACIENTE_REQUEST, register)]);
  yield all([takeLatest(Types.GET_ALL_PACIENTE_REQUEST, getAll)]);
  yield all([takeLatest(Types.UPDATE_PACIENTE_REQUEST, update)]);
  yield all([takeLatest(Types.DELETE_PACIENTE_REQUEST, deletePacienteSaga)]);
}
