import React, { useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Table, Space, Popconfirm, Button, Input } from "antd";
import moment from "moment";

import { VerticalContainer } from "../../../components/styled";
import {
  deleteProcedimento,
  getProcedimentos,
} from "../../../services/procedimentos";
import AddProcedimento from "../add";
import EditProcedimento from "../edit";
import { useContext } from "react";
import { UserContext } from "../../../context";

const { Search } = Input;

const { Column, ColumnGroup } = Table;
export default function ListProcedimentos() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleAddProcedimento, setVisibleAddProcedimento] = useState(false);
  const [procedimento, setProcedimento] = useState({});
  const [filter, setFilter] = useState("");
  const [procedimentos, setProcedimentos] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const user = useContext(UserContext);
  const getProcedimentosPaginated = async () => {
    try {
      setLoading(true);
      const procedimentosData = await getProcedimentos(page, filter);
      setProcedimentos(procedimentosData.data);
      setTotal(procedimentosData.total);
    } finally {
      setLoading(false);
    }
  };
  const onConfirm = async () => {
    await getProcedimentosPaginated();
  };

  useEffect(() => {
    getProcedimentosPaginated();
  }, [page, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deleteProcedimento(record.id);
    await getProcedimentosPaginated();
  }
  const showDrawer = (record) => {
    setProcedimento(record);
    setVisible(true);
  };

  function cancel(e) {
    console.log(e);
  }
  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter("");
  };

  return (
    <>
      <VerticalContainer>
        <Search
          onChange={onChange}
          style={{ width: "50%" }}
          placeholder="Pesquisar procedimento"
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            setVisibleAddProcedimento(true);
          }}
          style={{ width: "100%!important" }}
          block
        >
          <PlusOutlined />
          Adicionar procedimento
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{
            width: "100%",
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}
          dataSource={procedimentos}
          rowKey="id"
          loading={loading}
        >
          <ColumnGroup title="Dados básicos">
            <Column width="10%" title="Código" dataIndex="id" key="id" />
            <Column width="15%" title="Nome" dataIndex="nome" key="nome" />
            <Column title="Descrição" dataIndex="descricao" key="descricao" />
            <Column title="Preparo" dataIndex="preparo" key="preparo" />
          </ColumnGroup>
          <Column
            width="15%"
            title="Data de criação"
            dataIndex="created_at"
            render={(data) => moment(data).format("DD/MM/YYYY")}
            key="created_at"
          />
          <Column
            width="20%"
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                {procedimento?.permissao_editar ?
                  <a onClick={() => showDrawer(record)}>
                    Editar
                  </a>
                : ""}
                <Popconfirm
                  title="Deseja deletar o procedimento?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
        {visible ? (
          <EditProcedimento
            visible={visible}
            setVisible={setVisible}
            procedimento={procedimento}
            onConfirm={onConfirm}
          />
        ) : (
          ""
        )}
        {visibleAddProcedimento ? (
          <AddProcedimento
            visible={visibleAddProcedimento}
            setVisible={setVisibleAddProcedimento}
            onConfirm={onConfirm}
          />
        ) : (
          ""
        )}
      </VerticalContainer>
    </>
  );
}
