import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Table, Tag, Space, Popconfirm, Button,
} from 'antd';
import moment from 'moment';

import { VerticalContainer } from '../../../components/styled';
import { deleteUser, getUsers } from '../../../services/users';
import AddUser from '../add';
import EditUserForm from '../edit';

const { Column, ColumnGroup } = Table;
export default function ListUsers() {
  const [visible, setVisible] = useState(false);
  const [visibleAddUser, setVisibleAddUser] = useState(false);
  const [user, setUser] = useState({});
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const getUserPaginated = async () => {
    const usersData = await getUsers(page);
    setUsers(usersData.data);
    setTotal(usersData.total);
  };
  const onConfirm = async () => {
    await getUserPaginated();
  };

  useEffect(() => {
    getUserPaginated();
  }, [page]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deleteUser(record.id);
    await getUserPaginated();
  }
  const showDrawer = (record) => {
    setUser(record);
    setVisible(true);
  };

  function cancel(e) {
    console.log(e);
  }
  return (
    <>
      <VerticalContainer>
        <Button
          type="dashed"
          onClick={() => {
            setVisibleAddUser(true);
          }}
          style={{ width: '100%!important' }}
          block
        >
          <PlusOutlined />
          {' '}
          Adicionar usuário
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{ width: '100%' }}
          dataSource={users}
          rowKey="id"
        >
          <ColumnGroup title="Dados básicos">
            <Column title="Código" dataIndex="id" key="id" />
            <Column title="Nome" dataIndex="name" key="name" />
            <Column title="Email" dataIndex="email" key="email" />
          </ColumnGroup>
          <Column
            title="Tipo"
            dataIndex="type"
            key="type"
            render={(type) => (
              <Tag color={type === 'ADM' ? 'blue' : 'green'} key={type}>
                {type}
              </Tag>
            )}
          />
          <Column
            title="Data de criação"
            dataIndex="created_at"
            render={(data) => moment(data).format('DD/MM/YYYY')}
            key="created_at"
          />
          <Column
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => showDrawer(record)}>Editar</a>
                <Popconfirm
                  title="Deseja deletar o usuário?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
        {visible ? (
          <EditUserForm
            visible={visible}
            setVisible={setVisible}
            user={user}
            onConfirm={onConfirm}
          />
        ) : (
          ''
        )}
        {visibleAddUser ? (
          <AddUser
            visible={visibleAddUser}
            setVisible={setVisibleAddUser}
            onConfirm={onConfirm}
          />
        ) : (
          ''
        )}
      </VerticalContainer>
    </>
  );
}
