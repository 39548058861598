import React, { useState } from "react";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import moment from "moment";
import { Select } from "antd";

const { Option } = Select;
let procedimentosIds = [];
const { confirm } = Modal;
const confirmProcedimentos = (procedimentos, onOk) => {
  const handleChange = (value) => {
    procedimentosIds = value;
  };
  return confirm({
    width: "500px",
    title: "Selecione os procedimentos para geração da solicitação",
    icon: <ExclamationCircleOutlined />,
    content: (
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        onChange={handleChange}
        optionLabelProp="label"
      >
        {procedimentos.length > 0 &&
          procedimentos.map((cli) => (
            <Option key={cli.id} value={cli.nome}>
              {cli.nome}
            </Option>
          ))}
      </Select>
    ),
    okText: "Continuar",
    cancelText: "Cancelar",
    onOk() {
      onOk(procedimentosIds);
    },
    onCancel() {
      console.log("cancel");
    },
  });
};

export default confirmProcedimentos;
