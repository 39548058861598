import React from 'react';

import {
  Alert, Button, Card, Col, Form, Input, Row,
} from 'antd';

import EnderecoForm from '../../address/forms';
import PessoaJuridicaForm from '../../pessoa_juridica/forms';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
const cardStyle = { borderColor: 'white' };

export default function ClinicaForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
}) {
  const [form] = Form.useForm();

  const onEdit = () => {
    setError();
  };

  return (
    <>
      {error && (
        <>
          <Alert
            message={error}
            type="error"
          />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <Card title="Dados básicos" style={{ ...cardStyle }}>
          <PessoaJuridicaForm viewMode={viewMode} form={form} />
          <Row>
            <Col span={16} />
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item name="site" label="Site">
                <Input disabled={viewMode} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card title="Endereço" style={{ ...cardStyle }}>
          <EnderecoForm
            viewMode={viewMode}
            dataIndex="pessoa_juridica"
            form={form}
          />
        </Card>
        <Row>
          <Col style={{ marginLeft: 25 }} span={16}>
            <Form.Item>
              <Button loading={loading} type="primary" htmlType="submit">
                Enviar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
