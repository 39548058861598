import React, { createContext, useEffect } from 'react';

import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Creators as AuthActions } from '../store/ducks/auth';
import { Creators as ClinicaActions } from '../store/ducks/clinica';
import { Creators as CnaeNaturezaActions } from '../store/ducks/cnae-natureza';
import { Creators as EntidadeActions } from '../store/ducks/entidade';
import { Creators as EstadoActions } from '../store/ducks/estados';
import { Creators as PacienteActions } from '../store/ducks/pacientes';
import { Creators as ProfissionalActions } from '../store/ducks/profissional';
import { Creators as UnidadeActions } from '../store/ducks/unidade';
import { isEmpty } from '../utils';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { cnaes } = useSelector((state) => state.cnaeNatureza);
  const { estados: states, cidades } = useSelector((state) => state.estados);
  useEffect(() => {
    if (isEmpty(user)) {
      dispatch(AuthActions.getUserRequest());
    }

    if (cnaes.length <= 0) {
      dispatch(CnaeNaturezaActions.getAllCnaeNatureza());
    }
    dispatch(ProfissionalActions.getAllProfissionalRequest());
    dispatch(UnidadeActions.getAllUnidadeRequest());
    dispatch(ClinicaActions.getAllClinicaRequest());
    dispatch(EntidadeActions.getAllEntidadeRequest());
    if (cidades.length <= 0 || states.length <= 0) {
      dispatch(EstadoActions.getAllEstados());
    }
  }, []);

  return (
    <>
      {isEmpty(user) ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <Spin size="large" />
        </div>
      ) : (
        <UserContext.Provider value={user}>{children}</UserContext.Provider>
      )}
    </>
  );
};
