import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { VerticalContainer } from '../../../components/styled';
import UnidadeForm from '../../../components/unidades/forms';
import { Creators as UnidadeActions } from '../../../store/ducks/unidade';

export default function AddUnidade() {
  const [error, setError] = useState();
  const { loading, error: erro } = useSelector((state) => state.unidade);
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    dispatch(UnidadeActions.registerUnidadeRequest(values));
  };

  useEffect(() => {
    setError(erro);
  }, [erro]);

  return (
    <VerticalContainer>
      <div />
      <UnidadeForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </VerticalContainer>
  );
}
