import styled, { createGlobalStyle } from "styled-components";

export const BackgroundStyle = createGlobalStyle`
  body {
    background-color: rgb(248,248,248);
    background-image : ${(props) => `url('${props.background}')`}; 
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 100%;
    font-family: "Open Sans",sans-serif;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
  }
  .ant-btn-primary{
    width:100%;
  }
`;

export const ErrorText = styled.div`
  color: #ff4d4f;
  text-align: justify;
  margin-bottom: 20px;
`;
export const VerticalContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  color: #393f49;
  font-weight: 300;
  text-transform: none;
  letter-spacing: normal;
  margin-bottom: 40px;
`;

export const TitleHeader = styled(Title)`
  font-size: 25px;
`;

export const Center = styled.div`
  min-width: 400px;
  min-height: 300px;
  padding: 20px;
  max-width: 500px;
  background-color: #fff;
  border-radius: 0.25rem;
  position: absolute;
  left: 50%;
  color: #393f49;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 3px 10px rgb(0 0 0 / 3%);
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const LoginContainer = styled.div`
  /* text-align:center; */
  margin: 20px;
`;

export const SecondaryText = styled.div`
  color: grey;
  font-size: 13px;
  text-align: center;
`;

export const SecondaryTextJustify = styled.div`
  color: grey;
  font-size: 13px;
`;
