import { Button, Card, Spin, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import EditButton from "../../../components/buttons/edit";
import SolicitacaoForm from "../../../components/solicitacoes/forms";
import {
  InlineContainer,
  SecondaryTextJustify,
  TitleHeader,
  VerticalContainer,
} from "../../../components/styled";
import ViewContainer from "../../../components/view_container";
import { SITUACOES } from "../../../models/solicitacoes";
import {
  abrirComprovanteSolicitacao,
  getSolicitacao,
  updateSolicitacao,
} from "../../../services/solicitacoes";
import { UserContext } from "../../../context";

const situacoesStyle = {
  LANÇADO: "yellow",
  CANCELADO: "red",
  APROVADO: "green",
  REALIZADO: "blue",
  NAO_REALIZADO: "red",
};

const prioridadeStyle = {
  ELETIVO: "#73d13d",
  PRIORITARIO: "#fadb14",
  URGENCIA: "#cf1322",
};

export default function EditSolicitacao({ computedMatch }) {
  const [error, setError] = useState();
  const [solicitacao, setSolicitacao] = useState();
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const user = useContext(UserContext);
  const { params } = computedMatch;
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateSolicitacao(solicitacao, values);
    } catch (err) {
      console.log(err);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  const handleSolicitacao = async () => {
    const data = await getSolicitacao(params.id);
    setSolicitacao(data);
  };

  useEffect(() => {
    handleSolicitacao();
  }, []);

  return (
    <>
      {solicitacao ? (
        <VerticalContainer>
          <InlineContainer>
            <TitleHeader>{`Nº - ${solicitacao.numero}`}</TitleHeader>
            {user?.type !== "CLINICA" && (
              <EditButton viewMode={viewMode} setViewMode={setViewMode} />
            )}
          </InlineContainer>
          <Card
            title="Informações"
            style={{ marginTop: "-20px", borderColor: "white" }}
          >
            <SecondaryTextJustify>
              {" "}
              Situação:{" "}
              <Tag color={situacoesStyle[solicitacao.situacao]}>
                {solicitacao.situacao}
              </Tag>
            </SecondaryTextJustify>
            {solicitacao.motivo &&
              solicitacao.situacao === SITUACOES.CANCELADO && (
                <SecondaryTextJustify>
                  Motivo do cancelamento: {solicitacao.motivo}
                </SecondaryTextJustify>
              )}
            <SecondaryTextJustify>
              {" "}
              Lançamento: {solicitacao?.marcador?.name}
            </SecondaryTextJustify>
            <SecondaryTextJustify>
              {" "}
              Prioridade:{" "}
              <Tag color={prioridadeStyle[solicitacao.prioridade]}>
                {solicitacao.prioridade}
              </Tag>
            </SecondaryTextJustify>{" "}
            {viewMode &&
              solicitacao.situacao !== SITUACOES.LANCADO &&
              solicitacao.situacao !== SITUACOES.PENDENTE &&
              solicitacao.situacao !== SITUACOES.CANCELADO && (
                <SecondaryTextJustify>
                  Comprovante:
                  <Button
                    loading={loading}
                    onClick={() => abrirComprovanteSolicitacao(solicitacao.id)}
                    type="link"
                  >
                    Imprimir comprovante
                  </Button>
                </SecondaryTextJustify>
              )}
            {solicitacao.situacao === "CANCELADO" && (
              <SecondaryTextJustify>
                Cancelamento: {solicitacao?.cancelador?.name}
              </SecondaryTextJustify>
            )}
          </Card>
          <ViewContainer item={solicitacao}>
            <SolicitacaoForm
              initialValues={{ ...solicitacao }}
              onFinish={onFinish}
              error={error}
              setError={setError}
              loading={loading}
              viewMode={viewMode}
            />
          </ViewContainer>
        </VerticalContainer>
      ) : (
        <Spin />
      )}
    </>
  );
}
