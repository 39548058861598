import React, { useEffect, useState } from "react";

// import { DeleteOutlined } from '@ant-design/icons';
import { Table, Space, Popconfirm, Checkbox } from "antd";
import history from "../../../routes/history";
import { FormItemInput } from "../../ant-wrapper/form-item";

const { Column } = Table;
export default function ProcedimentosListBox({
  disabled = false,
  procedimentos,
  onDelete,
  onChangeSelection,
  showURL,
  viewMode,
  disableExtraFields = false,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  useEffect(() => {
    setSelectedKeys(
      procedimentos
        .filter(({ pivot }) => pivot?.emergencial)
        .map((proc) => proc.id)
    );

    setSelectedRows(procedimentos.filter(({ pivot }) => pivot?.emergencial));
  }, [procedimentos]);
  function cancel(e) {
    console.log(e);
  }
  return (
    <div>
      <Table
        style={{ pointerEvents: viewMode ? "none" : "all" }}
        rowSelection={
          onChangeSelection
            ? {
                selectedRowKeys: selectedKeys,
                type: "checkbox",
                columnTitle: "Urgência",
                onChange: (a, b) => {
                  onChangeSelection(a, b);
                  setSelectedRows(b);
                  setSelectedKeys(a);
                },
              }
            : undefined
        }
        size="small"
        pagination={false}
        dataSource={procedimentos}
        rowKey="id"
        expandedRowKeys={selectedKeys}
        expandable={{
          expandIcon: () => <></>,
          columnWidth: 0,
          rowExpandable: (record) => {
            return selectedRows.find((s) => s.id === record.id);
          },
          expandedRowRender: (record) => (
            <FormItemInput
              defaultValue={record?.pivot?.justificativa}
              viewMode={viewMode}
              label="Justificativa"
              onChangeInput={(e) => {
                record.pivot = { justificativa: e.target.value };
              }}
            />
          ),
        }}
      >
        <Column title="Código" dataIndex="id" key="id" />
        <Column title="Nome" dataIndex="nome" key="nome" />
        {!disableExtraFields && (
          <Column title="Saldo" dataIndex="saldo" key="saldo" />
        )}
        {!disableExtraFields && (
          <Column
            title="Saldo Emergencial"
            dataIndex="saldo_emergencial"
            key="saldo_emergencial"
          />
        )}
        {showURL && (
          <Column
            title="URL"
            key="solicitacaoUrl"
            render={(text, record) => (
              <Space size="middle">
                {record.solicitacaoUrl ? (
                  <a onClick={() => history.push(record.solicitacaoUrl)}>
                    Visualizar
                  </a>
                ) : (
                  "PENDENTE"
                )}
              </Space>
            )}
          />
        )}
        {!disabled && (
          <Column
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <Popconfirm
                  title="Deseja remover o procedimento escolhido?"
                  onConfirm={() => onDelete(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Remover</a>
                </Popconfirm>
              </Space>
            )}
          />
        )}
      </Table>
    </div>
  );
}
