import React, { useContext, useEffect, useState } from 'react';

import { MedicineBoxOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Spin, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import EditButton from '../../../components/buttons/edit';
import ProfissionalForm from '../../../components/profissional/forms';
import SolicitacoesTable from '../../../components/solicitacoes/table';
import {
  InlineContainer,
  VerticalContainer,
} from '../../../components/styled';
import ViewContainer from '../../../components/view_container';
import {
  getProfissional,
} from '../../../services/profissional';
import { gerarRelatorioSolicitacao } from '../../../services/relatorios';
import { deleteSolicitacao, getSolicitacoesByProfissional } from '../../../services/solicitacoes';
import { Creators as ProfissionalActions } from '../../../store/ducks/profissional';
import { UserContext } from '../../../context';

const { TabPane } = Tabs;

export default function EditProfissional({ computedMatch }) {
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [profissional, setProfissional] = useState();
  const [solicitacoes, setSolicitacoes] = useState();
  const [viewMode, setViewMode] = useState(true);
  const { params } = computedMatch;
  const { loading, error: erro } = useSelector((state) => state.profissional);
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const onFinish = async (values) => {
    dispatch(ProfissionalActions.updateProfissionalRequest(profissional, values));
  };
  const getSolicitacoes = async () => {
    const solicitacoesData = await getSolicitacoesByProfissional(params.id, page);
    setSolicitacoes(solicitacoesData.data);
    setTotal(solicitacoesData.total);
  };
  const handleProfissional = async () => {
    const data = await getProfissional(params.id);
    setProfissional(data);
    await getSolicitacoes();
  };

  async function confirm(record) {
    await deleteSolicitacao(record.id);
    await getSolicitacoes();
  }

  const imprimirRelatorioSolicitacao = async () => {
    await gerarRelatorioSolicitacao({ profissional: JSON.stringify(profissional) });
  };

  useEffect(() => {
    getSolicitacoes();
  }, [page]);

  useEffect(() => {
    setError(erro);
  }, [erro]);

  useEffect(() => {
    handleProfissional();
  }, []);

  return (
    <Tabs defaultActiveKey="1" style={{ width: '100%' }}>
      <TabPane
        tab={(
          <span>
            <MedicineBoxOutlined />
            Detalhes
          </span>
    )}
        key="1"
      >
        <VerticalContainer>
          <InlineContainer>
            <div />
            {profissional?.permissao_editar ?  <EditButton viewMode={viewMode} setViewMode={setViewMode} /> : ""}
          </InlineContainer>
          {profissional ? (
            <ViewContainer item={profissional}>
              <ProfissionalForm
                initialValues={{ ...profissional }}
                onFinish={onFinish}
                error={error}
                setError={setError}
                loading={loading}
                viewMode={viewMode}
              />
            </ViewContainer>
          ) : (
            <Spin />
          )}
        </VerticalContainer>
      </TabPane>
      <TabPane
        tab={(
          <span>
            <TeamOutlined />
            Solicitações
          </span>
        )}
        key="2"
      >
        <VerticalContainer>
          <InlineContainer>
            <div />
            <Button onClick={() => imprimirRelatorioSolicitacao()}>Imprimir</Button>
          </InlineContainer>

          <SolicitacoesTable
            handleTableChange={handleTableChange}
            solicitacoes={solicitacoes}
            total={total}
            confirm={confirm}
          />
        </VerticalContainer>
      </TabPane>
    </Tabs>
  );
}
