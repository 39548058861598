import React, { useState } from "react";

import { Modal } from "antd";

import ProcedimentoForm from "../../../components/procedimentos/form";
import { registerProcedimento } from "../../../services/procedimentos";

export default function AddProcedimento({ visible, setVisible, onConfirm }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await registerProcedimento(values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      console.log(err.error);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Adicionar procedimento"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <ProcedimentoForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </Modal>
  );
}
