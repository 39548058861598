import React from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function ProcedimentoForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
}) {
  const [form] = Form.useForm();
  const onEdit = () => {
    setError();
  };
  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={initialValues}
      >
        <Form.Item
          {...formItemLayout}
          name="nome"
          label={
            <span>
              Nome&nbsp;
              <Tooltip title="Digite o nome do procedimento">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Nome é obrigatório!",
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...formItemLayout} label="Preparo" name="preparo">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item {...formItemLayout} name="descricao" label="Descrição">
          <Input />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
