import { notification } from "antd";
import moment from "moment";

import history from "../../routes/history";
import api from "../api";
import ApiException from "../exception/ApiException";
import FormData from "form-data";

const registerProtocolo = async (values) => {
  try {
    const formData = new FormData();
    values.data_entrada = moment(values.data_entrada).format("Y-M-D HH:mm");
    if (values.data_saida) {
      values.data_saida = moment(values.data_saida).format("Y-M-D HH:mm");
      formData.append("data_saida", values.data_saida);
    }

    formData.append("data_entrada", values.data_entrada);

    if (values.observacao) formData.append("observacao", values.observacao);
    formData.append("paciente_id", values.paciente_id);
    values?.documentos.forEach((documento) => {
      formData.append(`documentos[]`, documento.arquivo);
      formData.append(`tipos[]`, documento.tipo_documento_id);
    });
    await api.post("protocolos", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    history.push("/pacientes/" + values.paciente_id);
    notification.open({
      message: "Protocolo adicionado com sucesso!",
    });
  } catch (error) {
    console.log(error);
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteProtocolo = async (id) => {
  try {
    await api.delete(`protocolos/${id}`);
    notification.open({
      message: "Protocolo deletado com sucesso",
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getProtocolos = async (page, month = "", year = "", filter = "", pacienteId = "") => {
  const { data: protocolos } = await api.get(
    `protocolos?page=${page}&month=${month}&year=${year}&filter=${filter}&pacienteId=${pacienteId}`
  );
  return protocolos;
};

const getProtocolo = async (id) => {
  try {
    const { data } = await api.get(`protocolos/${id}`);
    data.paciente = JSON.stringify(data.paciente);
    if (data.data_entrada)
      data.data_entrada = moment(data.data_entrada, "Y-M-D HH:mm");
    if (data.data_saida) {
      data.data_saida = moment(data.data_saida, "Y-M-D HH:mm");
    } else {
      data.data_saida = moment().local();
    }
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data);
  }
};

const updateProtocolo = async (protocolo, values) => {
  try {
    const formData = new FormData();
    values.data_entrada = moment(values.data_entrada).format("Y-M-D HH:mm");
    if (values.data_saida) {
      values.data_saida = moment(values.data_saida).format("Y-M-D HH:mm");
      formData.append("data_saida", values.data_saida);
    }

    formData.append("id", protocolo.id);
    formData.append("data_entrada", values.data_entrada);
    values?.documentos_removidos.forEach((docRemovido) => {
      formData.append("documentos_removidos[]", docRemovido);
    });

    if (values.observacao) formData.append("observacao", values.observacao);
    if (values.observacao_saida)
      formData.append("observacao_saida", values.observacao_saida);
    formData.append("paciente_id", values.paciente_id);
    console.log(values.documentos);
    values?.documentos.forEach((documento) => {
      formData.append(`documentos[]`, documento.arquivo);
      formData.append(`tipos[]`, documento.tipo_documento_id);
    });
    await api.post(`protocolos/${protocolo.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    notification.open({
      message: "Protocolo atualizado com sucesso!",
    });
  } catch (error) {
    console.log(error);
    throw new ApiException(error?.response?.data?.error);
  }
};

const imprimirProtocolo = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/protocolos/comprovante/${id}`;
  window.open(url, "PRINT", "height=720,width=900");
};

export {
  registerProtocolo,
  deleteProtocolo,
  getProtocolos,
  updateProtocolo,
  getProtocolo,
  imprimirProtocolo
};
