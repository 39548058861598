import { notification } from 'antd';

import history from '../../routes/history';
import { cepMask, toDigitsOnly } from '../../utils';
import api from '../api';
import ApiException from '../exception/ApiException';

const registerUnidade = async (values) => {
  try {
    values.pessoa_juridica.endereco.cep = toDigitsOnly(
      values.pessoa_juridica.endereco.cep,
    );
    await api.post('unidades', values);
    history.push('/unidades');
    notification.open({
      message: 'Unidade adicionada com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteUnidade = async (id) => {
  try {
    await api.delete(`unidades/${id}`);
    notification.open({
      message: 'Unidade deletado com sucesso',
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getUnidades = async (page, filter = '', paginated = '') => {
  const { data } = await api.get(
    `unidades?page=${page}&filter=${filter}&paginated=${paginated}`,
  );
  return data;
};

const getUnidade = async (id) => {
  try {
    const { data } = await api.get(`unidades/${id}`);
    if (data) {
      data.pessoa_juridica.endereco.cep = cepMask(
        data?.pessoa_juridica?.endereco?.cep,
      );
    }
    return data;
  } catch (error) {
    console.log(error);
    throw new ApiException(error?.response?.data?.error);
  }
};

const updateUnidade = async (unidade, values) => {
  try {
    values.id = unidade.id;
    values.pessoa_juridica.endereco_id = unidade.endereco_id;
    values.pessoa_juridica_id = unidade.pessoa_juridica_id;
    values.pessoa_juridica.endereco_id = unidade.pessoa_juridica.endereco_id;
    values.pessoa_juridica.endereco.cep = toDigitsOnly(
      values.pessoa_juridica.endereco.cep,
    );
    await api.put('unidades', values);
    history.push('/unidades');
    notification.open({
      message: 'Unidade atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerUnidade,
  deleteUnidade,
  getUnidades,
  getUnidade,
  updateUnidade,
};
