import React, { useEffect, useState } from 'react';

import {
  Button,
  Card, Col, Form, Row, Select,
} from 'antd';

import {
  FormItemSelect,
  FormItemInput,
  FormItemRangePicker,
} from '../../../components/ant-wrapper/form-item';
import { getClinicas } from '../../../services/clinicas';
import { getPacientes } from '../../../services/pacientes';
import { getProfissionais } from '../../../services/profissional';
import { gerarRelatorioAtendimento } from '../../../services/relatorios';
import { getUnidades } from '../../../services/unidades';

const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
export default function RelatorioAtendimentos() {
  const [form] = Form.useForm();
  const [loadingDados, setloadingDados] = useState(false);
  const [profissionais, setProfissionais] = useState([]);
  const [pacientes, setPacientes] = useState([]);

  const onSubmit = async (values) => {
    await gerarRelatorioAtendimento(values);
  };

  const onSearch = async (result) => {
    const pacientesData = await getPacientes(0, result);
    setPacientes(pacientesData.data);
  }    

  const getData = async () => {
    setloadingDados(true);
    try {
      const pacientesData = await getPacientes(0, "");
    setPacientes(pacientesData.data);
      setProfissionais(await getProfissionais('', '', false));
    } finally {
      setloadingDados(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ width: '100%' }}>
      <Form
        form={form}
        name="register"
        onFinish={onSubmit}
        scrollToFirstError
        {...formItemLayout}
      >

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <FormItemInput name="numero" label="Número" />

          </Col>
          <Col span={8}>
            <FormItemSelect
              name="paciente"
              label="Paciente"
              items={pacientes}
              onSearch={onSearch}
              itemsLabel={['pessoa_fisica', 'nome']}
              stringfyValue
            />
          </Col>
          <Col span={8}>
            <FormItemSelect
              name="profissional"
              label="Profissional"
              items={profissionais}
              itemsLabel={['pessoa_fisica', 'nome']}
              stringfyValue
            />
          </Col>
          <Col span={8}>
            <FormItemRangePicker
              name="datas_lancamento"
              label="Período de lançamento"
            />
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>

      </Form>
    </div>
  );
}
