import { notification } from 'antd';

import history from '../../routes/history';
import api from '../api';
import ApiException from '../exception/ApiException';

const registerProcedimento = async (values) => {
  try {
    await api.post('procedimentos', values);
    history.push('/procedimentos');
    notification.open({
      message: 'Procedimento adicionado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteProcedimento = async (id) => {
  try {
    await api.delete(`procedimentos/${id}`);
    notification.open({
      message: 'Procedimento deletado com sucesso',
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getProcedimentos = async (page, filter = '', paginated = '') => {
  const { data: procedimentos } = await api.get(
    `procedimentos?page=${page}&filter=${filter}&paginated=${paginated}`,
  );
  return procedimentos;
};

const updateProcedimento = async (procedimento, values) => {
  try {
    values.id = procedimento.id;
    await api.put('procedimentos', values);
    history.push('/procedimentos');
    notification.open({
      message: 'Procedimento atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerProcedimento,
  deleteProcedimento,
  getProcedimentos,
  updateProcedimento,
};
