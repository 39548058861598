import React, { useState } from 'react';

import { Modal } from 'antd';

import ProfissionalClinicaForm from '../../../../components/clinicas/forms/profissional';
import { registerProfissionalClinica } from '../../../../services/clinicas/profissional';

export default function AddProfissionalClinica({
  clinica,
  visible,
  setVisible,
  onConfirm,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      values.clinica_id = clinica.id;
      await registerProfissionalClinica(values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      console.log(err.error);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Adicionar profissional"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <ProfissionalClinicaForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </Modal>
  );
}
