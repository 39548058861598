import { Button, DatePicker, Form } from "antd";
import { TitleHeader } from "../../../components/styled";
import { useState } from "react";
import moment from "moment";
import locale from "antd/es/date-picker/locale/pt_BR";
import { gerarRelatorioQuotas } from "../../../services/relatorios";

export default function RelatorioQuotas() {
  const [form] = Form.useForm();
  const [mounth, setMounth] = useState(moment().format("MM"));
  const [year, setYear] = useState(moment().format("YYYY"));

  const onChangeFilter = (value) => {
    const valueFormated = value.format("MM");
    const valueFormatedYear = value.format("YYYY");
    setMounth(valueFormated);
    setYear(valueFormatedYear);
  };

  const handleSubmit = async () => {
    const values = {mes: mounth, ano: year}
    await gerarRelatorioQuotas(values);
  };

  return (
    <div style={{ with: "100%" }}>
      <Form form={form} name="register" onFinish={handleSubmit}>
        <TitleHeader style={{ marginBottom: "15px", fontSize: "15px" }}>
          Selecione o mês para filtrar as quotas
        </TitleHeader>
        <Form.Item>
        <DatePicker
          picker="month"
          format="MMM [de] YYYY"
          onChange={onChangeFilter}
          placeholder="Seleciona a mês"
          locale={locale}
          defaultValue={moment()}
          size="large"
          style={{width: '400px'}}
        />
        </Form.Item>
        <Form.Item>
          <Button type = "primary" htmlType="submit">Gerar relatório de Quotas</Button>
        </Form.Item>
      </Form>
    </div>
  );
}
