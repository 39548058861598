import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import { fetchAllDistricts, fetchAllStates } from '../../services/location';
import { Creators as EstadosActions, Types } from '../ducks/estados';

function* getAll() {
  try {
    const estados = yield call(fetchAllStates);
    const cidades = yield call(fetchAllDistricts);
    yield put(EstadosActions.getAllEstadosSuccess(estados, cidades));
  } catch (error) {
    yield put(EstadosActions.getAllEstadosFailure(error));
  }
}

export function* rootEstadosSaga() {
  yield all([takeLatest(Types.GET_ALL_ESTADOS, getAll)]);
}
