import React from 'react';

import { CloseOutlined, FormOutlined } from '@ant-design/icons';

export default function EditButton({ viewMode, setViewMode }) {
  return (
    <a style={{ color: 'black' }} onClick={() => setViewMode(!viewMode)}>
      {viewMode ? (
        <>
          Editar
          {' '}
          <FormOutlined />
          {' '}
        </>
      ) : (
        <>
          Cancelar
          {' '}
          <CloseOutlined />
          {' '}
        </>
      )}
    </a>
  );
}
