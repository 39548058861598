import { notification } from "antd";
import moment from "moment";

import history from "../../routes/history";
import api from "../api";
import ApiException from "../exception/ApiException";

const registerQuota = async (values) => {
  try {
    values.procedimento_id = JSON.parse(values.procedimento).id;
    values.mes = moment(values.mes).format("Y-M-D");
    await api.post("quotas", values);
    history.push("/quotas");
    notification.open({
      message: "Quota adicionada com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteQuota = async (id) => {
  try {
    await api.delete(`quotas/${id}`);
    notification.open({
      message: "Quota deletada com sucesso",
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const verificarSeCotaDisponivel = async (
  procedimentos,
  dataSolicitacao,
  procedimentos_emergencia
) => {
  dataSolicitacao = moment(dataSolicitacao).format("Y-M-D");
  const { data } = await api.post("quotas/procedimentos", {
    procedimentos,
    procedimentos_emergencia,
    data: dataSolicitacao,
  });
  return data;
};

const getQuotas = async (page, month = "", year = "", filter = "", id = "") => {
  const { data } = await api.get(
    `quotas?page=${page}&month=${month}&year=${year}&filter=${filter}&id=${id}`
  );
  return data;
};

const getCotaByProcedimentoId = async (procedimentoId) => {
  try {
    const { data } = await api.get(`quotas/${procedimentoId}`);
    return data;
  } catch (error) {
    throw new ApiException("Procedimento não encontrado");
  }
};

const updateQuota = async (quota, values) => {
  try {
    values.id = quota.id;
    if (values.mes) {
      values.mes = moment(values.mes).format("Y-M-D");
    }
    values.procedimento_id = JSON.parse(values.procedimento).id;
    delete values.procedimento;
    await api.put("quotas", values);
    history.push("/quotas");
    notification.open({
      message: "Quota atualizada com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerQuota,
  deleteQuota,
  getQuotas,
  updateQuota,
  verificarSeCotaDisponivel,
  getCotaByProcedimentoId,
};
