import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import { SITUACOES } from "../../../models/solicitacoes";
import history from "../../../routes/history";
import { getPaciente, getPacientes } from "../../../services/pacientes";
import { getProcedimentos } from "../../../services/procedimentos";
import { getSolicitacao, updateSolicitacao } from "../../../services/solicitacoes";
import { FormItemSelect } from "../../ant-wrapper/form-item";
import DetailPacientBox from "../../pacientes/datail-box";
import PacientesListBox from "../../pacientes/list-box";
import SkeletonContainer from "../../skeleton-container";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
const cardStyle = { borderColor: "white" };

export default function FilaEsperaForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
  disableButtons = false,
}) {
  const [form] = Form.useForm();
  const [procedimento, setProcedimento] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [paciente, setPaciente] = useState();
  const [prioridade, setPrioridade] = useState("ELETIVO");
  const [procedimentos, setProcedimentos] = useState([]);
  const [pacientesAdicionados, setPacientesAdicionados] = useState([]);
  const [loadingDados, setloadingDados] = useState(false);
  const [loadingAprovar, setLoadingAprovar] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const isAprovable = () => initialValues.situacao !== SITUACOES.APROVADO;

  const onEdit = () => {
    setError();
  };

  const onSearch = async (result) => {
    const pacientesData = await getPacientes(0, result);
    setPacientes(pacientesData.data);
  };

  const getData = async () => {
    setloadingDados(false);
    try {
      if (initialValues.pacientes) {
        const procedimentoAux = JSON.parse(initialValues.procedimento);
        setProcedimento(procedimentoAux);
        let pacientesNaoAprovados = []
        if (initialValues.pacientes?.length > 0) {         
          for (const element of initialValues.pacientes) {
            if(element.pivot.solicitacao_id != null){
              const solicitacao = await getSolicitacao(element.pivot.solicitacao_id);
              if(solicitacao.situacao != "APROVADO"){
                pacientesNaoAprovados.push(element)
              }
            }else{
              pacientesNaoAprovados.push(element)
            }
          }
          if(pacientesNaoAprovados.length > 0){
            setPacientesAdicionados(pacientesNaoAprovados);
          }else{
            setPacientesAdicionados(initialValues.pacientes);
          }
          
        }
      }
      setProcedimentos(await getProcedimentos(0, "", false));
      const { data: pacientesData } = await getPacientes(0, "");

      setPacientes(pacientesData);
    } catch (err) {
      console.log(err);
      setError(err.error);
    } finally {
      setloadingDados(false);
    }
  };
  useEffect(() => {
    const solicitacoes = initialValues.solicitacoes;
    if (solicitacoes?.length > 0) {
      debugger;
      initialValues.pacientes.forEach((proc) => {
        solicitacoes.forEach((sol) => {
          sol.pacientes.forEach((procedimento) => {
            if (procedimento.id === proc.id) {
              proc.solicitacaoUrl = "/solicitacoes/" + sol.id;
            }
          });
        });
      });
    }
    getData();
  }, []);

  const onChangeProcedimento = async (value) => {
    const procedimentoAux = JSON.parse(value);
    setProcedimento(procedimentoAux);
  };

  const onChangePrioridade = async (value) => {
    setPrioridade(value.target.value);
    if (paciente) {
      paciente.pivot = { prioridade: value.target.value };
      ordenarPacientes(paciente);
    }
  };

  const ordenarPacientes = (paciente) => {
    let pacientesAux = [
      ...pacientesAdicionados.filter((pac) => pac !== paciente),
      paciente,
    ];

    const eletivos = pacientesAux.filter(
      (pac) => pac.pivot.prioridade === "ELETIVO"
    );
    const prioritarios = pacientesAux.filter(
      (pac) => pac.pivot.prioridade === "PRIORITARIO"
    );
    const urgentes = pacientesAux.filter(
      (pac) => pac.pivot.prioridade === "URGENCIA"
    );

    pacientesAux = [...urgentes, ...prioritarios, ...eletivos];
    setPacientesAdicionados(pacientesAux);
  };

  const onChangePaciente = async (value) => {
    if (!pacientesAdicionados) {
      setError("Preencha o procedimento antes de adicionar os pacientes");
      return;
    }
    setError("");
    const paciente = JSON.parse(value);
    setPaciente(paciente);
    paciente.pivot = { prioridade: prioridade };
    if (!pacientesAdicionados.find((p) => p.id === paciente.id)) {
      ordenarPacientes(paciente);
    }
  };

  const onDeletePaciente = (value) => {
    const pacientesAux = pacientesAdicionados.filter((p) => p.id !== value.id);
    setPaciente(null);
    setPacientesAdicionados(pacientesAux);
  };

  const onSubmit = async (values) => {
    setLoadingButton(true);
    try {
      values.pacientes = pacientesAdicionados;
      if (!values.pacientes.length) {
        setError("Selecione pelo menos um procedimento");
        return;
      }
      onFinish(values);
    } finally {
      setLoadingButton(false);
    }
  };

  const onApproval = async (paciente) => {
    const filaEsperaAtualizada = { ...initialValues };
    const prioridadePaciente = paciente.pivot.prioridade;
    delete paciente.pivot;
    const solicitacao = {
      procedimentos: [procedimento],
      paciente: JSON.stringify(paciente),
      prioridade: prioridadePaciente,
      filaEspera: JSON.stringify(filaEsperaAtualizada),
    };
    history.push("/adicionar-solicitacao", solicitacao);
    return;
  };

  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onSubmit}
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <div>
          <SkeletonContainer loading={loadingDados}>
            <Col span={12}>
              <Card title="Dados básicos" style={{ ...cardStyle }}>
                <FormItemSelect
                  name="procedimento"
                  label="Procedimento"
                  items={procedimentos}
                  itemsLabel={"nome"}
                  stringfyValue
                  required
                  onChangeSelect={onChangeProcedimento}
                  viewMode={viewMode}
                />
              </Card>
            </Col>
          </SkeletonContainer>

          {!viewMode && (
            <Row gutter={16}>
              <Col span={12}>
                <Card title="Adicionar pacientes" bordered={false}>
                  <SkeletonContainer loading={loadingDados}>
                    <Col span={10}>
                      {!viewMode && (
                        <Select
                          value={null}
                          placeholder="Adicionar pacientes"
                          style={{ width: "100%", marginBottom: "20px" }}
                          onChange={onChangePaciente}
                          onSearch={onSearch}
                          disabled={viewMode}
                          showSearch
                        >
                          {pacientes.length > 0 &&
                            pacientes.map((cli) => (
                              <Option key={cli.id} value={JSON.stringify(cli)}>
                                {cli.pessoa_fisica.nome}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </Col>
                  </SkeletonContainer>
                </Card>
              </Col>

              <Col span={12}>
                <Card title="Prioridade" bordered={false}>
                  <Form.Item name="prioridade">
                    <Radio.Group
                      defaultValue="ELETIVO"
                      buttonStyle="solid"
                      onChange={onChangePrioridade}
                    >
                      <Radio.Button className="rb-1" value="ELETIVO">
                        Eletivo
                      </Radio.Button>
                      <Radio.Button className="rb-2" value="PRIORITARIO">
                        Prioritário
                      </Radio.Button>
                      <Radio.Button className="rb-3" value="URGENCIA">
                        Urgência
                      </Radio.Button>
                    </Radio.Group>
                    <Radio.Group
                      defaultValue="ELETIVO"
                      style={{
                        marginTop: 16,
                      }}
                    />
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          )}
        </div>
        <PacientesListBox
          disabled={viewMode}
          onDelete={onDeletePaciente}
          pacientes={pacientesAdicionados}
          onApproval={onApproval}
          viewMode={viewMode}
          showURL
        />
        <br />
        <br />
        {!disableButtons && (
          <>
            <SkeletonContainer loading={loadingDados}>
              <div style={{ marginLeft: "25px" }}>
                {!viewMode && (
                  <Form.Item>
                    <Button
                      loading={loading || loadingButton}
                      type="primary"
                      htmlType="submit"
                    >
                      Enviar
                    </Button>
                  </Form.Item>
                )}
              </div>
            </SkeletonContainer>
          </>
        )}
      </Form>
    </>
  );
}
