import { useContext, useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Table } from "antd";
import moment from "moment";

import { VerticalContainer } from "../../../components/styled";
import { UserContext } from "../../../context";
import history from "../../../routes/history";
import {
  deleteChave,
  getChavesPagineted,
} from "../../../services/configuracoes";
import AddConfig from "../add";
import AddChave from "../add";

const { Search } = Input;

const { Column } = Table;
export default function ListChaves() {
  const [visibleAddConfig, setVisibleAddConfig] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [configs, setConfigs] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const user = useContext(UserContext);
  const getConfigsPaginated = async () => {
    try {
      setLoading(true);
      const configsData = await getChavesPagineted(page, filter);
      setConfigs(configsData.data);
      setTotal(configsData.total);
    } finally {
      setLoading(false);
    }
  };
  const onConfirm = async () => {
    await getConfigsPaginated();
  };

  const tipoToString = (id) => {
    let value = "";
    switch (id) {
      case 0:
        value = "String";
        break;
      case 1:
        value = "Inteiro";
        break;
      case 2:
        value = "Decimal";
        break;
      case 3:
        value = "Booleano";
        break;
    }

    return value;
  };

  const renderColumn = (config) => {
    if (config != null && config != undefined) {
      switch (config.tipo) {
        case 0:
          return (
            config.string
          );
        case 1:
          return (
            config.inteiro
          );
        case 2:
          return (
            config.decimal
          );
        case 3:
          return (
            config.booleano == 1 ? "Ativada" : "Desativada"
          );
      }
    }
  };

  useEffect(() => {
    getConfigsPaginated();
  }, [page, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  async function confirm(record) {
    await deleteChave(record.id);
    await getConfigsPaginated();
  }

  function cancel(e) {
    console.log(e);
  }
  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter("");
  };

  return (
    <>
      <VerticalContainer>
        <Search
          onChange={onChange}
          style={{ width: "50%" }}
          placeholder="Pesquisar Configuração Avançada"
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            setVisibleAddConfig(true);
          }}
          style={{ width: "100%!important" }}
          block
        >
          <PlusOutlined />
          Adicionar chave de configuração
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{
            width: "100%",
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}
          dataSource={configs}
          rowKey="id"
          loading={loading}
        >
          <Column width="10%" title="Código" dataIndex="id" key="id" />
          <Column width="15%" title="Chave" dataIndex="nome" key="nome" />
          <Column
            width="15%"
            title="Tipo"
            dataIndex="tipo"
            key="tipo"
            render={(data) => tipoToString(data)}
          />
         <Column width="15%" title="Valor" render={(text,record) => renderColumn(record)} />


          <Column
            width="15%"
            title="Data de criação"
            dataIndex="created_at"
            render={(data) => moment(data).format("DD/MM/YYYY")}
            key="created_at"
          />
          <Column
            width="20%"
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => history.push(`configuracoes/${record.id}`)}>
                  Visualizar
                </a>
                {user?.type === "ADM" && (
                  <Popconfirm
                    title="Deseja deletar a chave de configuração?"
                    onConfirm={() => confirm(record)}
                    onCancel={cancel}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <a>Deletar</a>
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
        {visibleAddConfig ? (
          <AddChave
            visible={visibleAddConfig}
            setVisible={setVisibleAddConfig}
            onConfirm={onConfirm}
          />
        ) : (
          ""
        )}
      </VerticalContainer>
    </>
  );
}
