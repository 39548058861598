import React from 'react';

import {
  Alert, Button, Form,
} from 'antd';

import { FormItemInput } from '../../ant-wrapper/form-item';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function DistrictForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
}) {
  const [form] = Form.useForm();
  const onEdit = () => {
    setError();
  };
  return (
    <>
      {error && (
        <>
          <Alert
            message={error}
            type="error"
          />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={initialValues}
      >
        <FormItemInput
          {...formItemLayout}
          name="codigo"
          required
          label="Código"
          viewMode={viewMode}
        />

        <FormItemInput
          {...formItemLayout}
          name="nome"
          required
          label="Nome"
          viewMode={viewMode}
        />
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
