import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  registerClinica, updateClinica, getClinicas, deleteClinica,
} from '../../services/clinicas';
import { Creators as ClinicaActions, Types } from '../ducks/clinica';

function* register(action) {
  try {
    yield call(registerClinica, action.clinica);
    yield put(ClinicaActions.registerClinicaSuccess());
    yield put(ClinicaActions.getAllClinicaRequest());
  } catch (error) {
    yield put(ClinicaActions.requestClinicaFailure(error?.error));
  }
}

function* update(action) {
  try {
    yield call(updateClinica, action.clinica, action.values);
    yield put(ClinicaActions.updateClinicaSuccess());
    yield put(ClinicaActions.getAllClinicaRequest());
  } catch (error) {
    yield put(ClinicaActions.requestClinicaFailure(error?.error));
  }
}

function* getAll() {
  try {
    const clinicas = yield call(getClinicas, '', '', false);
    yield put(ClinicaActions.getAllClinicaSuccess(clinicas));
  } catch (error) {
    yield put(ClinicaActions.requestClinicaFailure(error?.error));
  }
}

function* deleteClinicaSaga(action) {
  try {
    yield call(deleteClinica, action.id);
    yield put(ClinicaActions.deleteClinicaSuccess());
    yield put(ClinicaActions.getAllClinicaRequest());
  } catch (error) {
    yield put(ClinicaActions.requestClinicaFailure(error?.error));
  }
}

export function* rootClinicaSaga() {
  yield all([takeLatest(Types.REGISTER_CLINICA_REQUEST, register)]);
  yield all([takeLatest(Types.GET_ALL_CLINICA_REQUEST, getAll)]);
  yield all([takeLatest(Types.UPDATE_CLINICA_REQUEST, update)]);
  yield all([takeLatest(Types.DELETE_CLINICA_REQUEST, deleteClinicaSaga)]);
}
