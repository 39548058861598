import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import moment from 'moment';

const { confirm } = Modal;

export default function confirmFaltaQuota(procedimentos, mes, onOk, onCancel) {
  const nameOfMonth = moment(mes).format('MMMM');
  const getNomesProcedimentos = () => {
    let nomes = '';
    procedimentos.forEach((element) => {
      nomes += `${element.nome}, `;
    });
    return nomes;
  };
  return confirm({
    width: '500px',
    title: 'Atenção! Não existem quotas para um dos procedimentos cadastrados',
    icon: <ExclamationCircleOutlined />,
    content: `O(s) procedimento(s): ${getNomesProcedimentos()} não tem cotas disponíveis para o mês de ${nameOfMonth}.\nA solicitação ficará pendente para uma aprovação manual`,
    okText: 'Continuar',
    cancelText: 'Cancelar',
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel();
    },
  });
}
