import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import api from '../../services/api';
import { Creators as CnaeNaturezaActions, Types } from '../ducks/cnae-natureza';

function* getAll() {
  try {
    const [{ data: cnaes }, { data: naturezas }] = yield yield all([
      call(api.get, '/cnae'),
      call(api.get, '/natureza'),
    ]);
    yield put(CnaeNaturezaActions.getAllCnaeNaturezaSuccess(cnaes, naturezas));
  } catch (error) {
    yield put(CnaeNaturezaActions.getAllCnaeNaturezaFailure(error));
  }
}

export function* rootCnaeNaturezaSaga() {
  yield all([takeLatest(Types.GET_ALL_CNAE_NATUREZA, getAll)]);
}
