import React, { useState } from 'react';

import {
  Button, Form,
} from 'antd';

import { gerarRelatorioDistritos } from '../../../services/relatorios';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

export default function RelatorioDistritos() {
  const [form] = Form.useForm();

  const handleDistritos = async () => {
    await gerarRelatorioDistritos();
  };

  return (
    <div style={{ width: '100%' }}>
      <Form
        form={form}
        name="register"
        onFinish={handleDistritos}
        scrollToFirstError
        {...formItemLayout}
      >

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Gerar relatórios dos distritos
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
