import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import Container from '../components/container';
import { UserContextProvider } from '../context';
import { getToken } from '../utils';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  isAdm = false,
  title, subtitle,
  ...rest
}) {
  const user = useSelector((state) => state.auth.user);
  const signed = getToken();

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/home" />;
  }

  if (!isPrivate) {
    return (
      <Route {...rest}>
        <Component {...rest} />
      </Route>
    );
  }

  if (isAdm) {
    if (user.type !== 'ADM') return <Redirect to="/home" />;
  }

  return (
    <Route {...rest}>
      <UserContextProvider>
        <Container title={title} subtitle={subtitle}>
          <Component {...rest} />
        </Container>
      </UserContextProvider>
    </Route>
  );
}
