import React, { useEffect, useState } from 'react';

import { MedicineBoxOutlined, TeamOutlined } from '@ant-design/icons';
import { Spin, Tabs } from 'antd';

import EditButton from '../../../components/buttons/edit';
import TypeDocumentForm from '../../../components/tipodocumentos/form';
import {
  InlineContainer,
  VerticalContainer,
} from '../../../components/styled';
import ViewContainer from '../../../components/view_container';
import { getType, updateType } from '../../../services/tipodocumentos';
//import ListAgenteSaudes from '../agentes/list';

const { TabPane } = Tabs;

export default function ViewTipo({ computedMatch }) {
  const [error, setError] = useState();
  const [tipo, setTipo] = useState();
  const [viewMode, setViewMode] = useState(true);
  const { params } = computedMatch;

  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateType(tipo, values);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  const handleTipo = async () => {
    const data = await getType(params.id);
    setTipo(data);
  };
  useEffect(() => {
    handleTipo();
  }, []);

  return (
    <Tabs defaultActiveKey="1" style={{ width: '100%' }}>
      <TabPane
        tab={(
          <span>
            <MedicineBoxOutlined />
            Detalhes
          </span>
        )}
        key="1"
      >
        <VerticalContainer>
          <InlineContainer>
            <div />
            <EditButton viewMode={viewMode} setViewMode={setViewMode} />
          </InlineContainer>
          {tipo ? (
            <ViewContainer item={tipo} marginLeft="0">
              <TypeDocumentForm
                initialValues={{ ...tipo }}
                onFinish={onFinish}
                error={error}
                setError={setError}
                loading={loading}
                viewMode={viewMode}
              />
            </ViewContainer>
          ) : (
            <Spin />
          )}
        </VerticalContainer>
      </TabPane>
    </Tabs>
  );
}