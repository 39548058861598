import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import SolicitacaoForm from "../../../components/solicitacoes/forms";
import { VerticalContainer } from "../../../components/styled";
import { registerSolicitacao } from "../../../services/solicitacoes";
import {
  ClinicaActions,
  PacienteActions,
  ProfissionalActions,
  UnidadeActions,
} from "../../../store/utils";

export default function AddSolicitacao(props) {
  const solicitacao = props.location.state;

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    try {
      setLoading(true);
      return await registerSolicitacao(values);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(ProfissionalActions.getAllProfissionalRequest());
    dispatch(UnidadeActions.getAllUnidadeRequest());
    dispatch(ClinicaActions.getAllClinicaRequest());
  }, []);
  return (
    <VerticalContainer>
      <div />
      <SolicitacaoForm
        initialValues={solicitacao}
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </VerticalContainer>
  );
}
