import React, { forwardRef, useContext, useEffect, useState } from "react";

import { Checkbox, Col, Form, Row } from "antd";
import { useSelector } from "react-redux";

import { UserContext } from "../../../context";
import { cpfMask } from "../../../utils";
import {
  FormItemCheckbox,
  FormItemDatePicker,
  FormItemInput,
  FormItemSelect,
} from "../../ant-wrapper/form-item";
import {
  ESTADO_CIVIL_FIELDS,
  NACIONALIDADE_FIELDS,
  SEXO_FIELDS,
} from "../../utils";
import { getChaveByNome } from "../../../services/configuracoes";

export default function PessoaFisicaForm({
  form,
  viewMode = false,
  enableCpfRgCheckbok = false,
  useCard = false,
  isEdit = false,
}) {
  const [possuiRg, setPossuiRg] = useState(true);
  const [possuiCpf, setPossuiCpf] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const { estados: states, cidades } = useSelector((state) => state.estados);
  const user = useContext(UserContext);
  const handleCpf = (value) => {
    value = value.target.value;
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      pessoa_fisica: {
        ...fields.pessoa_fisica,
        cpf: cpfMask(value),
      },
    });
  };

  useEffect(() => {
    const fetchChave = async () => {
      if (user.type === "MARCADOR") {
        const chaveValue = await getChaveByNome("marcador_edita_paciente", false, 3);
        setCanEdit(chaveValue);
      }
    };
    fetchChave();
  }, [user.type]);

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={useCard ? 12 : 8}>
          <FormItemInput
            name={["pessoa_fisica", "nome"]}
            label="Nome"
            required
            viewMode={viewMode || (isEdit && user.type !== "ADM" && !canEdit)}
          />
        </Col>
        <Col span={useCard ? 12 : 6}>
          <FormItemInput
            name={["pessoa_fisica", "email"]}
            label="E-mail"
            viewMode={viewMode}
            email
          />
        </Col>
        <Col span={useCard ? 12 : 4}>
          <FormItemDatePicker
            label="Data de nascimento"
            name={["pessoa_fisica", "data_nascimento"]}
            viewMode={viewMode}
            required
          />
        </Col>
        <Col span={useCard ? 12 : 6}>
          <FormItemSelect
            viewMode={viewMode}
            name={["pessoa_fisica", "sexo"]}
            label="Sexo"
            items={SEXO_FIELDS}
            required
          />
        </Col>

        <Col span={useCard ? 12 : 6}>
          <FormItemInput
            name={["pessoa_fisica", "celular"]}
            label="Celular"
            required
            viewMode={viewMode}
          />
        </Col>
        <Col span={useCard ? 12 : 6}>
          <FormItemInput
            name={["pessoa_fisica", "telefone"]}
            label="Telefone"
            viewMode={viewMode}
          />
        </Col>
        <Col span={useCard ? 12 : 6}>
          <FormItemSelect
            viewMode={viewMode}
            label="Estado civil"
            name={["pessoa_fisica", "estado_civil"]}
            items={ESTADO_CIVIL_FIELDS}
            required
          />
        </Col>
        <Col span={useCard ? 12 : 6}>
          <FormItemSelect
            viewMode={viewMode}
            label="Nacionalidade"
            name={["pessoa_fisica", "nacionalidade"]}
            items={NACIONALIDADE_FIELDS}
          />
        </Col>
        <Col span={useCard ? 12 : 6}>
          <FormItemSelect
            viewMode={viewMode}
            label="Naturalidade"
            name={["pessoa_fisica", "naturalidade"]}
            items={cidades}
            itemsValue="nome"
            itemsLabel="nome"
          />
        </Col>
      </Row>

      {enableCpfRgCheckbok && (
        <Row style={{ marginBottom: "25px", marginTop: "20px" }}>
          <Checkbox
            disabled={viewMode}
            onChange={(e) => setPossuiCpf(!e.target.checked)}
          >
            Paciente não possui CPF
          </Checkbox>
          <Checkbox
            disabled={viewMode}
            onChange={(e) => setPossuiRg(!e.target.checked)}
          >
            Paciente não possui RG
          </Checkbox>
          <FormItemCheckbox
              viewMode={viewMode}
              name="comorbidade"
              checkboxLabel="Possui comorbidade"
              valuePropName="checked"
            />
        </Row>
      )}

      <Row gutter={[8, 8]}>
        {possuiCpf ? (
          <Col span={useCard ? 12 : 6}>
            <FormItemInput
              name={["pessoa_fisica", "cpf"]}
              label="CPF"
              required
              viewMode={viewMode || (isEdit && user.type !== "ADM" && !canEdit)}
              onChangeInput={handleCpf}
            />
          </Col>
        ) : (
          ""
        )}
        {possuiRg ? (
          <>
            <Col span={useCard ? 12 : 6}>
              <FormItemInput
                label="RG"
                name={["pessoa_fisica", "rg"]}
                viewMode={viewMode || (isEdit && user.type !== "ADM" && !canEdit)}
              />
            </Col>
            <Col span={useCard ? 12 : 6}>
              <FormItemDatePicker
                label="Data de emissão RG"
                name={["pessoa_fisica", "data_emissao_rg"]}
                viewMode={viewMode}
              />
            </Col>

            <Col span={useCard ? 12 : 6}>
              <FormItemSelect
                viewMode={viewMode}
                label="UF RG"
                name={["pessoa_fisica", "uf_rg"]}
                items={states}
                itemsValue="sigla"
                itemsLabel="sigla"
              />
            </Col>
          </>
        ) : (
          ""
        )}
        <Col span={useCard ? 12 : 6}>
          <FormItemInput
            label="Numero da Certidão"
            name={["pessoa_fisica", "numero_certidao"]}
            viewMode={viewMode}
          />
        </Col>
        <Col span={useCard ? 12 : 6}>
          <FormItemInput
            name={["pessoa_fisica", "cnh"]}
            label="CNH"
            viewMode={viewMode || (isEdit && user.type !== "ADM" && !canEdit)}
          />
        </Col>
      </Row>

      <Row />
    </>
  );
}
