import React, { useContext, useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Table, Space, Popconfirm, Button, Input, Tag, DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";

import "moment/locale/pt-br";
import { TitleHeader, VerticalContainer } from "../../../components/styled";
import { UserContext } from "../../../context";
import history from "../../../routes/history";
import {
  abrirProtocolo,
  deleteProtocolo,
  getProtocolos,
  imprimirProtocolo,
  updateProtocolo,
} from "../../../services/protocolos";
import AddProtocolo from "../add";

const statusStyle = {
  PROTOCOLADO: "yellow",
  DEVOLVIDO: "green",
  NAO_REALIZADO: "red",
  REALIZADO: "green",
};

const { Search } = Input;

const { Column } = Table;
export default function ListProtocolos({ paciente }) {
  const [visibleAddProtocolo, setVisibleAddProtocolo] = useState(false);
  const [visibleEditProtocolo, setVisibleEditProtocolo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [protocolo, setProtocolo] = useState();
  const [viewMode, setViewMode] = useState(true);
  const [filter, setFilter] = useState("");
  const [pacienteId, setPacienteId] = useState();
  const [month, setMounth] = useState();
  const [year, setYear] = useState();

  const [protocolos, setProtocolos] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const user = useContext(UserContext);

  const getProtocolosPaginated = async () => {
    try {
      setPacienteId(paciente?.id);
      setLoading(true);
      const protocolosData = await getProtocolos(
        page,
        month,
        year,
        filter,
        pacienteId
      );
      setProtocolos(protocolosData.data);
      setTotal(protocolosData.total);
    } finally {
      setLoading(false);
    }
  };
  const onConfirm = async () => {
    await getProtocolosPaginated();
  };

  useEffect(() => {
    getProtocolosPaginated();
  }, [page, month, filter, pacienteId]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  async function confirm(record) {
    await deleteProtocolo(record.id);
    await getProtocolosPaginated();
  }

  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter("");
  };

  const onChangeFilter = (value) => {
    if (value) {
      const valueFormated = value.format("MM");
      setMounth(valueFormated);
      const valueFormatedYear = value.format("YYYYY");
      setYear(valueFormatedYear);
    } else {
      setMounth("");
      setYear("");
      console.log("O valor do mês e ano está nulo!");
    }
  };

  function cancel(e) {
    console.log(e);
  }

  return (
    <>
      <VerticalContainer>
        <TitleHeader style={{ marginBottom: "0px", fontSize: "15px" }}>
          Selecione o mês para filtrar os protocolos
        </TitleHeader>
        <DatePicker
          picker="month"
          format="MMM [de] YYYY"
          onChange={onChangeFilter}
          placeholder="Seleciona a mês"
          locale={locale}
        />
        <br />
        <Search
          onChange={onChange}
          style={{ width: "50%" }}
          placeholder="Pesquisar por número de protocolo ou observação"
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            setVisibleAddProtocolo(true);
          }}
          style={{ width: "100%!important" }}
          block
        >
          <PlusOutlined />
          Adicionar protocolo
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{
            width: "100%",
            wordWrap: "break-word",
            wordBreak: "break-word",
          }}
          dataSource={[...protocolos]}
          rowKey="id"
          loading={loading}
        >
          <Column width="20%" title="Número" dataIndex="numero" key="numero" />

          <Column
            width="15%"
            title="Data de entrada"
            dataIndex="data_entrada"
            render={(data) => moment(data).format("DD/MM/YYYY")}
            key="data_entrada"
          />

          <Column
            width="15%"
            title="Data de saida"
            dataIndex="data_saida"
            render={(data) => (data ? moment(data).format("DD/MM/YYYY") : "")}
            key="data_saida"
          />

          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status) => (
              <Tag color={statusStyle[status]} key={status}>
                {status}
              </Tag>
            )}
          />

          <Column
            width="20%"
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a
                  onClick={() => {
                    setVisibleEditProtocolo(true);
                    const protocoloCopia = { ...record };
                    protocoloCopia.paciente = JSON.stringify(record.paciente);
                    if (protocoloCopia.data_entrada) {
                      protocoloCopia.data_entrada = moment(
                        protocoloCopia.data_entrada,
                        "Y-M-D HH:mm"
                      );
                    }

                    if (protocoloCopia.data_saida) {
                      protocoloCopia.data_saida = moment(
                        protocoloCopia.data_saida,
                        "Y-M-D HH:mm"
                      );
                    }
                    setProtocolo(protocoloCopia);
                  }}
                >
                  Visualizar
                </a>
                <a onClick={() => imprimirProtocolo(record.id)}>Imprimir</a>
                {user?.type === "ADM" && (
                  <Popconfirm
                    title="Deseja deletar o protocolo?"
                    onConfirm={() => confirm(record)}
                    onCancel={cancel}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <a>Deletar</a>
                  </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
        {visibleAddProtocolo || visibleEditProtocolo ? (
          <AddProtocolo
            paciente={paciente}
            visible={visibleAddProtocolo || visibleEditProtocolo}
            setVisible={
              visibleAddProtocolo
                ? setVisibleAddProtocolo
                : setVisibleEditProtocolo
            }
            isEdit={visibleAddProtocolo ? false : true}
            onConfirm={onConfirm}
            initialValues={visibleAddProtocolo ? undefined : { ...protocolo }}
          />
        ) : (
          ""
        )}
      </VerticalContainer>
    </>
  );
}
