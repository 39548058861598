import React, { useEffect, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Skeleton,
  Modal,
} from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';

import { SITUACOES } from '../../../models/atendimentos';
import {
  abrirComprovanteAtendimento,
  getByCurrentMonthAndPaciente,
  updateAtendimento,
} from '../../../services/atendimentos';
import { getClinicas } from '../../../services/clinicas';
import { getProfissionalClinicas } from '../../../services/clinicas/profissional';
import { getPacientes } from '../../../services/pacientes';
import { getProcedimentos } from '../../../services/procedimentos';
import { getProfissionais } from '../../../services/profissional';
import { getUnidades } from '../../../services/unidades';
import DetailPacientBox from '../../pacientes/datail-box';
import ProcedimentosListBox from '../../procedimentos/list-box';
import SkeletonContainer from '../../skeleton-container';
import ListModelAtendimentos from '../modal-list';
import 'moment/locale/pt-br';

const { Option } = Select;
const { confirm } = Modal;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
const cardStyle = { borderColor: 'white' };

export default function AtendimentoForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
  disableButtons = false,
}) {
  const [form] = Form.useForm();
  const [profissionais, setProfissionais] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [paciente, setPaciente] = useState();
  const [loadingDados, setloadingDados] = useState(false);
  const [loadingAprovar, setLoadingAprovar] = useState(false);

  const isCancelable = () => {
    const { situacao } = initialValues;
    return situacao === SITUACOES.APROVADO || situacao === SITUACOES.LANCADO;
  };
  const isAprovable = () => initialValues.situacao !== SITUACOES.APROVADO;

  const onEdit = () => {
    setError();
  };
  
  const onSearch = async (result) => {
    const pacientesData = await getPacientes(0, result);
    setPacientes(pacientesData.data);
  }
  
  const getData = async () => {
    setloadingDados(true);
    try {
      let filter = "";
      if (initialValues?.paciente){
        const pac =  JSON.parse(initialValues?.paciente)
        filter = pac.pessoa_fisica?.nome;
      }
      const pacientesData = await getPacientes(0, filter);
      setPacientes(pacientesData.data);
      setProfissionais(await getProfissionais('', '', false));
      if (initialValues.paciente) {
        setPaciente(JSON.parse(initialValues.paciente));
      }
    } catch (err) {
      setError(err.error);
    } finally {
      setloadingDados(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const onChangePaciente = async (value) => {
    const pacienteAux = JSON.parse(value);
    setPaciente(pacienteAux);
  };

  const onSubmit = (values) => {
    onFinish(values);
  };

  const handleSituacao = async (situacao) => {
    const atendimentoAtualizada = { ...initialValues };
    atendimentoAtualizada.situacao = situacao;
    try {
      setLoadingAprovar(true);
      await updateAtendimento(initialValues, atendimentoAtualizada);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoadingAprovar(false);
    }
  };

  return (
    <>
      {error && (
        <>
          <Alert
            message={error}
            type="error"
          />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onSubmit}
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <Row>
          <SkeletonContainer loading={loadingDados}>
            <Col span={12}>
              <Card title="Dados básicos" style={{ ...cardStyle }}>
                <Form.Item
                  name="profissional"
                  label="Profissional"
                  rules={[
                    {
                      required: true,
                      message: 'Profissional é obrigatório!',
                    },
                  ]}
                >
                  <Select disabled={viewMode} showSearch>
                    {profissionais.length > 0
                      && profissionais.map((elm) => (
                        <Option key={elm.id} value={JSON.stringify(elm)}>
                          {elm.pessoa_fisica.nome}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="paciente"
                  label="Paciente"
                  rules={[
                    {
                      required: true,
                      message: 'Paciente é obrigatório!',
                    },
                  ]}
                >
                  <Select
                    onChange={onChangePaciente}
                    disabled={viewMode}
                    onSearch={onSearch}
                    showSearch
                  >
                    {pacientes.length > 0
                      && pacientes.map((elm) => (
                        <Option key={elm.id} value={JSON.stringify(elm)}>
                          {elm.pessoa_fisica.nome}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                <DetailPacientBox paciente={paciente} />

                <Form.Item
                  label="Data"
                  name="data"
                  rules={[
                    {
                      required: true,
                      message: 'Data é obrigatório!',
                    },
                  ]}
                >
                  <DatePicker
                    locale={locale}
                    style={{ width: '100%' }}
                    disabled={viewMode}
                    format="DD/MM/YYYY"
                    placeholder="Seleciona a data"
                  />
                </Form.Item>

                <Form.Item name="historico" label="Historico">
                  <Input.TextArea disabled={viewMode} />
                </Form.Item>
              </Card>
            </Col>
          </SkeletonContainer>
        </Row>
        <br />
        <br />
        {!disableButtons && (
          <>
            <SkeletonContainer loading={loadingDados}>
              <div style={{ marginLeft: '25px' }}>
                {!viewMode && (
                  <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                      Enviar
                    </Button>
                  </Form.Item>
                )}
                {viewMode && (
                  <>
                    {isAprovable() && (
                      <Form.Item>
                        <Button
                          loading={loadingAprovar}
                          onClick={() => handleSituacao(SITUACOES.APROVADO)}
                          type="primary"
                        >
                          Aprovar
                        </Button>
                      </Form.Item>
                    )}
                    {isCancelable() && (
                      <Form.Item>
                        <Button
                          loading={loadingAprovar}
                          type="primary"
                          onClick={() => handleSituacao(SITUACOES.CANCELADO)}
                          style={{ backgroundColor: '#00008b' }}
                        >
                          Cancelar atendimento
                        </Button>
                      </Form.Item>
                    )}
                  </>
                )}
              </div>
            </SkeletonContainer>
          </>
        )}
      </Form>
    </>
  );
}
