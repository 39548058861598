import React, { useContext, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Table, Space, Popconfirm, Button,
} from 'antd';
import moment from 'moment';

import { VerticalContainer } from '../../../../components/styled';
import { UserContext } from '../../../../context';
import {
  deleteProfissionalClinica,
  getProfissionalClinicas,
} from '../../../../services/clinicas/profissional';
import { failureNotification } from '../../../../utils';
import AddProfissionalClinica from '../add';
import EditProfissionalClinica from '../edit';

const { Column } = Table;
export default function ListProfissionalClinicas({ clinica }) {
  const user = useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const [
    visibleAddProfissionalClinica,
    setVisibleAddProfissionalClinica,
  ] = useState(false);
  const [profissional, setProfissionalClinica] = useState({});
  const [profissionals, setProfissionalClinicas] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const getProfissionalClinicaPaginated = async () => {
    try {
      const profissionalsData = await getProfissionalClinicas(page, '', clinica);
      setProfissionalClinicas(profissionalsData.data);
      setTotal(profissionalsData.total);
    } catch (err) {
      failureNotification(err.error);
    }
  };
  const onConfirm = async () => {
    await getProfissionalClinicaPaginated();
  };

  useEffect(() => {
    getProfissionalClinicaPaginated();
  }, [page]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deleteProfissionalClinica(record.id);
    await getProfissionalClinicaPaginated();
  }
  const showDrawer = (record) => {
    setProfissionalClinica(record);
    setVisible(true);
  };

  function cancel(e) {
    console.log(e);
  }
  return (
    <>
      <VerticalContainer>
        <Button
          type="dashed"
          onClick={() => {
            setVisibleAddProfissionalClinica(true);
          }}
          style={{ width: '100%!important' }}
          block
        >
          <PlusOutlined />
          {' '}
          Adicionar profissional
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{ width: '100%' }}
          dataSource={profissionals}
          rowKey="id"
        >
          <Column title="Registro" dataIndex="registro" key="registro" />
          <Column
            title="Profissional"
            dataIndex="profissional"
            key="profissional"
          />
          <Column
            title="Especialidade"
            dataIndex="especialidade"
            key="especialidade"
          />
          <Column
            title="Data de criação"
            dataIndex="created_at"
            render={(data) => moment(data).format('DD/MM/YYYY')}
            key="created_at"
          />
          <Column
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => showDrawer(record)}>Editar</a>
                {user?.type === 'ADM'
                && (
                <Popconfirm
                  title="Deseja deletar o profissional?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
        {visible ? (
          <EditProfissionalClinica
            visible={visible}
            setVisible={setVisible}
            profissional={profissional}
            onConfirm={onConfirm}
          />
        ) : (
          ''
        )}
        {visibleAddProfissionalClinica ? (
          <AddProfissionalClinica
            clinica={clinica}
            visible={visibleAddProfissionalClinica}
            setVisible={setVisibleAddProfissionalClinica}
            onConfirm={onConfirm}
          />
        ) : (
          ''
        )}
      </VerticalContainer>
    </>
  );
}
