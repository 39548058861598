import React, { useContext, useState } from 'react';

import { useDispatch } from 'react-redux';

import EditButton from '../../../components/buttons/edit';
import {
  VerticalContainer,
  InlineContainer,
} from '../../../components/styled';
import UserForm from '../../../components/users/form';
import { UserContext } from '../../../context';
import { updateUser } from '../../../services/users';
import { Creators as AuthActions } from '../../../store/ducks/auth';

export default function AccountDetail() {
  const [error, setError] = useState();
  const [viewMode, setViewMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateUser(user, values);
      dispatch(AuthActions.getUserRequest());
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <VerticalContainer>
      <InlineContainer>
        <div />
        <EditButton viewMode={viewMode} setViewMode={setViewMode} />
      </InlineContainer>
      <UserForm
        initialValues={{ ...user }}
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
        isEdit
        viewMode={viewMode}
      />
    </VerticalContainer>
  );
}
