import React, { useState } from 'react';

import { Modal } from 'antd';

import UserForm from '../../../components/users/form';
import { updateUser } from '../../../services/users';

export default function EditUser({
  visible, setVisible, user, onConfirm,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateUser(user, values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Editar usuário"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      {user && (
        <UserForm
          initialValues={{ ...user }}
          onFinish={onFinish}
          error={error}
          setError={setError}
          loading={loading}
          isEdit
        />
      )}
    </Modal>
  );
}
