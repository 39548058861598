import React, { useEffect, useState } from 'react';

import {
  Button, Card, Spin, Tag,
} from 'antd';

import AtendimentoForm from '../../../components/atendimentos/forms';
import EditButton from '../../../components/buttons/edit';
import {
  InlineContainer,
  SecondaryTextJustify,
  TitleHeader,
  VerticalContainer,
} from '../../../components/styled';
import ViewContainer from '../../../components/view_container';
import {
  abrirComprovanteAtendimento,
  getAtendimento,
  updateAtendimento,
} from '../../../services/atendimentos';

const situacoesStyle = {
  LANÇADO: 'yellow',
  CANCELADO: 'red',
  APROVADO: 'green',
};
export default function EditAtendimento({ computedMatch }) {
  const [error, setError] = useState();
  const [atendimento, setAtendimento] = useState();
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const { params } = computedMatch;
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateAtendimento(atendimento, values);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  const handleAtendimento = async () => {
    const data = await getAtendimento(params.id);
    console.log(data);
    setAtendimento(data);
  };

  useEffect(() => {
    handleAtendimento();
  }, []);

  return (
    <>
      {atendimento ? (
        <VerticalContainer>
          <InlineContainer>
            <TitleHeader>
              {`Nº - ${atendimento.numero}`}
            </TitleHeader>
            <EditButton viewMode={viewMode} setViewMode={setViewMode} />
          </InlineContainer>
          <Card
            title="Informações"
            style={{ marginTop: '-20px', borderColor: 'white' }}
          >
            <SecondaryTextJustify>
              {' '}
              Situação:
              {' '}
              <Tag color={situacoesStyle[atendimento.situacao]}>
                {atendimento.situacao}
              </Tag>
            </SecondaryTextJustify>

            <SecondaryTextJustify>
              {' '}
              Lançamento:
              {' '}
              {atendimento?.marcador?.name}
            </SecondaryTextJustify>
            {viewMode && (
              <SecondaryTextJustify>
                Comprovante:
                <Button
                  loading={loading}
                  onClick={() => abrirComprovanteAtendimento(atendimento.id)}
                  type="link"
                >
                  Imprimir comprovante
                </Button>
              </SecondaryTextJustify>
            )}
            {atendimento.situacao === 'CANCELADO' && (
              <SecondaryTextJustify>
                Cancelamento:
                {' '}
                {atendimento?.cancelador?.name}
              </SecondaryTextJustify>
            )}
          </Card>
          <ViewContainer item={atendimento}>
            <AtendimentoForm
              initialValues={{ ...atendimento }}
              onFinish={onFinish}
              error={error}
              setError={setError}
              loading={loading}
              viewMode={viewMode}
            />
          </ViewContainer>
        </VerticalContainer>
      ) : (
        <Spin />
      )}
    </>
  );
}
