import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  registerProfissional, updateProfissional, getProfissionais, deleteProfissional,
} from '../../services/profissional';
import { Creators as ProfissionalActions, Types } from '../ducks/profissional';

function* register(action) {
  try {
    yield call(registerProfissional, action.profissional);
    yield put(ProfissionalActions.registerProfissionalSuccess());
    yield put(ProfissionalActions.getAllProfissionalRequest());
  } catch (error) {
    yield put(ProfissionalActions.requestProfissionalFailure(error?.error));
  }
}

function* update(action) {
  try {
    yield call(updateProfissional, action.profissional, action.values);
    yield put(ProfissionalActions.updateProfissionalSuccess());
    yield put(ProfissionalActions.getAllProfissionalRequest());
  } catch (error) {
    yield put(ProfissionalActions.requestProfissionalFailure(error?.error));
  }
}

function* getAll() {
  try {
    const profissionais = yield call(getProfissionais, '', '', false);
    yield put(ProfissionalActions.getAllProfissionalSuccess(profissionais));
  } catch (error) {
    yield put(ProfissionalActions.requestProfissionalFailure(error?.error));
  }
}

function* deleteProfissionalSaga(action) {
  try {
    yield call(deleteProfissional, action.id);
    yield put(ProfissionalActions.deleteProfissionalSuccess());
    yield put(ProfissionalActions.getAllProfissionalRequest());
  } catch (error) {
    yield put(ProfissionalActions.requestProfissionalFailure(error?.error));
  }
}

export function* rootProfissionalSaga() {
  yield all([takeLatest(Types.REGISTER_PROFISSIONAL_REQUEST, register)]);
  yield all([takeLatest(Types.GET_ALL_PROFISSIONAL_REQUEST, getAll)]);
  yield all([takeLatest(Types.UPDATE_PROFISSIONAL_REQUEST, update)]);
  yield all([takeLatest(Types.DELETE_PROFISSIONAL_REQUEST, deleteProfissionalSaga)]);
}
