import React, { useState } from 'react';

import { Modal } from 'antd';

import TypeDocumentForm from '../../../components/tipodocumentos/form';
import { registerType } from '../../../services/tipodocumentos';

export default function AddType({ visible, setVisible, onConfirm }) {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const onFinish = async (values) => {
      try {
        setLoading(true);
        await registerType(values);
        setVisible(false);
        onConfirm();
      } catch (err) {
        console.log(err.error);
        setError(err.error);
      } finally {
        setLoading(false);
      }
    };
    return (
      <Modal
        title="Adicionar Tipo de Documento"
        centered
        footer={null}
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <TypeDocumentForm
          onFinish={onFinish}
          error={error}
          setError={setError}
          loading={loading}
        />
      </Modal>
    );
  }