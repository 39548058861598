import React, { useEffect, useState } from "react";

import { MedicineBoxOutlined } from "@ant-design/icons";
import { Spin, Tabs, Card, Tag } from "antd";

import EditButton from "../../../components/buttons/edit";
import ProtocoloForm from "../../../components/protocolos/forms";
import {
  InlineContainer,
  VerticalContainer,
  TitleHeader,
  SecondaryTextJustify,
} from "../../../components/styled";
import ViewContainer from "../../../components/view_container";
import { getProtocolo, updateProtocolo } from "../../../services/protocolos";

const { TabPane } = Tabs;

export default function ViewProtocolo({ computedMatch }) {
  const [error, setError] = useState();
  const [protocolo, setProtocolo] = useState();
  const [viewMode, setViewMode] = useState(true);
  const { params } = computedMatch;

  const statusStyle = {
    PROTOCOLADO: "yellow",
    DEVOLVIDO: "green",
    NAO_REALIZADO: "red",
    REALIZADO: "green",
  };

  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateProtocolo(protocolo, values);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  const handleProtocolo = async () => {
    const data = await getProtocolo(params.id);
    console.log(data);
    setProtocolo(data);
  };
  useEffect(() => {
    handleProtocolo();
  }, []);

  return (
    <VerticalContainer>
      <InlineContainer>
        <div></div>
        <EditButton viewMode={viewMode} setViewMode={setViewMode} />
      </InlineContainer>
      <Card
        title="Informações"
        style={{ marginTop: "-20px", borderColor: "white" }}
      >
        <SecondaryTextJustify>
          {" "}
          Status:{" "}
          <Tag color={statusStyle[protocolo?.status]} key={protocolo?.status}>
            {protocolo?.status}
          </Tag>
        </SecondaryTextJustify>
      </Card>

      {protocolo ? (
        <ViewContainer item={protocolo} marginLeft="0">
          <ProtocoloForm
            initialValues={{ ...protocolo }}
            onFinish={onFinish}
            error={error}
            setError={setError}
            loading={loading}
            viewMode={viewMode}
          />
        </ViewContainer>
      ) : (
        <Spin />
      )}
    </VerticalContainer>
  );
}
