import api from "../api";

const getDocumento = async (path) => {
  const { data } = await api.post(
    "documentos",
    {
      path: path,
    },
    { responseType: "blob" }
  );
  return window.URL.createObjectURL(new Blob([data]));
};

export default getDocumento;
