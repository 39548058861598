import React, { useState } from 'react';

import { Modal } from 'antd';

import ProcedimentoForm from '../../../components/procedimentos/form';
import ViewContainer from '../../../components/view_container';
import { updateProcedimento } from '../../../services/procedimentos';

export default function EditProcedimento({
  visible,
  setVisible,
  procedimento,
  onConfirm,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateProcedimento(procedimento, values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Editar procedimento"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      {procedimento && (
      <ViewContainer item={procedimento} marginLeft="0">
        <ProcedimentoForm
          initialValues={{ ...procedimento }}
          onFinish={onFinish}
          error={error}
          setError={setError}
          loading={loading}
          isEdit
        />

      </ViewContainer>
      )}
    </Modal>
  );
}
