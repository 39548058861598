import React, { useEffect, useState } from 'react';

import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import EditButton from '../../../components/buttons/edit';
import {
  InlineContainer,
  VerticalContainer,
} from '../../../components/styled';
import UnidadeForm from '../../../components/unidades/forms';
import ViewContainer from '../../../components/view_container';
import { getUnidade } from '../../../services/unidades';
import { Creators as UnidadeActions } from '../../../store/ducks/unidade';
import { useContext } from 'react';
import { UserContext } from '../../../context';

export default function EditUnidade({ computedMatch }) {
  const [error, setError] = useState();
  const [unidade, setUnidade] = useState();
  const [viewMode, setViewMode] = useState(true);
  const { params } = computedMatch;
  const { loading, error: erro } = useSelector((state) => state.unidade);
  const dispatch = useDispatch();
  const user = useContext(UserContext);

  const onFinish = async (values) => {
    dispatch(UnidadeActions.updateUnidadeRequest(unidade, values));
  };

  const handleUnidade = async () => {
    const data = await getUnidade(params.id);
    setUnidade(data);
  };

  useEffect(() => {
    setError(erro);
  }, [erro]);

  useEffect(() => {
    handleUnidade();
  }, []);

  return (
    <VerticalContainer>
      <InlineContainer>
        <div />
        {unidade?.permissao_editar ?  <EditButton viewMode={viewMode} setViewMode={setViewMode} /> : ""}
      </InlineContainer>
      {unidade ? (
        <ViewContainer item={unidade}>
          <UnidadeForm
            initialValues={{ ...unidade }}
            onFinish={onFinish}
            error={error}
            setError={setError}
            loading={loading}
            viewMode={viewMode}
          />
        </ViewContainer>
      ) : (
        <Spin />
      )}
    </VerticalContainer>
  );
}
