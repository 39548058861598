import React, { useState } from 'react';

import { Modal } from 'antd';

import ProfissionalClinicaForm from '../../../../components/clinicas/forms/profissional';
import ViewContainer from '../../../../components/view_container';
import { updateProfissionalClinica } from '../../../../services/clinicas/profissional';

export default function EditProfissionalClinica({
  visible,
  setVisible,
  profissional,
  onConfirm,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateProfissionalClinica(profissional, values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Editar profissional"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      {profissional && (
        <ViewContainer item={profissional} marginLeft="0">
          <ProfissionalClinicaForm
            initialValues={{ ...profissional }}
            onFinish={onFinish}
            error={error}
            setError={setError}
            loading={loading}
            isEdit
          />
        </ViewContainer>
      )}
    </Modal>
  );
}
