import { notification } from 'antd';

import history from '../../routes/history';
import { cepMask, toDigitsOnly } from '../../utils';
import api from '../api';
import ApiException from '../exception/ApiException';

const registerClinica = async (values) => {
  try {
    values.pessoa_juridica.endereco.cep = toDigitsOnly(
      values.pessoa_juridica.endereco.cep,
    );
    await api.post('clinicas', values);
    history.push('/clinicas');
    notification.open({
      message: 'Clinica adicionada com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteClinica = async (id) => {
  try {
    await api.delete(`clinicas/${id}`);
    notification.open({
      message: 'Clinica deletado com sucesso',
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getClinicas = async (page, filter = '', paginated = '') => {
  const { data } = await api.get(
    `clinicas?page=${page}&filter=${filter}&paginated=${paginated}`,
  );
  return data;
};

const getClinica = async (id) => {
  try {
    const { data } = await api.get(`clinicas/${id}`);
    if (data) {
      data.pessoa_juridica.endereco.cep = cepMask(
        data?.pessoa_juridica?.endereco?.cep,
      );
    }
    return data;
  } catch (error) {
    console.log(error);
    throw new ApiException(error?.response?.data?.error);
  }
};

const updateClinica = async (clinica, values) => {
  try {
    values.id = clinica.id;
    values.pessoa_juridica.endereco_id = clinica.endereco_id;
    values.pessoa_juridica_id = clinica.pessoa_juridica_id;
    values.pessoa_juridica.endereco_id = clinica.pessoa_juridica.endereco_id;
    values.pessoa_juridica.endereco.cep = toDigitsOnly(
      values.pessoa_juridica.endereco.cep,
    );
    await api.put('clinicas', values);
    history.push('/clinicas');
    notification.open({
      message: 'Clinica atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerClinica,
  deleteClinica,
  getClinicas,
  getClinica,
  updateClinica,
};
