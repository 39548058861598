import { notification } from "antd";
import moment from "moment";

import history from "../../routes/history";
import api from "../api";
import ApiException from "../exception/ApiException";

const ERROR = "Não foi possível atualizar a fila de espera";

const normalizeFilaEspera = (values) => {
  values.procedimento_id = JSON.parse(values.procedimento).id;
  values.data = moment(values.data).format("Y-M-D HH:mm");
  delete values.procedimento;
  delete values.created_by;
  delete values.updated_by;
  delete values.marcador;
};

const onGetFilaEspera = (values) => {
  values.procedimento = JSON.stringify(values.procedimento);
  values.data = moment(values.data, "Y-M-D HH:mm");
};

const registerFilaEspera = async (values) => {
  try {
    normalizeFilaEspera(values);
    await api.post("fila-espera", values);
    history.push("/fila-espera");
    notification.open({
      message: "Adicionado com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error || ERROR);
  }
};

const deleteFilaEspera = async (id) => {
  try {
    await api.delete(`fila-espera/${id}`);
    notification.open({
      message: "Fila de espera deletada com sucesso",
    });
  } catch (error) {
    notification.open({
      message: error?.response?.data?.error,
    });
  }
};

const getFilasEspera = async (page, filter = "") => {
  const { data } = await api.get(`fila-espera?page=${page}&filter=${filter}`);
  return data;
};

const getFilaEspera = async (id) => {
  try {
    const { data } = await api.get(`fila-espera/${id}`);
    onGetFilaEspera(data);
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data?.error || ERROR);
  }
};

const updateFilaEspera = async (filaEspera, values) => {
  try {
    normalizeFilaEspera(values);
    values.id = filaEspera.id;
    await api.put("fila-espera", values);
    history.push("/fila-espera");
    notification.open({
      message: "Fila de espera atualizada com sucesso!",
    });
  } catch (error) {
    console.log(error);
    throw new ApiException(error?.response?.data?.error || ERROR);
  }
};

export {
  updateFilaEspera,
  getFilaEspera,
  getFilasEspera,
  deleteFilaEspera,
  registerFilaEspera,
};
