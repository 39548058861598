import React, { useContext, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Table, Space, Popconfirm, Button, Input,
} from 'antd';
import { useDispatch } from 'react-redux';

import { VerticalContainer } from '../../../components/styled';
import { UserContext } from '../../../context';
import history from '../../../routes/history';
import { getPacientes, deletePaciente } from '../../../services/pacientes';
import { Creators as PacienteActions } from '../../../store/ducks/pacientes';

const { Search } = Input;
const { Column } = Table;
export default function ListPacientes() {
  const user = useContext(UserContext);
  const [filter, setFilter] = useState('');
  const [pacientes, setPacientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const dispatch = useDispatch();
  const getPacientesPaginated = async () => {
    try {
      setLoading(true);
      const pacientesData = await getPacientes(page, filter);
      setPacientes(pacientesData.data);
      setTotal(pacientesData.total);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPacientesPaginated();
  }, [page, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deletePaciente(record.id);
    await getPacientesPaginated();
  }

  function cancel(e) {
    console.log(e);
  }

  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter('');
  };

  return (
    <>
      <VerticalContainer>
        <Search
          onChange={onChange}
          style={{ width: '50%' }}
          placeholder="Pesquisar paciente, cartão sus, email"
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            history.push('adicionar-paciente');
          }}
          style={{ width: '100%!important' }}
          block
        >
          <PlusOutlined />
          Adicionar paciente
        </Button>
        <br />
        <Table
          loading={loading}
          size="small"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{ width: '100%' }}
          dataSource={pacientes}
          rowKey="id"
        >
          <Column title="Código" dataIndex="id" key="id" />
          <Column
            title="Nome"
            dataIndex={['pessoa_fisica', 'nome']}
            key="nome"
          />
          <Column
            title="Responsável"
            dataIndex="responsavel"
            key="responsavel"
          />
          <Column title="Cartão SUS" dataIndex="cartao_sus" key="cartao_sus" />
          <Column
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => history.push(`pacientes/${record.id}`)}>
                  Visualizar
                </a>
                {user?.type === 'ADM'
                && (
                <Popconfirm
                  title="Deseja deletar o paciente?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
      </VerticalContainer>
    </>
  );
}
