import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PacienteForm from '../../../components/pacientes/forms';
import { showPacientes } from '../../../components/pacientes/modal';
import { VerticalContainer } from '../../../components/styled';
import {
  getPacientesByCnh,
  getPacientesByCpf,
  getPacientesByRg
} from '../../../services/pacientes';
import { Creators as PacienteActions } from '../../../store/ducks/pacientes';

export default function AddPaciente() {
  const [error, setError] = useState();
  const [paciente, setPaciente] = useState();
  const { loading, error: erro } = useSelector((state) => state.paciente);
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    setPaciente(values);
    dispatch(PacienteActions.registerPacienteRequest(values));
  };

  const handleConfirm = async () => {
    if (paciente) {
      if (erro && erro.includes('CPF')) {
        const data = await getPacientesByCpf(paciente?.pessoa_fisica?.cpf);
        showPacientes(data, 'CPF');
      }
      if (erro && erro.includes('CNH')) {
        const data = await getPacientesByCnh(paciente?.pessoa_fisica?.cnh);
        showPacientes(data, 'CNH');
      }
      if (erro && erro.includes('RG')) {
        const data = await getPacientesByRg(paciente?.pessoa_fisica?.rg);
        showPacientes(data, 'RG');
      }
    }
  };
  useEffect(() => {
    setError(erro);
    handleConfirm();
  }, [erro]);

  useEffect(() => {
    setError('');
  }, []);

  return (
    <VerticalContainer>
      <div />
      <PacienteForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </VerticalContainer>
  );
}
