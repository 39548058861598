import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  registerEntity, updateEntity, getEntities,
} from '../../services/entity';
import { Creators as EntidadeActions, Types } from '../ducks/entidade';

function* register(action) {
  try {
    yield call(registerEntity, action.entidade);
    yield put(EntidadeActions.registerEntidadeSuccess());
  } catch (error) {
    yield put(EntidadeActions.requestEntidadeFailure(error?.error));
  }
}

function* update(action) {
  try {
    yield call(updateEntity, action.entidade);
    yield put(EntidadeActions.updateEntidadeSuccess());
  } catch (error) {
    yield put(EntidadeActions.requestEntidadeFailure(error?.error));
  }
}

function* getAll() {
  try {
    const entidade = yield call(getEntities);
    yield put(EntidadeActions.getAllEntidadeSuccess(entidade));
  } catch (error) {
    yield put(EntidadeActions.requestEntidadeFailure(error?.error));
  }
}

export function* rootEntidadeSaga() {
  yield all([takeLatest(Types.REGISTER_ENTIDADE_REQUEST, register)]);
  yield all([takeLatest(Types.GET_ALL_ENTIDADE_REQUEST, getAll)]);
  yield all([takeLatest(Types.UPDATE_ENTIDADE_REQUEST, update)]);
}
