import { notification } from 'antd';

import api from '../../api';
import ApiException from '../../exception/ApiException';

const registerProfissionalClinica = async (values) => {
  try {
    await api.post('profissional-clinica', values);
    notification.open({
      message: 'Profissional adicionado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteProfissionalClinica = async (id) => {
  try {
    await api.delete(`profissional-clinica/${id}`);
    notification.open({
      message: 'Profissional deletado com sucesso',
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getProfissionalClinicas = async (
  page,
  filter = '',
  clinica,
  paginated = '',
) => {
  try {
    const idClinica = clinica.id;
    const { data } = await api.get(
      `profissional-clinica?page=${page}&filter=${filter}&clinica=${idClinica}&paginated=${paginated}`,
    );
    return data;
  } catch (err) {
    throw new ApiException('Não foi possível obter os dados dos profissionais da clínica');
  }
};

const updateProfissionalClinica = async (profissional, values) => {
  try {
    values.id = profissional.id;
    await api.put('profissional-clinica', values);
    notification.open({
      message: 'Profissional atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerProfissionalClinica,
  deleteProfissionalClinica,
  getProfissionalClinicas,
  updateProfissionalClinica,
};
