import React, { useState } from 'react';

import { Modal } from 'antd';

import DistrictForm from '../../../components/districts/form';
import { registerDistrict } from '../../../services/districts';

export default function AddDistrict({ visible, setVisible, onConfirm }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await registerDistrict(values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      console.log(err.error);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Adicionar distrito"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <DistrictForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </Modal>
  );
}
