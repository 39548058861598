import { Button, Card, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import FilaEsperaForm from "../../../components/fila_espera/forms";
import { getFilaEspera, updateFilaEspera } from "../../../services/fila_espera";
import EditButton from "../../../components/buttons/edit";
import {
  InlineContainer,
  SecondaryTextJustify,
  TitleHeader,
  VerticalContainer,
} from "../../../components/styled";
import ViewContainer from "../../../components/view_container";
import { SITUACOES } from "../../../models/solicitacoes";

const situacoesStyle = {
  LANÇADO: "yellow",
  CANCELADO: "red",
  APROVADO: "green",
};
export default function EditFilaEspera({ computedMatch }) {
  const [error, setError] = useState();
  const [filaEspera, setFilaEspera] = useState();
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const { params } = computedMatch;
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateFilaEspera(filaEspera, values);
    } catch (err) {
      console.log(err);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilaEspera = async () => {
    const data = await getFilaEspera(params.id);
    setFilaEspera(data);
  };

  useEffect(() => {
    handleFilaEspera();
  }, []);

  return (
    <>
      {filaEspera ? (
        <VerticalContainer>
          <InlineContainer>
            <div />
            <EditButton viewMode={viewMode} setViewMode={setViewMode} />
          </InlineContainer>
          <Card title="Informações" style={{ borderColor: "white" }}>
            {/* <SecondaryTextJustify>
              {" "}
              Situação:{" "}
              <Tag color={situacoesStyle[filaEspera.situacao]}>
                {filaEspera.situacao}
              </Tag>
            </SecondaryTextJustify> */}

            <SecondaryTextJustify>
              {" "}
              Lançamento: {filaEspera?.marcador?.name}
            </SecondaryTextJustify>
          </Card>
          <ViewContainer item={filaEspera}>
            <FilaEsperaForm
              initialValues={{ ...filaEspera }}
              onFinish={onFinish}
              error={error}
              setError={setError}
              loading={loading}
              viewMode={viewMode}
            />
          </ViewContainer>
        </VerticalContainer>
      ) : (
        <Spin />
      )}
    </>
  );
}
