import moment from 'moment';

import { cepMask, toDigitsOnly } from '../../utils';

const formatData = 'DD/MM/YYYY';

const normalizePessoaFisica = (pessoaFisica) => {
  pessoaFisica.endereco.cep = cepMask(pessoaFisica?.endereco?.cep);
  const dataNascimento = pessoaFisica.data_nascimento;
  if (dataNascimento) {
    const dataNascimentoFormatada = moment(dataNascimento).format(formatData);
    pessoaFisica.data_nascimento = moment(dataNascimentoFormatada, formatData);
  }
  const dataEmissaoRg = pessoaFisica.data_emissao_rg;
  if (dataEmissaoRg) {
    const dataEmissaoFormatada = moment(dataEmissaoRg).format(formatData);
    pessoaFisica.data_emissao_rg = moment(dataEmissaoFormatada, formatData);
  }
};

const preparePessoaFisica = (values, object) => {
  const pessoaFisica = object.pessoa_fisica;
  values.pessoa_fisica.endereco.cep = toDigitsOnly(
    values.pessoa_fisica.endereco.cep,
  );
  values.pessoa_fisica.endereco_id = pessoaFisica.endereco_id;
  values.pessoa_fisica_id = object.pessoa_fisica_id;
};

export { normalizePessoaFisica, preparePessoaFisica };
