import React from 'react';

import {
  Alert, Button, Form, InputNumber, Tooltip
} from 'antd';

import { FormItemInput } from '../../ant-wrapper/form-item';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function ConfigurationForm({
    onFinish,
    setError,
    error,
    initialValues = {},
    loading = false,
    viewMode = false,
  }) {
    const [form] = Form.useForm();
    const [value, setValue] = React.useState(1);
    const onEdit = () => {
      setError();
    };
    return (
      <>
        {error && (
          <>
            <Alert
              message={error}
              type="error"
            />
            <br />
          </>
        )}
        <Form
          form={form}
          onChange={onEdit}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={initialValues}
        >
          <FormItemInput
            {...formItemLayout}
            name="extensao"
            required
            label="Extensão"
            viewMode={viewMode}
          />
          <Form.Item
            {...formItemLayout}
            name="tamanho_maximo"
            required
            label="Tamanho Máximo (Bytes)"
          >
            <InputNumber
              min={0}
              style={{ width: "100%" }}
              disabled={viewMode || value != 1}
            />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }