import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  List,
  Upload,
} from "antd";

import { FormItemInput, FormItemSelect } from "../../ant-wrapper/form-item";
import { getPacientes } from "../../../services/pacientes";
import { InlineContainer } from "../../../components/styled";

import locale from "antd/es/date-picker/locale/pt_BR";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import { getTypes } from "../../../services/tipodocumentos";
import { UploadOutlined } from "@ant-design/icons";
import getDocumento from "../../../services/documentos";

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 14 },
};
export default function ProtocoloForm({
  paciente,
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
  isEdit = false,
}) {
  const [pacientes, setPacientes] = useState([]);
  const [form] = Form.useForm();
  const [protocoloStatus, setProtocoloStatus] = useState("");
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const [tipoDocumento, setTipoDocumento] = useState();
  const [documentos, setDocumentos] = useState([]);
  const [documentosRemovidos, setDocumentosRemovidos] = useState([]);
  const [documentosSalvos, setDocumentosSalvos] = useState([]);

  const onChangeTipoDocumento = (tipo) => {
    setTipoDocumento(JSON.parse(tipo));
  };

  const onEdit = () => {
    setError();
  };

  const onChangeFile = (file) => {
    setDocumentos([
      ...documentos,
      { tipo_documento_id: tipoDocumento.id, arquivo: file },
    ]);
  };

  const deletarDocumento = (documento) => {
    setDocumentosSalvos([
      ...documentosSalvos.filter((dc) => dc.id !== documento.id),
    ]);
    setDocumentosRemovidos([...documentosRemovidos, documento.id]);
  };

  const getData = async () => {
    const tipoDocumentos = await getTypes("", "", false);
    setTipoDocumentos(tipoDocumentos);
    if (initialValues?.documentos) {
      for (let doc of initialValues?.documentos) {
        if (!doc.url.startsWith("blob:")) {
          let urlDownload = await getDocumento(doc.url);
          doc.url = urlDownload;
        }
      }
      setDocumentosSalvos(initialValues?.documentos);
    }
  };
  const onRemoveFile = (file) => {
    setDocumentos([
      ...documentos.filter(
        (doc) => doc.arquivo.name !== file.originFileObj.name
      ),
    ]);
  };
  const onSave = (values) => {
    delete values.tipoDocumentos;
    values.documentos = documentos;
    values.documentos_removidos = documentosRemovidos;
    values.paciente_id = paciente.id;
    onFinish(values);
  };

  useEffect(() => {
    getData();
    if (initialValues?.data_saida) {
      setProtocoloStatus("DEVOLVIDO");
    } else {
      setProtocoloStatus("PROTOCOLADO");
    }
  }, []);

  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onSave}
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <Col span={8}></Col>
        <Form.Item
          label="Data de entrada"
          name="data_entrada"
          rules={[
            {
              required: true,
              message: "Data entrada é obrigatório!",
            },
          ]}
        >
          <DatePicker
            showTime
            locale={locale}
            style={{ width: "100%" }}
            disabled={viewMode}
            format="DD/MM/YYYY HH:mm"
            placeholder="Seleciona a data"
          />
        </Form.Item>

        {isEdit && (
          <Form.Item
            label="Data de saida"
            name="data_saida"
            rules={[
              {
                required: true,
                message: "Data de saída é obrigatório!",
              },
            ]}
          >
            <DatePicker
              showTime
              locale={locale}
              style={{ width: "100%" }}
              // disabled={viewMode}
              format="DD/MM/YYYY HH:mm"
              placeholder="Seleciona a data"
            />
          </Form.Item>
        )}

        <Form.Item label="Observação (Entrada)" name="observacao">
          <TextArea rows={4} disabled={viewMode} />
        </Form.Item>

        {isEdit && (
          <Form.Item label="Observação (Saída)" name="observacao_saida">
            <TextArea rows={4} />
          </Form.Item>
        )}

        {documentosSalvos && documentosSalvos.length > 0 && (
          <>
            <Divider plain>Documentos anexados</Divider>
            <List
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={documentosSalvos}
              renderItem={(documento) => (
                <List.Item
                  actions={[
                    <a download={documento.nome} href={documento.url}>
                      Visualizar
                    </a>,
                    <>
                      {initialValues?.status !== "DEVOLVIDO" && (
                        <a
                          onClick={() => deletarDocumento(documento)}
                          key="list-loadmore-more"
                        >
                          Deletar
                        </a>
                      )}
                    </>,
                  ]}
                >
                  <div>
                    {documento?.nome?.substring(0, 20) +
                      "..." +
                      documento?.nome?.slice(-3)}
                    <br />
                    Tipo: {documento?.tipo_documento?.tipo}
                  </div>
                </List.Item>
              )}
            />
          </>
        )}

        {initialValues?.status !== "DEVOLVIDO" && (
          <>
            <Divider plain>Anexar documentos</Divider>
            <div>
              <FormItemSelect
                name="tipoDocumentos"
                label="Tipo documento"
                items={tipoDocumentos}
                onChangeSelect={onChangeTipoDocumento}
                itemsLabel={"tipo"}
                stringfyValue
                viewMode={viewMode}
              />

              <Upload
                onRemove={onRemoveFile}
                style={{ width: "100%!important" }}
                action={onChangeFile}
              >
                <Button
                  disabled={!tipoDocumento}
                  style={{ width: "100%!important" }}
                  type="dashed"
                  icon={<UploadOutlined />}
                >
                  Adicionar novo arquivo +
                </Button>
              </Upload>
            </div>
          </>
        )}
        <br />
        <Form.Item>
          {initialValues?.status === "PROTOCOLADO" && isEdit ? (
            <Button loading={loading} type="primary" htmlType="submit">
              Devolução
            </Button>
          ) : (
            ""
          )}
          {!isEdit ? (
            <Button loading={loading} type="primary" htmlType="submit">
              Enviar
            </Button>
          ) : (
            ""
          )}
        </Form.Item>
      </Form>
    </>
  );
}
