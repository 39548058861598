import React, { useState } from 'react';

import {
  Alert, Button, Form, Input, notification, PageHeader,
} from 'antd';

import {
  BackgroundStyle,
  Center,
  SecondaryText,
} from '../../../components/styled';
import history from '../../../routes/history';
import api from '../../../services/api';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function ChangePassword({ computedMatch }) {
  const { params } = computedMatch;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [edit, setEdit] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      setEdit(true);
      values.hash = params.hash;
      await api.post('users/change-password', values);
      notification.open({
        message: 'Seja alterada com sucesso.',
        description: 'Realize novamente o login para ter acesso ao sistema.',
      });
      history.push('/');
    } catch (err) {
      console.log(err.response.data.err);
      setError(err.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center>
      <BackgroundStyle />
      {/* <Title style={{textAlign:'center'}}>Alterar senha</Title> */}
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/')}
        title="Alterar senha"
      />
      <SecondaryText>*Digite sua nova senha nos campos abaixo</SecondaryText>
      {edit && error && <Alert message={error} type="error" />}
      <br />
      <Form
        form={form}
        onChange={() => {
          setEdit(false);
          setError();
        }}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          {...formItemLayout}
          name="password"
          label="Senha"
          rules={[
            {
              required: true,
              message: 'Senha é obrigatória!',
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="password_confirmation"
          label="Confirmação da senha"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Confirmação da senha é obrigatória!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject('As senhas informadas não são iguais!');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </Center>
  );
}
