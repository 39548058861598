import React from "react";

import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";

import "moment/locale/pt-br";
import { RULE_EMAIL, RULE_REQUIRED } from "../utils";

const rules = (required, email = false) => {
  const rulesField = [];
  if (required) rulesField.push(RULE_REQUIRED);
  if (email) rulesField.push(RULE_EMAIL);
  return rulesField;
};

const { Option } = Select;
const FormItemInput = ({
  viewMode,
  onChangeInput,
  email,
  required,
  ...props
}) => (
  <Form.Item rules={rules(required, email)} {...props}>
    <Input
      defaultValue={props.defaultValue}
      disabled={viewMode}
      onChange={onChangeInput}
    />
  </Form.Item>
);
function resolve(path, obj, separator = ".") {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
}
const FormItemSelect = ({
  viewMode,
  onChangeSelect,
  required,
  items,
  itemsValue = "value",
  itemsLabel = "label",
  stringfyValue,
  render,
  onSearch,
  ...props
}) => (
  <Form.Item rules={rules(required)} {...props}>
    <Select
      onSearch={onSearch}
      disabled={viewMode}
      onChange={onChangeSelect}
      showSearch
    >
      {items.length > 0 &&
        items.map((item) => (
          <Option
            value={stringfyValue ? JSON.stringify(item) : item[itemsValue]}
          >
            {render ? render(item) : resolve(itemsLabel, item)}
          </Option>
        ))}
    </Select>
  </Form.Item>
);

const FormItemDatePicker = ({
  viewMode,
  onChangeInput,
  required,
  ...props
}) => (
  <Form.Item rules={rules(required)} {...props}>
    <DatePicker
      style={{ width: "100%" }}
      locale={locale}
      disabled={viewMode}
      format="DD/MM/YYYY"
      placeholder="Seleciona a data"
    />
  </Form.Item>
);

const { RangePicker } = DatePicker;

const FormItemRangePicker = ({ viewMode, required, ...props }) => (
  <Form.Item rules={rules(required)} {...props}>
    <RangePicker
      style={{ width: "100%" }}
      locale={locale}
      disabled={viewMode}
      format="DD/MM/YYYY"
    />
  </Form.Item>
);

const FormItemCheckbox = ({
  viewMode,
  onChangeCheckBox,
  required,
  checkboxLabel,
  ...props
}) => (
  <Form.Item rules={rules(required)} {...props}>
    <Checkbox
      style={{ width: "100%" }}
      onChange={onChangeCheckBox}
      disabled={viewMode}
    >
      {checkboxLabel}
    </Checkbox>
  </Form.Item>
);

export {
  FormItemSelect,
  FormItemCheckbox,
  FormItemDatePicker,
  FormItemInput,
  FormItemRangePicker,
};
