import React, { useContext } from 'react';

import {
  MenuFoldOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import {
  Layout, Menu, Dropdown, Avatar,
} from 'antd';
import { useDispatch } from 'react-redux';

import { UserContext } from '../../context';
import history from '../../routes/history';
import { Creators as AuthActions } from '../../store/ducks/auth';

const { Header: HeaderAntd } = Layout;

export default function Header({ collapsed, toggleCollapsed }) {
  const dispath = useDispatch();
  const user = useContext(UserContext);
  const menu = (
    <Menu>
      <Menu.Item
        key="0"
        icon={<UserOutlined />}
        onClick={() => history.push('/conta')}
      >
        Conta
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        onClick={() => {
          dispath(AuthActions.logout());
        }}
        icon={<LogoutOutlined />}
        key="1"
      >
        Sair
      </Menu.Item>
    </Menu>
  );

  return (
    <HeaderAntd className="site-layout-background" style={{ padding: 0 }}>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'collapse-button',
        onClick: toggleCollapsed,
      })}
      <Dropdown overlay={menu} trigger={['click']}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <Avatar
            style={{ marginRight: 10 }}
            size="small"
            icon={<UserOutlined />}
          />
          {user?.name}
          <DownOutlined style={{ marginLeft: 5, marginRight: 20 }} />
        </a>
      </Dropdown>
    </HeaderAntd>
  );
}
