import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import history from '../../routes/history';
import api from '../../services/api';
import { unsetToken } from '../../utils';
import { Creators as AuthActions, Types } from '../ducks/auth';

function* login(action) {
  try {
    const response = yield call(api.post, '/login', action.user);
    yield put(AuthActions.loginSuccess(response.data.access_token));
    if (!action.user.noRedirect) history.push('home');
  } catch (error) {
    error.response
      ? yield put(AuthActions.loginFailure(error.response?.data?.error))
      : console.log(error);
  }
}

function* get() {
  try {
    const { data: user } = yield call(api.get, '/user');
    yield put(AuthActions.getUserSuccess(user));
  } catch (error) {
    unsetToken();
    history.push('/');
    yield put(AuthActions.getUserFailure());
  }
}

export function* rootAuthSaga() {
  yield all([takeLatest(Types.LOGIN_REQUEST, login)]);
  yield all([takeLatest(Types.GET_USER_REQUEST, get)]);
}
