import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  registerClinicaRequest: ['clinica'],
  registerClinicaSuccess: null,

  updateClinicaRequest: ['clinica', 'values'],
  updateClinicaSuccess: null,

  getAllClinicaRequest: null,
  getAllClinicaSuccess: ['clinicas'],

  deleteClinicaRequest: ['id'],
  deleteClinicaSuccess: null,

  requestClinicaFailure: ['error'],
});

const INITIAL_STATE = { clinicas: [], loading: false, error: '' };

/**
 * REGISTER REQUESTS BELLOW
 */

const registerClinicaRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const registerClinicaSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * UPDATE REQUESTS BELLOW
 */

const deleteClinicaRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const deleteClinicaSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});
/**
 * UPDATE REQUESTS BELLOW
 */

const updateClinicaRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const updateClinicaSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * GET SERVICES
 */

const getAllClinicaRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
  clinicas: [],
});

const getAllClinicaSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: '',
  loading: false,
  clinicas: action.clinicas,
});

/**
 * Request falure
 */
const requestClinicaFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_CLINICA_REQUEST]: registerClinicaRequest,
  [Types.REGISTER_CLINICA_SUCCESS]: registerClinicaSuccess,

  [Types.REQUEST_CLINICA_FAILURE]: requestClinicaFailure,

  [Types.UPDATE_CLINICA_REQUEST]: updateClinicaRequest,
  [Types.UPDATE_CLINICA_SUCCESS]: updateClinicaSuccess,

  [Types.DELETE_CLINICA_REQUEST]: deleteClinicaRequest,
  [Types.DELETE_CLINICA_SUCCESS]: deleteClinicaSuccess,

  [Types.GET_ALL_CLINICA_REQUEST]: getAllClinicaRequest,
  [Types.GET_ALL_CLINICA_SUCCESS]: getAllClinicaSuccess,
});
