import { notification } from 'antd';

import history from '../../routes/history';
import { cepMask, toDigitsOnly } from '../../utils';
import api from '../api';
import ApiException from '../exception/ApiException';

const normalizeValues = (values) => {
  values.cnae = JSON.parse(values.cnae);
  values.natureza = JSON.parse(values.natureza);
  values.pessoa_juridica.endereco.cep = toDigitsOnly(
    values.pessoa_juridica.endereco.cep,
  );
};

const registerEntity = async (values) => {
  try {
    normalizeValues(values);
    await api.post('entidades', values);
    history.push('/entidades');
    notification.open({
      message: 'Entidade atualizada com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const getEntities = async () => {
  const { data } = await api.get('entidades');
  if (data) {
    if(data.pessoa_juridica.endereco.cep != null){
      data.pessoa_juridica.endereco.cep = cepMask(
        data?.pessoa_juridica?.endereco?.cep,
      );
    }else{
      data.pessoa_juridica.endereco.cep = "00.000-00";
    }
    data.cnae = JSON.stringify(data.cnae);
    data.natureza = JSON.stringify(data.natureza);
  }
  return data;
};

const updateEntity = async (entity, values) => {
  try {
    normalizeValues(values);
    const pessoaJuridica = entity.pessoa_juridica;
    values.id = entity.id;
    values.pessoa_juridica.endereco_id = pessoaJuridica.endereco_id;
    values.pessoa_juridica_id = entity.pessoa_juridica_id;
    await api.put('entidades', values);
    history.push('/entidades');
    notification.open({
      message: 'Entidade atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export { registerEntity, getEntities, updateEntity };
