import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getAllEstados: null,
  getAllEstadosSuccess: ['estados', 'cidades'],
  getAllEstadosFailure: ['error'],
});

const INITIAL_STATE = {
  estados: [], loading: false, error: '', cidades: [],
};

/**
 * REGISTER REQUESTS BELLOW
 */

const getAllEstados = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const getAllEstadosSucess = (state = INITIAL_STATE, action) => ({
  ...state,
  estados: action.estados,
  cidades: action.cidades,
  loading: false,
});

/**
 * Request falure
 */
const getAllEstadosFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  estados: [],
  error: action.error,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_ALL_ESTADOS]: getAllEstados,
  [Types.GET_ALL_ESTADOS_SUCCESS]: getAllEstadosSucess,

  [Types.GET_ALL_ESTADOS_FAILURE]: getAllEstadosFailure,
});
