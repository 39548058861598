export const RULE_REQUIRED = {
  required: true,
  message: "Campo obrigatório!",
};
export const RULE_EMAIL = {
  type: "email",
  message: "Email inválido!",
};

export const SEXO_FIELDS = [
  {
    label: "Masculino",
    value: "M",
  },
  {
    label: "Feminino",
    value: "F",
  },
];

export const PRIORIDADE_FIELDS = [
  {
    label: "ELETIVO",
    value: "ELETIVO",
  },
  {
    label: "PRIORITÁRIO",
    value: "PRIORITARIO",
  },
  {
    label: "URGÊNCIA",
    value: "URGENCIA",
  },
];

export const ESTADO_CIVIL_FIELDS = [
  {
    label: "Solteiro(a)",
    value: "Solteiro(a)",
  },
  {
    label: "União Estável",
    value: "União Estável",
  },
  {
    label: "Casado(a)",
    value: "Casado(a)",
  },
  {
    label: "Separado(a)",
    value: "Separado(a)",
  },
  {
    label: "Desquitado(a)",
    value: "Desquitado(a)",
  },
  {
    label: "Viuvo(a)",
    value: "Viuvo(a)",
  },
];

export const NACIONALIDADE_FIELDS = [
  {
    value: "Brasileiro(a)",
    label: "Brasileiro(a)",
  },
  {
    value: "Argentino(a)",
    label: "Argentino(a)",
  },
  {
    value: "Americano(a)",
    label: "Americano(a)",
  },
  {
    value: "Paraguaio(a)",
    label: "Paraguaio(a)",
  },
  {
    value: "Uruguaio(a)",
    label: "Uruguaio(a)",
  },
  {
    value: "Colombiano(a)",
    label: "Colombiano(a)",
  },
  {
    value: "Chileno(a)",
    label: "Chileno(a)",
  },
  {
    value: "Venezuelano(a)",
    label: "Venezuelano(a)",
  },
];
