import React from 'react';

import moment from 'moment';

export default function DetailPacientBox({ paciente }) {
  return (
    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
      <div style={{ marginBottom: '5px' }}>
        <b>Distrito:</b>
        <span style={{ marginLeft: '10px' }}>
          {' '}
          {paciente?.distrito?.nome}
        </span>
      </div>
      <div style={{ marginBottom: '5px' }}>
        <b>Agente:</b>
        <span style={{ marginLeft: '10px' }}>
          {' '}
          {paciente?.agente?.nome}
        </span>
      </div>

      <div style={{ marginBottom: '5px' }}>
        <b>CPF/RG:</b>
        <span style={{ marginLeft: '10px' }}>
          {' '}
          {paciente?.pessoa_fisica?.cpf || paciente?.pessoa_fisica?.rg}
        </span>
      </div>

      <div style={{ marginBottom: '5px' }}>
        <b>Nascimento:</b>
        <span style={{ marginLeft: '10px' }}>
          {' '}
          {paciente?.pessoa_fisica?.data_nascimento
            ? moment(paciente?.pessoa_fisica?.data_nascimento).format(
              'DD/MM/YYYY',
            )
            : ''}
        </span>
      </div>

      <div style={{ marginBottom: '5px' }}>
        <b>Mãe:</b>
        <span style={{ marginLeft: '10px' }}>
          {' '}
          {paciente?.nome_mae}
        </span>
      </div>
    </div>
  );
}
