import React, { useEffect, useState } from "react";

import { Alert, Button, Form, InputNumber, Tooltip } from "antd";

import { FormItemCheckbox, FormItemInput, FormItemSelect } from "../../ant-wrapper/form-item";

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function ChaveConfiguracaoForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
}) {
  const [form] = Form.useForm();
  const [tipo, setTipo] = useState();
  const [value, setValue] = React.useState(1);

  const tipos = [
    { nome: "String", valor: 0 },
    { nome: "Inteiro", valor: 1 },
    { nome: "Decimal", valor: 2 },
    { nome: "Booleano", valor: 3 },
  ];
  const handleTipoChange = (tipo) =>{
    setTipo(tipo)
    console.log(initialValues)
  }

  const renderValues = (tipo) => {
    switch (tipo) {
      case 0:
        return (
          <FormItemInput
            {...formItemLayout}
            name="string"
            required
            viewMode={viewMode}
            label="Valor"
          />
        );
      case 1:
        return (
          <Form.Item
            {...formItemLayout}
            name="inteiro"
            required
            viewMode={viewMode}
            label="Valor"
          >
            <InputNumber
              min={0}
              style={{ width: "70%" }}
            />
          </Form.Item>
        );
      case 2:
        return(
          <FormItemInput
          {...formItemLayout}
          name="decimal"
          required
          viewMode={viewMode}
          label="Valor"
        />
        )
      case 3:
        return(
          <FormItemCheckbox
          viewMode={viewMode}
          name="booleano"
          checkboxLabel="Ativo"
          valuePropName="checked"
        />
        )
    }
  };

  useEffect(()=>{
    if(initialValues != null && tipo == null){
      setTipo(initialValues.tipo);
    }
  })

  const onEdit = () => {
    setError();
  };
  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={initialValues}
      >
        <FormItemInput
          {...formItemLayout}
          name="nome"
          required
          label="Nome"
          viewMode={viewMode}
        />
        <FormItemSelect
          name="tipo"
          required
          items={tipos}
          onChangeSelect={handleTipoChange}
          itemsLabel="nome"
          itemsValue="valor"
          viewMode={viewMode}
          label="Tipo"
          {...formItemLayout}
        />
        {renderValues(tipo)}
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
