import { Popconfirm, Space, Table, Tag } from "antd";
import React, { useContext } from "react";
import { UserContext } from "../../../context";
import history from "../../../routes/history";

const { Column } = Table;
export default function GestantesTable({
  gestantes,
  loading,
  handleTableChange,
  total,
  confirm,
}) {
  function cancel(e) {
    console.log(e);
  }
  const user = useContext(UserContext);
  return (
    <div>
      <Table
        loading={loading}
        size="small"
        pagination={{ pageSize: 10, total }}
        onChange={handleTableChange}
        style={{ width: "100%" }}
        dataSource={gestantes}
        rowKey="id"
      >
        <Column title="Código" dataIndex="id" key="id" />

        <Column
          title="Gestante"
          dataIndex={["paciente", "pessoa_fisica", "nome"]}
          key="paciente_nome"
        />
        <Column
          title="Trimestre"
          dataIndex="trimestre"
          key="trimestre"
          render={(text, value) =>
            text === "PRIMEIRO_TRIMESTRE"
              ? "1º Trimestre"
              : text === "SEGUNDO_TRIMESTRE"
              ? "2º Trimestre"
              : "3º Trimestre"
          }
        />
        <Column
          title="Ação"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => history.push(`/gestantes/${record.id}`)}>
                Visualizar
              </a>

              {user?.type === "ADM" && (
                <Popconfirm
                  title="Deseja deletar a gestante?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
              )}
            </Space>
          )}
        />
      </Table>
    </div>
  );
}
