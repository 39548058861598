import { createActions, createReducer } from 'reduxsauce';

import history from '../../routes/history';
import { setToken, unsetToken } from '../../utils';

export const { Types, Creators } = createActions({
  loginRequest: ['user'],
  loginSuccess: ['token'],
  loginFailure: ['error'],

  getUserRequest: null,
  getUserSuccess: ['user'],
  getUserFailure: ['error'],

  logout: null,
});

const INITIAL_STATE = { user: {}, loading: false, error: [] };
const loginRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: [],
  loading: true,
});

const loginSuccess = (state = INITIAL_STATE, action) => {
  setToken(action.token);
  return { ...state, error: [], loading: false };
};

const loginFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false,
});

const logout = () => {
  unsetToken();
  history.push('/');
  return INITIAL_STATE;
};

/**
 * GET REQUESTS BELLOW
 */

const getUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: [],
  user: {},
  loading: true,
});

const getUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: [],
  loading: false,
  user: action.user,
});

const getUserFailure = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.LOGOUT]: logout,
  [Types.GET_USER_REQUEST]: getUserRequest,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILURE]: getUserFailure,
});
