import { notification } from 'antd';

import history from '../../routes/history';
import api from '../api';
import ApiException from '../exception/ApiException';

const registerDistrict = async (values) => {
  try {
    await api.post('distritos', values);
    history.push('/distritos');
    notification.open({
      message: 'Distrito adicionado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteDistrict = async (id) => {
  try {
    await api.delete(`distritos/${id}`);
    notification.open({
      message: 'Distrito deletado com sucesso',
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getDistricts = async (page, filter = '', paginated = '') => {
  const { data: districts } = await api.get(
    `distritos?page=${page}&filter=${filter}&paginated=${paginated}`,
  );
  return districts;
};

const getDistrict = async (id) => {
  try {
    const { data } = await api.get(`distritos/${id}`);
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data);
  }
};
const getCidades = async () => {
  const { data } = await api.get('cidades');
  return data;
};

const updateDistrict = async (district, values) => {
  try {
    values.id = district.id;
    await api.put('distritos', values);
    history.push('/distritos');
    notification.open({
      message: 'Distrito atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerDistrict, deleteDistrict, getDistricts, updateDistrict, getCidades, getDistrict,
};
