import React, { useContext } from 'react';

import {
  Popconfirm, Space, Table, Tag,
} from 'antd';
import moment from 'moment';

import { UserContext } from '../../../context';
import history from '../../../routes/history';
import { abrirComprovanteAtendimento } from '../../../services/atendimentos';

const { Column } = Table;

const situacoesStyle = {
  LANÇADO: 'yellow',
  CANCELADO: 'red',
  APROVADO: 'green',
};
export default function AtendimentosTable({
  loading, atendimentos, handleTableChange, total, confirm,
}) {
  const user = useContext(UserContext);
  function cancel(e) {
    console.log(e);
  }
  return (
    <div>
      <Table
        size="small"
          // loading={loading}
        pagination={{ pageSize: 10, total }}
        onChange={handleTableChange}
        style={{ width: '100%' }}
        dataSource={atendimentos}
        rowKey="id"
      >
        <Column title="Código" dataIndex="id" key="id" />
        <Column
          title="Situação"
          dataIndex="situacao"
          key="situacao"
          render={(situacao) => (
            <Tag color={situacoesStyle[situacao]} key={situacao}>
              {situacao}
            </Tag>
          )}
        />
        <Column
          title="Data"
          dataIndex="data"
          render={(data) => moment(data).format('DD/MM/YYYY')}
          key="data"
        />
        <Column
          title="Paciente"
          dataIndex={['paciente', 'pessoa_fisica', 'nome']}
          key="paciente_nome"
        />
        <Column
          title="Profissional"
          dataIndex={['profissional', 'pessoa_fisica', 'nome']}
          key="profissional"
        />
        <Column
          title="Ação"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => history.push(`/atendimentos/${record.id}`)}>
                Visualizar
              </a>
              <a onClick={() => abrirComprovanteAtendimento(record.id)}>
                Comprovante
              </a>
              {user?.type === 'ADM'
              && (
              <Popconfirm
                title="Deseja deletar a solicitação?"
                onConfirm={() => confirm(record)}
                onCancel={cancel}
                okText="Sim"
                cancelText="Não"
              >
                <a>Deletar</a>
              </Popconfirm>
              ) }
            </Space>
          )}
        />
      </Table>
    </div>
  );
}
