import React, { useState } from "react";

import { Modal } from "antd";

import ProtocoloForm from "../../../components/protocolos/forms";
import {
  registerProtocolo,
  updateProtocolo,
} from "../../../services/protocolos";

export default function AddProtocolo({
  paciente,
  visible,
  setVisible,
  onConfirm,
  initialValues,
  isEdit,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (isEdit) {
        await updateProtocolo(initialValues, values);
      } else {
        await registerProtocolo(values);
      }
      setVisible(false);
      onConfirm();
    } catch (err) {
      console.log(err);
      console.log(err.error);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Protocolo"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <ProtocoloForm
        isEdit={isEdit}
        paciente={paciente}
        onFinish={onFinish}
        error={error}
        initialValues={initialValues}
        setError={setError}
        loading={loading}
      />
    </Modal>
  );
}
