import { combineReducers } from 'redux';

import auth from './auth';
import clinica from './clinica';
import cnaeNatureza from './cnae-natureza';
import entidade from './entidade';
import estados from './estados';
import paciente from './pacientes';
import profissional from './profissional';
import unidade from './unidade';
import users from './users';

const createRootReducer = () => combineReducers({
  auth,
  users,
  cnaeNatureza,
  paciente,
  profissional,
  unidade,
  clinica,
  entidade,
  estados,
});

export default createRootReducer;
