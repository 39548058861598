import React, { useContext, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Table, Space, Popconfirm, Button, Input,
} from 'antd';
import { useDispatch } from 'react-redux';

import { VerticalContainer } from '../../../components/styled';
import { UserContext } from '../../../context';
import history from '../../../routes/history';
import { getClinicas, deleteClinica } from '../../../services/clinicas';
import { Creators as ClinicaActions } from '../../../store/ducks/clinica';

const { Search } = Input;
const { Column } = Table;
export default function ListClinicas() {
  const user = useContext(UserContext);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [clinicas, setClinicas] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const dispatch = useDispatch();
  const getClinicasPaginated = async () => {
    try {
      setLoading(true);
      const clinicasData = await getClinicas(page, filter);
      setClinicas(clinicasData.data);
      setTotal(clinicasData.total);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClinicasPaginated();
  }, [page, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deleteClinica(record.id);
    await getClinicasPaginated();
    dispatch(ClinicaActions.getAllClinicaRequest());
  }

  function cancel(e) {
    console.log(e);
  }

  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter('');
  };

  return (
    <>
      <VerticalContainer>
        <Search
          onChange={onChange}
          style={{ width: '50%' }}
          placeholder="Pesquisar clinica, cnpj, email"
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            history.push('adicionar-clinica');
          }}
          style={{ width: '100%!important' }}
          block
        >
          <PlusOutlined />
          {' '}
          Adicionar clinica
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{ width: '100%' }}
          dataSource={clinicas}
          rowKey="id"
          loading={loading}
        >
          <Column title="Código" dataIndex="id" key="id" />
          <Column
            title="Nome"
            dataIndex={['pessoa_juridica', 'nome']}
            key="nome"
          />
          <Column
            title="CNPJ"
            dataIndex={['pessoa_juridica', 'cnpj']}
            key="nome"
          />
          <Column
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => history.push(`clinicas/${record.id}`)}>
                  Visualizar
                </a>
                {user?.type === 'ADM'
                && (
                <Popconfirm
                  title="Deseja deletar a clinica?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
      </VerticalContainer>
    </>
  );
}
