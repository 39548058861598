import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import EditButton from '../../../components/buttons/edit';
import EntityForm from '../../../components/entity/form';
import {
  InlineContainer,
  VerticalContainer,
} from '../../../components/styled';
import ViewContainer from '../../../components/view_container';
import {
  registerEntity,
  updateEntity,
} from '../../../services/entity';

export default function EntityDetail() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const { entidade: entity, loading: allowEdit } = useSelector((state) => state.entidade);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (!entity.id) {
        await registerEntity(values);
      } else {
        await updateEntity(entity, values);
      }
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  console.log(allowEdit);
  return (
    <VerticalContainer>
      <InlineContainer>
        <div />
        <EditButton viewMode={viewMode} setViewMode={setViewMode} />
      </InlineContainer>
      {!allowEdit
        ? (
          <ViewContainer item={entity} marginLeft="27px">
            <EntityForm
              initialValues={{ ...entity }}
              onFinish={onFinish}
              error={error}
              setError={setError}
              loading={loading}
              viewMode={viewMode}
            />
          </ViewContainer>
        )
        : ''}
    </VerticalContainer>
  );
}
