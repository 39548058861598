import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import history from '../../routes/history';
import api from '../../services/api';
import { failureNotification, successNotification } from '../../utils';
import { Creators as UserActions, Types } from '../ducks/users';

function* register(action) {
  try {
    const response = yield call(api.post, '/users', action.user);
    yield put(UserActions.registerUserSuccess(response.data.data));
    successNotification('Usuário cadastrado com sucesso!');
    history.push('/home');
  } catch (error) {
    yield put(UserActions.requestUserFailure(error));
  }
}

function* update(action) {
  try {
    const response = yield call(api.put, '/users', action.user);
    yield put(UserActions.updateUserSuccess(response.data.data));
    successNotification('Usuário atualizado');
    history.push('/users');
  } catch (error) {
    yield put(UserActions.requestUserFailure(error.response.data));
  }
}

function* getAll() {
  try {
    const { data: users } = yield call(api.get, '/users');
    yield put(UserActions.getAllUserSuccess(users.data));
  } catch (error) {
    yield put(UserActions.requestUserFailure(error));
  }
}

function* deleteUser(action) {
  try {
    yield call(api.delete, `/users/${action.user.id}`);
    yield put(UserActions.deleteUserSuccess());
    successNotification('Usuário excluído com sucesso!');
    history.push('/users');
  } catch (error) {
    const dataError = error?.response?.data;
    yield put(UserActions.requestUserFailure(dataError));
    failureNotification(dataError?.error);
  }
}

export function* rootUserSaga() {
  yield all([takeLatest(Types.REGISTER_USER_REQUEST, register)]);
  yield all([takeLatest(Types.GET_ALL_USER_REQUEST, getAll)]);
  yield all([takeLatest(Types.UPDATE_USER_REQUEST, update)]);
  yield all([takeLatest(Types.DELETE_USER_REQUEST, deleteUser)]);
}
