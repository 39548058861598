import React, { useEffect, useState } from 'react';

import { AntDesignOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Alert, Avatar, Button, Form, Upload,
} from 'antd';

import SkeletonContainer from '../../skeleton-container';

export default function LayoutForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
}) {
  const [form] = Form.useForm();
  const [fileSrc, setFileSrc] = useState();
  const onEdit = () => {
    setError();
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
    });
  }
  const onChange = async ({ file }) => {
    const data = await getBase64(file.originFileObj);
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      background: data,
    });
    setFileSrc(data);
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    setFileSrc(values.background);
  }, []);

  return (
    <div>
      {error && (
        <>
          <Alert
            message={error}
            type="error"
          />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={initialValues}
      >
        <SkeletonContainer>
          <Form.Item name="background" label="">
            <Avatar
              shape="square"
              src={fileSrc}
              size={{
                xs: 500,
                sm: 500,
                md: 500,
                lg: 500,
                xl: 500,
                xxl: 500,
              }}
              icon={<AntDesignOutlined />}
            />
            <br />
            <br />
            <Upload onChange={onChange} maxCount={1} name="background">
              <Button disabled={viewMode} icon={<UploadOutlined />}>
                Clique para alterar
              </Button>
            </Upload>
          </Form.Item>
          {!viewMode && (

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>

          )}
        </SkeletonContainer>

      </Form>
    </div>
  );
}
