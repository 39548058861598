import React, { useState } from 'react';

import { Modal } from 'antd';

import AgenteSaudeForm from '../../../../components/districts/agentes';
import { registerAgenteSaude } from '../../../../services/districts/agentes';

export default function AddAgenteSaude({
  distrito,
  visible,
  setVisible,
  onConfirm,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      values.distrito_id = distrito.id;
      await registerAgenteSaude(values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      console.log(err.error);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Adicionar agente de saúde"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <AgenteSaudeForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </Modal>
  );
}
