import { notification } from "antd";

import history from "../../routes/history";
import api from "../api";
import ApiException from "../exception/ApiException";

const registerConfig = async (values) => {
    try {
      await api.post("configuracoes-arquivos", values);
      history.push("/configuracao-arquivos");
      notification.open({
        message: "Configuração de arquivo adicionado com sucesso!",
      });
    } catch (error) {
      throw new ApiException(error?.response?.data?.error);
    }
  };


  const deleteConfig = async (id) => {
    try {
      await api.delete(`configuracoes-arquivos/${id}`);
      notification.open({
        message: "Configuração de arquivo deletado com sucesso",
      });
    } catch (error) {
      notification.open({
        message: error.response.data.error,
      });
    }
  };

  const getConfigs = async (page, filter = "", paginated = "") => {
    const { data: configuracoes } = await api.get(
      `configuracoes-arquivos?page=${page}&filter=${filter}&paginated=${paginated}`
    );
    return configuracoes;
  };
  
  const getConfig = async (id) => {
    try {
      const { data } = await api.get(`configuracoes-arquivos/${id}`);
      return data;
    } catch (error) {
      throw new ApiException(error?.response?.data);
    }
  };

  const updateConfig = async (type, values) => {
    try {
      values.id = type.id;
      await api.put("configuracoes-arquivos", values);
      history.push("/configuracao-arquivos");
      notification.open({
        message: "Configuração de arquivo atualizado com sucesso!",
      });
    } catch (error) {
      throw new ApiException(error?.response?.data?.error);
    }
  };
  
  export { registerConfig, deleteConfig, getConfigs, updateConfig, getConfig };