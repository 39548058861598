import { useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Popconfirm, Space, Table } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";

import "moment/locale/pt-br";
import { TitleHeader, VerticalContainer } from "../../../components/styled";
import { deleteQuota, getQuotas } from "../../../services/quotas";
import AddQuota from "../add";
import EditQuota from "../edit";

const { Search } = Input;
const { Column } = Table;
export default function ListQuotas() {
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAddQuota, setVisibleAddQuota] = useState(false);
  const [quota, setQuota] = useState({});
  const [quotas, setQuotas] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [mounth, setMounth] = useState(moment().format("MM"));
  const [year, setYear] = useState(moment().format("YYYY"));

  const getQuotasPaginated = async () => {
    try {
      setLoading(true);
      const quotasData = await getQuotas(page, mounth, year, filter);
      setQuotas(quotasData.data);
      setTotal(quotasData.total);
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = async () => {
    await getQuotasPaginated();
  };
  useEffect(() => {
    getQuotasPaginated();
  }, [page, mounth, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deleteQuota(record.id);
    await getQuotasPaginated();
  }
  const showDrawer = (record) => {
    const quotaData = { ...record };
    quotaData.procedimento = JSON.stringify(record.procedimento);
    if (quotaData.mes) {
      quotaData.mes = moment(quotaData.mes, "Y-M-D HH:mm");
    }
    setQuota(quotaData);
    setVisible(true);
  };

  const onChangeFilter = (value) => {
    const valueFormated = value.format("MM");
    const valueFormatedYear = value.format("YYYYY");
    setMounth(valueFormated);
    setYear(valueFormatedYear);
  };

  function cancel(e) {
    console.log(e);
  }

  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter("");
  };

  return (
    <>
      <VerticalContainer>
        <TitleHeader style={{ marginBottom: "0px", fontSize: "15px" }}>
          Selecione o mês para filtrar as quotas
        </TitleHeader>
        <DatePicker
          picker="month"
          format="MMM [de] YYYY"
          onChange={onChangeFilter}
          placeholder="Seleciona a mês"
          locale={locale}
          defaultValue={moment()}
        />
        <br />
        <Search
          onChange={onChange}
          style={{ width: "50%" }}
          placeholder="Pesquisar quota por procedimento"
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            setVisibleAddQuota(true);
          }}
          style={{ width: "100%!important" }}
          block
        >
          <PlusOutlined /> Adicionar quota
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{ width: "100%" }}
          dataSource={quotas}
          rowKey="id"
          loading={loading}
        >
          <Column title="Código" dataIndex="id" key="id" />
          <Column
            title="Procedimento"
            dataIndex={["procedimento", "nome"]}
            key="procedimento"
          />
          <Column title="Quantidade" dataIndex="quantidade" key="quantidade" />

          {/* <Column title="Usado" dataIndex="usado" key="usado" /> */}
          <Column title="Saldo" dataIndex="saldo" key="saldo" />
          <Column
            title="Quantidade Emergencial"
            dataIndex="quantidade_emergencial"
            key="quantidade_emergencial"
          />
          <Column
            title="Saldo emergencial"
            dataIndex="saldo_emergencial"
            key="saldo_emergencial"
          />
          <Column
            title="Data de criação"
            dataIndex="created_at"
            render={(data) => moment(data).format("DD/MM/YYYY")}
            key="created_at"
          />
          <Column
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => showDrawer(record)}>Editar</a>
                <Popconfirm
                  title="Deseja deletar a quota?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
        {visible ? (
          <EditQuota
            visible={visible}
            setVisible={setVisible}
            quota={quota}
            onConfirm={onConfirm}
          />
        ) : (
          ""
        )}
        {visibleAddQuota ? (
          <AddQuota
            visible={visibleAddQuota}
            setVisible={setVisibleAddQuota}
            onConfirm={onConfirm}
          />
        ) : (
          ""
        )}
      </VerticalContainer>
    </>
  );
}
