import React, { useEffect, useState } from "react";

// import { DeleteOutlined } from '@ant-design/icons';
import { Table, Space, Popconfirm, Checkbox, Tag } from "antd";
import history from "../../../routes/history";
import { FormItemInput } from "../../ant-wrapper/form-item";
import { SelectOutlined } from "@ant-design/icons";

const prioridadeStyle = {
  ELETIVO: "#73d13d",
  PRIORITARIO: "#fadb14",
  URGENCIA: "#cf1322",
};

const { Column } = Table;
export default function PacientesListBox({
  disabled = false,
  pacientes,
  onDelete,
  onChangeSelection,
  onApproval,
  showURL,
  viewMode,
}) {
  function cancel(e) {
    console.log(e);
  }
  const [posicaoParaAprovar, setPosicaoParaAprovar] = useState();

  useEffect(() => {
    const ultimoNaoAprovado = pacientes.find(
      (paciente) => !paciente?.pivot?.aprovado
    );
    setPosicaoParaAprovar(ultimoNaoAprovado?.id);
  }, [pacientes]);
  return (
    <div>
      <Table size="small" pagination={false} dataSource={pacientes} rowKey="id">
        <Column
          title="Posição"
          dataIndex="id"
          key="id"
          render={(value, record, index) => <div>{index + 1}</div>}
        />
        <Column title="Nome" dataIndex={["pessoa_fisica", "nome"]} key="nome" />
        <Column
          title="Prioridade"
          dataIndex="prioridade"
          key="prioridade"
          render={(text, record) => (
            <Tag
              color={prioridadeStyle[record?.pivot?.prioridade]}
              key={record?.pivot?.prioridade}
            >
              {record?.pivot?.prioridade}
            </Tag>
          )}
        />
        <Column
          title="Ação"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              {record?.pivot?.aprovado ? (
                <div>
                  <Tag color="green" key="aprovado">
                    Aprovado
                  </Tag>
                  <Tag color="yellow" key="aprovado">
                    <a
                      onClick={() =>
                        history.push(
                          `/solicitacoes/${record.pivot.solicitacao_id}`
                        )
                      }
                    >
                      <SelectOutlined /> Solicitação
                    </a>
                  </Tag>
                </div>
              ) : (
                <div>
                  {viewMode && posicaoParaAprovar === record.id ? (
                    <Popconfirm
                      title="Deseja aprovar a solicitação?"
                      onConfirm={() => onApproval(record)}
                      onCancel={cancel}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <a>Aprovar</a>
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      title="Deseja remover o paciente escolhido?"
                      onConfirm={() => onDelete(record)}
                      onCancel={cancel}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <a>Remover</a>
                    </Popconfirm>
                  )}
                </div>
              )}
            </Space>
          )}
        />
      </Table>
    </div>
  );
}
