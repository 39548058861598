import React, { useEffect, useState } from "react";

import { UploadOutlined, AntDesignOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Upload,
  Select,
} from "antd";
import { useSelector } from "react-redux";

import EnderecoForm from "../../address/forms";
import PessoaJuridicaForm from "../../pessoa_juridica/forms";
import SkeletonContainer from "../../skeleton-container";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
const cardStyle = { borderColor: "white" };

const types = [
  {
    nome: "Prefeitura",
    value: "Prefeitura",
  },
  {
    nome: "Camara",
    value: "Camara",
  },
  {
    nome: "Descentralizado",
    value: "Descentralizado",
  },
];
export default function EntityForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
}) {
  const [form] = Form.useForm();
  const [fileSrc, setFileSrc] = useState();
  const { cnaes, naturezas } = useSelector((state) => state.cnaeNatureza);

  const onEdit = () => {
    setError();
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
    });
  }
  const onChange = async ({ file }) => {
    const data = await getBase64(file.originFileObj);
    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      imagem: data,
    });
    setFileSrc(data);
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    setFileSrc(values.imagem);
  }, []);

  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <SkeletonContainer>
          <Card title="Dados básicos" style={{ ...cardStyle }}>
            <Row gutter={[16, 16]}>
              <Col span={16}>
                <Form.Item
                  name="tipo"
                  label="Tipo"
                  rules={[
                    {
                      required: true,
                      message: "Tipo é obrigatório!",
                    },
                  ]}
                >
                  <Select disabled={viewMode}>
                    {types.map((type) => (
                      <Option key={type.nome} value={type.nome}>
                        {type.nome}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Row gutter={[16, 8]}>
                  <Col span={16}>
                    <Form.Item name="slogan" label="Slogan">
                      <Input disabled={viewMode} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nome_reduzido" label="Nome reduzido">
                      <Input max={4} maxLength={4} disabled={viewMode} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Form.Item name="imagem" label="Imagem">
                  <Avatar
                    src={fileSrc}
                    size={{
                      xs: 24,
                      sm: 32,
                      md: 40,
                      lg: 64,
                      xl: 80,
                      xxl: 100,
                    }}
                    icon={<AntDesignOutlined />}
                  />
                  <br />
                  <br />
                  <Upload onChange={onChange} maxCount={1} name="imagem">
                    <Button disabled={viewMode} icon={<UploadOutlined />}>
                      Clique para alterar
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            <PessoaJuridicaForm viewMode={viewMode} form={form} />

            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  name="cnae"
                  label="Cnae"
                  rules={[
                    {
                      required: true,
                      message: "Cnae é obrigatório!",
                    },
                  ]}
                >
                  <Select disabled={viewMode} showSearch>
                    {cnaes.map((cnae) => (
                      <Option value={JSON.stringify(cnae)}>
                        {cnae.codigo} -{cnae.descricao}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name="fax" label="Fax">
                  <Input disabled={viewMode} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="natureza"
                  label="Natureza"
                  rules={[
                    {
                      required: true,
                      message: "Natureza é obrigatório!",
                    },
                  ]}
                >
                  <Select disabled={viewMode} showSearch>
                    {naturezas.map((natureza) => (
                      <Option value={JSON.stringify(natureza)}>
                        {natureza.codigo} -{natureza.descricao}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </SkeletonContainer>
        <Card title="Endereço" style={{ ...cardStyle }}>
          <EnderecoForm
            viewMode={viewMode}
            dataIndex="pessoa_juridica"
            form={form}
          />
        </Card>
        {!viewMode && (
          <Row>
            <Col style={{ marginLeft: 25 }} span={16}>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  Enviar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}
