import React, { useContext, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Table, Space, Popconfirm, Button, Input,
} from 'antd';
import moment from 'moment';

import { VerticalContainer } from '../../../components/styled';
import { UserContext } from '../../../context';
import history from '../../../routes/history';
import { deleteConfig, getConfigs } from '../../../services/configuracaoarquivos';
import AddConfig from '../add';
import ButtonTSX from '..';

const { Search } = Input;

const { Column } = Table;
export default function ListConfigs() {
  const [visibleAddConfig, setVisibleAddConfig] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [configs, setConfigs] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const user = useContext(UserContext);
  const getConfigsPaginated = async () => {
    try {
      setLoading(true);
      const configsData = await getConfigs(page, filter);
      setConfigs(configsData.data);
      setTotal(configsData.total);
    } finally {
      setLoading(false);
    }
  };
  const onConfirm = async () => {
    await getConfigsPaginated();
  };

  useEffect(() => {
    getConfigsPaginated();
  }, [page, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  async function confirm(record) {
    await deleteConfig(record.id);
    await getConfigsPaginated();
  }

  function cancel(e) {
    console.log(e);
  }
  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter('');
  };

  return (
    <>
      <VerticalContainer>
        <Search
          onChange={onChange}
          style={{ width: '50%' }}
          placeholder="Pesquisar Configuração de Arquivo"
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            setVisibleAddConfig(true);
          }}
          style={{ width: '100%!important' }}
          block
        >
          <PlusOutlined />
          Adicionar Configuração de Arquivo
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{
            width: '100%',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}
          dataSource={configs}
          rowKey="id"
          loading={loading}
        >
          <Column width="10%" title="Código" dataIndex="id" key="id" />
          <Column width="15%" title="Extensão" dataIndex="extensao" key="extensao" />
          <Column width="15%" title="Tamanho Máximo" dataIndex="tamanho_maximo" key="tamanho_maximo" />

          <Column
            width="15%"
            title="Data de criação"
            dataIndex="created_at"
            render={(data) => moment(data).format('DD/MM/YYYY')}
            key="created_at"
          />
          <Column
            width="20%"
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => history.push(`configuracao-arquivos/${record.id}`)}>Visualizar</a>
                {user?.type === 'ADM'
                && (
                <Popconfirm
                  title="Deseja deletar a configuração de arquivo?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
        {visibleAddConfig ? (
          <AddConfig
            visible={visibleAddConfig}
            setVisible={setVisibleAddConfig}
            onConfirm={onConfirm}
          />
        ) : (
          ''
        )}
      </VerticalContainer>
    </>
  );
}