import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ClinicaForm from '../../../components/clinicas/forms';
import { VerticalContainer } from '../../../components/styled';
import { Creators as ClinicaActions } from '../../../store/ducks/clinica';

export default function AddClinica() {
  const [error, setError] = useState();
  const { loading, error: erro } = useSelector((state) => state.clinica);
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    dispatch(ClinicaActions.registerClinicaRequest(values));
  };
  useEffect(() => {
    setError(erro);
  }, [erro]);

  return (
    <VerticalContainer>
      <div />
      <ClinicaForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </VerticalContainer>
  );
}
