import React, { useContext } from "react";

import {
  AuditOutlined,
  ContainerOutlined,
  HomeOutlined,
  MedicineBoxOutlined,
  PieChartOutlined,
  ReconciliationOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  ProfileOutlined,
  SnippetsOutlined,
  ScheduleOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { useHistory } from "react-router-dom";

import { UserContext } from "../../context";

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Sidebar({ collapsed }) {
  const history = useHistory();
  const user = useContext(UserContext);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo" />
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
      {(user?.type !== 'CLINICA') ?
        <>
          <Menu.Item
            onClick={() => history.push("/dashboard")}
            key="20"
            icon={<AuditOutlined />}
          >
            Dashboard
          </Menu.Item>
          <Menu.Item
            onClick={() => history.push("/fila-espera")}
            key="144"
            icon={<ProfileOutlined />}
          >
            Lista de espera
          </Menu.Item>
        </> : ""}
        <Menu.Item
          onClick={() => history.push("/solicitacoes")}
          key="1"
          icon={<AuditOutlined />}
        >
          Solicitações
        </Menu.Item>
        {(user?.type !== 'CLINICA') ?
          <>
            <Menu.Item
              onClick={() => history.push("/atendimentos")}
              key="2"
              icon={<MedicineBoxOutlined />}
            >
              Atendimentos
            </Menu.Item>
            <Menu.Item
              key="3"
              onClick={() => history.push("/pacientes")}
              icon={<UserOutlined />}
            >
              Pacientes
            </Menu.Item>
            <Menu.Item
              key="4"
              onClick={() => history.push("/profissionais")}
              icon={<TeamOutlined />}
            >
              Profissionais
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push("/clinicas")}
              key="5"
              icon={<MedicineBoxOutlined />}
            >
              Clínicas
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push("/unidades")}
              key="6"
              icon={<ReconciliationOutlined />}
            >
              Unidade de Saúde
            </Menu.Item>
            <Menu.Item
              onClick={() => history.push("/distritos")}
              key="13"
              icon={<HomeOutlined />}
            >
              Distritos
            </Menu.Item>

            <Menu.Item
              onClick={() => history.push("/gestantes")}
              key="1435"
              icon={<WomanOutlined />}
            >
              Gestantes
            </Menu.Item>
            {user.type === "ADM" && (
              <>
                <Menu.Item
                  icon={<ContainerOutlined />}
                  onClick={() => history.push("/quotas")}
                  key="7"
                >
                  Quotas
                </Menu.Item>
                <Menu.Item
                  icon={<ScheduleOutlined />}
                  onClick={() => history.push("/procedimentos")}
                  key="14"
                >
                  Procedimentos
                </Menu.Item>
                <SubMenu
                  key="sub1"
                  icon={<SettingOutlined />}
                  title="Configurações"
                >
                  <>
                    <Menu.Item onClick={() => history.push("/entidade")} key="9">
                      Entidade
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push("/users")} key="8">
                      Usuários
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push("/layout")} key="21">
                      Estilização
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push("/tipos")} key="22">
                      Tipo de Documento
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push("/configuracao-arquivos")} key="23">
                      Configuração de Arquivos
                    </Menu.Item>
                    <Menu.Item onClick={()=> history.push("/configuracoes")} key="24">
                      Chaves Avançadas
                    </Menu.Item>
                  </>
                </SubMenu>
              </>
            )}
            <SubMenu key="sub2" icon={<PieChartOutlined />} title="Relatórios">
              <Menu.Item
                key="11"
                onClick={() => history.push("/relatorios/solicitacoes")}
              >
                Solicitações
              </Menu.Item>
              <Menu.Item
                key="112"
                onClick={() => history.push("/relatorios/procedimentos")}
              >
                Procedimentos
              </Menu.Item>
              <Menu.Item
                key="12"
                onClick={() => history.push("/relatorios/atendimentos")}
              >
                Atendimentos
              </Menu.Item>
              <Menu.Item
                key="16"
                onClick={() => history.push("/relatorios/distritos")}
              >
                Distritos
              </Menu.Item>
              <Menu.Item
                key="17"
                onClick={() => history.push("/relatorios/pacientes")}
              >
                Pacientes
              </Menu.Item>
              <Menu.Item
                key="18"
                onClick={() => history.push("/relatorios/clinicas")}
              >
                Clinicas
              </Menu.Item>
              <Menu.Item
                key="19"
                onClick={() => history.push("/relatorios/unidades")}
              >
                Unidades de Saúde
              </Menu.Item>
              <Menu.Item
                key="20"
                onClick={() => history.push("/relatorios/profissionais")}
              >
                Profissionais
              </Menu.Item>
              <Menu.Item
                key="21"
                onClick={()=>history.push("relatorios/quotas")}
              >
                Quotas
              </Menu.Item>
            </SubMenu>
        </> : ""}
      </Menu>
    </Sider>
  );
}
