import React from 'react';

import moment from 'moment';

const getUpdatedAt = (item) => {
  if (item.pessoa_fisica) {
    return moment(item.pessoa_fisica.updated_at).isAfter(item.updated_at)
      ? moment(item.pessoa_fisica.updated_at).format('DD/MM/YYYY [às] HH:mm') : moment(item.updated_at).format('DD/MM/YYYY [às] HH:mm');
  }
  if (item.pessoa_juridica) {
    return moment(item.pessoa_juridica.updated_at).isAfter(item.updated_at)
      ? moment(item.pessoa_juridica.updated_at).format('DD/MM/YYYY [às] HH:mm') : moment(item.updated_at).format('DD/MM/YYYY [às] HH:mm');
  }
  return moment(item.updated_at).format('DD/MM/YYYY [às] HH:mm');
};

function ViewContainer({ item, children, marginLeft = '25px' }) {
  return (
    <div>
      {children}
      <div style={{
        fontSize: '9pt', color: 'gray', marginLeft,
      }}
      >
        {item?.created_by && (
        <>
          Registrado por
          {' '}
          <b>{item?.created_by?.name?.toUpperCase()}</b>
          {' '}
          em
          {' '}
          {moment(item.created_at).format('DD/MM/YYYY [às] HH:mm')}
          .
          {' '}
        </>
        ) }

        {item?.updated_by && (
        <>
          Editado por
          {' '}
          <b>{item?.updated_by?.name?.toUpperCase()}</b>
          {' '}
          em
          {' '}
          {getUpdatedAt(item)}
        </>
        )}
      </div>
    </div>
  );
}

export default ViewContainer;
