import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';

import {
  registerUnidade, updateUnidade, getUnidades, deleteUnidade,
} from '../../services/unidades';
import { Creators as UnidadeActions, Types } from '../ducks/unidade';

function* register(action) {
  try {
    yield call(registerUnidade, action.unidade);
    yield put(UnidadeActions.registerUnidadeSuccess());
    yield put(UnidadeActions.getAllUnidadeRequest());
  } catch (error) {
    yield put(UnidadeActions.requestUnidadeFailure(error?.error));
  }
}

function* update(action) {
  try {
    yield call(updateUnidade, action.unidade, action.values);
    yield put(UnidadeActions.updateUnidadeSuccess());
    yield put(UnidadeActions.getAllUnidadeRequest());
  } catch (error) {
    yield put(UnidadeActions.requestUnidadeFailure(error?.error));
  }
}

function* getAll() {
  try {
    const unidades = yield call(getUnidades, '', '', false);
    yield put(UnidadeActions.getAllUnidadeSuccess(unidades));
  } catch (error) {
    yield put(UnidadeActions.requestUnidadeFailure(error?.error));
  }
}

function* deleteUnidadeSaga(action) {
  try {
    yield call(deleteUnidade, action.id);
    yield put(UnidadeActions.deleteUnidadeSuccess());
    yield put(UnidadeActions.getAllUnidadeRequest());
  } catch (error) {
    yield put(UnidadeActions.requestUnidadeFailure(error?.error));
  }
}

export function* rootUnidadeSaga() {
  yield all([takeLatest(Types.REGISTER_UNIDADE_REQUEST, register)]);
  yield all([takeLatest(Types.GET_ALL_UNIDADE_REQUEST, getAll)]);
  yield all([takeLatest(Types.UPDATE_UNIDADE_REQUEST, update)]);
  yield all([takeLatest(Types.DELETE_UNIDADE_REQUEST, deleteUnidadeSaga)]);
}
