import React, { useState } from 'react';

import { Modal } from 'antd';

import QuotaForm from '../../../components/quotas/forms';
import ViewContainer from '../../../components/view_container';
import { updateQuota } from '../../../services/quotas';

export default function EditQuota({
  visible, setVisible, quota, onConfirm,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateQuota(quota, values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Editar quota"
      centered
      footer={null}
      visible={visible}
      onCancel={() => {
        quota.procedimento = JSON.parse(quota.procedimento);
        setVisible(false);
      }}
    >
      {quota && (
        <ViewContainer marginLeft="0" item={quota}>
          <QuotaForm
            initialValues={{ ...quota }}
            onFinish={onFinish}
            error={error}
            setError={setError}
            loading={loading}
            isEdit
          />
        </ViewContainer>
      )}
    </Modal>
  );
}
