import React, { useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";

import FilaEsperaTable from "../../../components/fila_espera/table";
import { VerticalContainer } from "../../../components/styled";
import history from "../../../routes/history";
import {
  deleteFilaEspera,
  getFilasEspera,
} from "../../../services/fila_espera";

const { Search } = Input;

export default function ListFilaEspera() {
  const [filter, setFilter] = useState("");
  const [filasEspera, setFilasEspera] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const getFilaEsperaPaginated = async () => {
    setLoading(true);
    try {
      const filasEsperaData = await getFilasEspera(page, filter);
      setFilasEspera(filasEsperaData.data);
      setTotal(filasEsperaData.total);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFilaEsperaPaginated();
  }, [page, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deleteFilaEspera(record.id);
    await getFilaEsperaPaginated();
  }

  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter("");
  };

  return (
    <>
      <VerticalContainer>
        <Search
          onChange={onChange}
          style={{ width: "50%" }}
          placeholder="Pesquisar na fila de espera por paciente..."
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            history.push("/adicionar-fila-espera");
          }}
          style={{ width: "100%!important" }}
          block
        >
          <PlusOutlined />
          Adicionar novo
        </Button>
        <br />
        <FilaEsperaTable
          loading={loading}
          handleTableChange={handleTableChange}
          filasEspera={filasEspera}
          total={total}
          confirm={confirm}
        />
      </VerticalContainer>
    </>
  );
}
