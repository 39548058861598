import { notification } from "antd";

import history from "../../routes/history";
import api from "../api";
import ApiException from "../exception/ApiException";

const onGetGestantes = (values) => {
  values.paciente = JSON.stringify(values.paciente);
};

const registerGestante = async (values) => {
  try {
    await api.post("gestantes", values);
    history.push("/gestantes");
    notification.open({
      message: "Gestante adicionado com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const getGestante = async (id) => {
  try {
    const { data } = await api.get(`gestantes/${id}`);
    onGetGestantes(data);
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteGestante = async (id) => {
  try {
    await api.delete(`gestantes/${id}`);
    notification.open({
      message: "Gestante deletado com sucesso",
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getGestantes = async (page, filter = "", paginated = "") => {
  const { data: gestantes } = await api.get(
    `gestantes?page=${page}&filter=${filter}&paginated=${paginated}`
  );
  return gestantes;
};

const updateGestante = async (gestante, values) => {
  try {
    values.id = gestante.id;
    await api.put("gestantes", values);
    history.push("/gestantes");
    notification.open({
      message: "Gestante atualizado com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerGestante,
  deleteGestante,
  getGestantes,
  getGestante,
  updateGestante,
};
