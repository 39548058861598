import React, { useState } from 'react';

import {
  Alert, Button, Form, Input, notification, PageHeader,
} from 'antd';

import {
  BackgroundStyle,
  Center,
  SecondaryText,
} from '../../../components/styled';
import history from '../../../routes/history';
import api from '../../../services/api';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function ForgotPassword() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [edit, setEdit] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      setEdit(true);
      await api.post('users/reset-password', values);
      notification.open({
        message: 'Verifique seu email.',
        description:
          'Um email com as instruções de mudança de senha foi enviado para você',
      });
      history.push('/');
    } catch (err) {
      console.log(err.response.data.err);
      setError(err.response.data.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Center>
      <BackgroundStyle />
      <PageHeader
        className="site-page-header"
        onBack={() => history.push('/')}
        title="Recuperar senha"
      />
      {edit && error && <Alert message={error} type="error" />}
      <br />
      <Form
        form={form}
        onChange={() => {
          setEdit(false);
          setError();
        }}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Form.Item
          {...formItemLayout}
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'Email inválido!',
            },
            {
              required: true,
              message: 'Email é obrigatório!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
      <SecondaryText>
        *Você receberá um email com as instruções de mudança de senha.
      </SecondaryText>
    </Center>
  );
}
