import React, { useState } from 'react';

import { Modal } from 'antd';

import AgenteSaudeForm from '../../../../components/districts/agentes';
import ViewContainer from '../../../../components/view_container';
import { updateAgenteSaude } from '../../../../services/districts/agentes';

export default function EditAgenteSaude({
  visible,
  setVisible,
  agente,
  onConfirm,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateAgenteSaude(agente, values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Editar agente de saúde"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      {agente && (
      <ViewContainer item={agente} marginLeft="0">
        <AgenteSaudeForm
          initialValues={{ ...agente }}
          onFinish={onFinish}
          error={error}
          setError={setError}
          loading={loading}
          isEdit
        />
      </ViewContainer>
      )}
    </Modal>
  );
}
