import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Table, Space, Popconfirm, Button,
} from 'antd';
import moment from 'moment';

import { VerticalContainer } from '../../../../components/styled';
import { deleteAgenteSaude, getAgentesSaudes } from '../../../../services/districts/agentes';
import AddAgenteSaude from '../add';
import EditAgenteSaude from '../edit';

const { Column } = Table;
export default function ListAgenteSaudes({ distrito }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    visibleAddAgenteSaude,
    setVisibleAddAgenteSaude,
  ] = useState(false);
  const [agente, setAgenteSaude] = useState({});
  const [agentes, setAgenteSaudes] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const getAgentesSaudePaginated = async () => {
    try {
      setLoading(true);
      const agentesSaudeData = await getAgentesSaudes(page, distrito, true);
      setAgenteSaudes(agentesSaudeData.data);
      setTotal(agentesSaudeData.total);
    } finally {
      setLoading(false);
    }
  };
  const onConfirm = async () => {
    await getAgentesSaudePaginated();
  };

  useEffect(() => {
    getAgentesSaudePaginated();
  }, [page]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deleteAgenteSaude(record.id);
    await getAgentesSaudePaginated();
  }
  const showDrawer = (record) => {
    setAgenteSaude(record);
    setVisible(true);
  };

  function cancel(e) {
    console.log(e);
  }
  return (
    <>
      <VerticalContainer>
        <Button
          type="dashed"
          onClick={() => {
            setVisibleAddAgenteSaude(true);
          }}
          style={{ width: '100%!important' }}
          block
        >
          <PlusOutlined />
          {' '}
          Adicionar agente de saúde
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{ width: '100%' }}
          dataSource={agentes}
          rowKey="id"
          loading={loading}
        >
          <Column
            title="Codigo"
            dataIndex="id"
            key="id"
          />
          <Column
            title="Nome"
            dataIndex="nome"
            key="nome"
          />
          <Column
            title="Data de criação"
            dataIndex="created_at"
            render={(data) => moment(data).format('DD/MM/YYYY')}
            key="created_at"
          />
          <Column
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => showDrawer(record)}>Editar</a>
                <Popconfirm
                  title="Deseja deletar o agente de saúde?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
        {visible ? (
          <EditAgenteSaude
            visible={visible}
            setVisible={setVisible}
            agente={agente}
            onConfirm={onConfirm}
          />
        ) : (
          ''
        )}
        {visibleAddAgenteSaude ? (
          <AddAgenteSaude
            distrito={distrito}
            visible={visibleAddAgenteSaude}
            setVisible={setVisibleAddAgenteSaude}
            onConfirm={onConfirm}
          />
        ) : (
          ''
        )}
      </VerticalContainer>
    </>
  );
}
