import React, { useEffect, useState } from 'react';

import { MedicineBoxOutlined, TeamOutlined } from '@ant-design/icons';
import { Spin, Tabs } from 'antd';

import EditButton from '../../../components/buttons/edit';
import DistrictForm from '../../../components/districts/form';
import {
  InlineContainer,
  VerticalContainer,
} from '../../../components/styled';
import ViewContainer from '../../../components/view_container';
import { getDistrict, updateDistrict } from '../../../services/districts';
import ListAgenteSaudes from '../agentes/list';
import { useContext } from 'react';
import { UserContext } from '../../../context';

const { TabPane } = Tabs;

export default function ViewDistrito({ computedMatch }) {
  const [error, setError] = useState();
  const [distrito, setDistrito] = useState();
  const [viewMode, setViewMode] = useState(true);
  const { params } = computedMatch;
  const user = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateDistrict(distrito, values);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  const handleDistrito = async () => {
    const data = await getDistrict(params.id);
    setDistrito(data);
  };
  useEffect(() => {
    handleDistrito();
  }, []);

  return (
    <Tabs defaultActiveKey="1" style={{ width: '100%' }}>
      <TabPane
        tab={(
          <span>
            <MedicineBoxOutlined />
            Detalhes
          </span>
        )}
        key="1"
      >
        <VerticalContainer>
          <InlineContainer>
            <div />
            {distrito?.permissao_editar ? <EditButton viewMode={viewMode} setViewMode={setViewMode} /> : ""}
          </InlineContainer>
          {distrito ? (
            <ViewContainer item={distrito} marginLeft="0">
              <DistrictForm
                initialValues={{ ...distrito }}
                onFinish={onFinish}
                error={error}
                setError={setError}
                loading={loading}
                viewMode={viewMode}
              />
            </ViewContainer>
          ) : (
            <Spin />
          )}
        </VerticalContainer>
      </TabPane>
      <TabPane
        tab={(
          <span>
            <TeamOutlined />
            Agentes de Saúde
          </span>
        )}
        key="2"
      >
        <ListAgenteSaudes distrito={distrito} />
      </TabPane>
    </Tabs>
  );
}
