import React from 'react';

import { Col, Row } from 'antd';

import { cnpjMask } from '../../../utils';
import { FormItemInput } from '../../ant-wrapper/form-item';

export default function PessoaJuridicaForm({ form, viewMode = false }) {
  const handleCnpj = (value) => {
    value = value.target.value;
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      pessoa_juridica: {
        ...fields.pessoa_juridica,
        cnpj: cnpjMask(value),
      },
    });
  };
  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <FormItemInput
            name={['pessoa_juridica', 'nome']}
            label="Nome"
            viewMode={viewMode}
            required
          />
        </Col>
        <Col span={8}>
          <FormItemInput
            name={['pessoa_juridica', 'razao_social']}
            label="Razão Social"
            viewMode={viewMode}
            required
          />
        </Col>
        <Col span={8}>
          <FormItemInput
            name={['pessoa_juridica', 'email']}
            label="E-mail"
            viewMode={viewMode}
            required
            email
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={8}>
          <FormItemInput
            name={['pessoa_juridica', 'telefone']}
            label="Telefone"
            viewMode={viewMode}
            required
          />
        </Col>

        <Col span={8}>
          <FormItemInput
            name={['pessoa_juridica', 'cnpj']}
            label="CNPJ"
            onChangeInput={handleCnpj}
            required
            viewMode={viewMode}
          />
        </Col>

        <Col span={8}>
          <FormItemInput
            label="Inscrição Estadual"
            name={['pessoa_juridica', 'inscricao_estadual']}
            viewMode={viewMode}
          />
        </Col>
      </Row>
    </>
  );
}
