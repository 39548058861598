import React, { useState } from 'react';

import { Modal } from 'antd';

import ConfigurationForm from '../../../components/configuracaoarquivos/form';
import { registerConfig } from '../../../services/configuracaoarquivos';

export default function AddConfig({ visible, setVisible, onConfirm }) {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const onFinish = async (values) => {
      try {
        if(!values.tamanho_maximo){
          setError('Tamanho Máximo é obrigatório!');
          return;
        }
        setLoading(true);
        await registerConfig(values);
        setVisible(false);
        onConfirm();
      } catch (err) {
        console.log(err.error);
        setError(err.error);
      } finally {
        setLoading(false);
      }
    };
    return (
      <Modal
        title="Adicionar Configuração de Arquivo"
        centered
        footer={null}
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <ConfigurationForm
          onFinish={onFinish}
          error={error}
          setError={setError}
          loading={loading}
        />
      </Modal>
    );
  }