import React from 'react';

import { CloseOutlined, SmileOutlined } from '@ant-design/icons';
import { notification } from 'antd';
/**
 * implement its logic to manipulate the user's token, by
 * default it is stored in the localstorage of the browser,
 * so the implementations implemented by default are web-oriented.
 * */

// Delete the token
export function unsetToken() {
  // Implement your logic to delete the token here
  window.localStorage.removeItem('token-marcacao');
}

// Sets the token
export function setToken(token) {
  // Implement your logic to set the token here
  window.localStorage.setItem('token-marcacao', token);
}

// Gets the token
export function getToken() {
  // Implement your logic to gets the token here
  return window.localStorage.getItem('token-marcacao');
}

export const cpfMask = (value) => value
  .replace(/\D/g, '')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2');

export const cnpjMask = (value) => value
  .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
  .replace(/(-\d{2})\d+?$/, '$1');

export const cpfCnpjMask = (value) => {
  value = value.replace(/\D/g, '');
  if (value.length <= 13) {
    value = cpfMask(value);
  } else {
    value = cnpjMask(value);
  }
  return value;
};

export const cepMask = (value) => value
  .replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1.$2')
  .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  .replace(/(-\d{3})\d+?$/, '$1');

export const toDigitsOnly = (value) => value.replace(/\D/g, '');

export const successNotification = (msg) => {
  notification.open({
    message: msg,
    icon: <SmileOutlined style={{ color: '#108ee9' }} />,
  });
};

export const failureNotification = (msg) => {
  notification.open({
    message: msg,
    icon: <CloseOutlined style={{ color: '#108ee9' }} />,
  });
};

export const isEmpty = (obj) => Object.keys(obj).length === 0;
