import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  registerPacienteRequest: ['paciente'],
  registerPacienteSuccess: null,

  updatePacienteRequest: ['paciente', 'values'],
  updatePacienteSuccess: null,

  getAllPacienteRequest: null,
  getAllPacienteSuccess: ['pacientes'],

  deletePacienteRequest: ['id'],
  deletePacienteSuccess: null,

  requestPacienteFailure: ['error'],
});

const INITIAL_STATE = { pacientes: [], loading: false, error: '' };

/**
 * REGISTER REQUESTS BELLOW
 */

const registerPacienteRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const registerPacienteSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * UPDATE REQUESTS BELLOW
 */

const deletePacienteRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const deletePacienteSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});
/**
 * UPDATE REQUESTS BELLOW
 */

const updatePacienteRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const updatePacienteSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * GET SERVICES
 */

const getAllPacienteRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
  pacientes: [],
});

const getAllPacienteSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: '',
  loading: false,
  pacientes: action.pacientes,
});

/**
 * Request falure
 */
const requestPacienteFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_PACIENTE_REQUEST]: registerPacienteRequest,
  [Types.REGISTER_PACIENTE_SUCCESS]: registerPacienteSuccess,

  [Types.REQUEST_PACIENTE_FAILURE]: requestPacienteFailure,

  [Types.UPDATE_PACIENTE_REQUEST]: updatePacienteRequest,
  [Types.UPDATE_PACIENTE_SUCCESS]: updatePacienteSuccess,

  [Types.DELETE_PACIENTE_REQUEST]: deletePacienteRequest,
  [Types.DELETE_PACIENTE_SUCCESS]: deletePacienteSuccess,

  [Types.GET_ALL_PACIENTE_REQUEST]: getAllPacienteRequest,
  [Types.GET_ALL_PACIENTE_SUCCESS]: getAllPacienteSuccess,
});
