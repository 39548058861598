import React, { useContext, useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Table, Space, Popconfirm, Button, Input,
} from 'antd';
import moment from 'moment';

import { VerticalContainer } from '../../../components/styled';
import { UserContext } from '../../../context';
import history from '../../../routes/history';
import { deleteType, getTypes } from '../../../services/tipodocumentos';
import AddType from '../add';

const { Search } = Input;

const { Column } = Table;
export default function ListTypes() {
  const [visibleAddType, setVisibleAddType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [types, setTypes] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const user = useContext(UserContext);
  const getTypesPaginated = async () => {
    try {
      setLoading(true);
      const typesData = await getTypes(page, filter);
      setTypes(typesData.data);
      setTotal(typesData.total);
    } finally {
      setLoading(false);
    }
  };
  const onConfirm = async () => {
    await getTypesPaginated();
  };

  useEffect(() => {
    getTypesPaginated();
  }, [page, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  async function confirm(record) {
    await deleteType(record.id);
    await getTypesPaginated();
  }

  function cancel(e) {
    console.log(e);
  }
  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter('');
  };

  return (
    <>
      <VerticalContainer>
        <Search
          onChange={onChange}
          style={{ width: '50%' }}
          placeholder="Pesquisar tipo de documento"
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            setVisibleAddType(true);
          }}
          style={{ width: '100%!important' }}
          block
        >
          <PlusOutlined />
          Adicionar Tipo de Documento
        </Button>
        <br />
        <Table
          size="middle"
          pagination={{ pageSize: 10, total }}
          onChange={handleTableChange}
          style={{
            width: '100%',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
          }}
          dataSource={types}
          rowKey="id"
          loading={loading}
        >
          <Column width="10%" title="Código" dataIndex="id" key="id" />
          <Column width="15%" title="Tipo" dataIndex="tipo" key="tipo" />
          <Column width="15%" title="Descrição" dataIndex="descricao" key="descricao" />

          <Column
            width="15%"
            title="Data de criação"
            dataIndex="created_at"
            render={(data) => moment(data).format('DD/MM/YYYY')}
            key="created_at"
          />
          <Column
            width="20%"
            title="Ação"
            key="action"
            render={(text, record) => (
              <Space size="middle">
                <a onClick={() => history.push(`tipos/${record.id}`)}>Visualizar</a>
                {user?.type === 'ADM'
                && (
                <Popconfirm
                  title="Deseja deletar o tipo de documento?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
                )}
              </Space>
            )}
          />
        </Table>
        {visibleAddType ? (
          <AddType
            visible={visibleAddType}
            setVisible={setVisibleAddType}
            onConfirm={onConfirm}
          />
        ) : (
          ''
        )}
      </VerticalContainer>
    </>
  );
}