import { notification } from "antd";
import history from "../../routes/history";
import api from "../api";
import ApiException from "../exception/ApiException";
import { DatabaseFilled } from "@ant-design/icons";

const registerChave = async (data) => {
  try {
    if (data.tipo == 3 && data.booleano == undefined) {
      data.booleano = false;
    }
    await api.post("/configuracoes", data);
    history.push("/configuracoes");
    notification.open({
      message: "Configuração criada com sucesso",
    });
  } catch (err) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteChave = async (id) => {
  try {
    await api.delete(`/configuracoes/${id}`);
    notification.open({
      message: "Configuração excluida com sucesso",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const getChavesPagineted = async (page, filter = "", paginated = "") => {
  const { data: configuracoes } = await api.get(
    `configuracoes?page=${page}&filter=${filter}&paginated=${paginated}`
  );
  return configuracoes;
};

const getChave = async (id) => {
  try {
    const { data } = await api.get(`/configuracoes/id/${id}`);
    if (data == null || data == undefined) {
      return defaultValue;
    } else {
      return data;
    }
  } catch (error) {
    throw new ApiException(error?.response?.data);
  }
};

const getChaveByNome = async (nome, defaultValue, tipo) => {
  try {
    const { data } = await api.get(`/configuracoes/${nome}`);
    if(data != null || data!= undefined){
        switch(tipo){
            case 0:
                return data.string;
            case 1:
                return data.inteiro
            case 2:
                return data.decimal
            case 3:
                return data.booleano == 1 ? true : false; 
        }
    }else{
        return defaultValue;
    }
  } catch (error) {
    throw new ApiException(error?.response?.data);
  }
};

const updateChave = async (values, record) => {
  try {
    values.id = record.id;
    await api.put("/configuracoes", normalizeUpdate(values));
    history.push("/configuracoes");
    notification.open({
      message: "Chave atualizada com sucesso",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const normalizeUpdate = (values) => {
  let chave = values;
  switch (values.tipo) {
    case 0:
      chave.inteiro = null;
      chave.decimal = null;
      chave.booleano = null;
      break;
    case 1:
      chave.string = null;
      chave.decimal = null;
      chave.booleano = null;
      break;
    case 2:
      chave.string = null;
      chave.inteiro = null;
      chave.booleano = null;
      break;
    case 3:
      chave.string = null;
      chave.inteiro = null;
      chave.decimal = null;
      break;
  }
  return chave;
};

export {
  getChave,
  getChavesPagineted,
  registerChave,
  updateChave,
  deleteChave,
  getChaveByNome
};
