import React, { useState } from 'react';

import { Modal } from 'antd';

import QuotaForm from '../../../components/quotas/forms';
import { registerQuota } from '../../../services/quotas';

export default function AddQuota({ visible, setVisible, onConfirm }) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await registerQuota(values);
      setVisible(false);
      onConfirm();
    } catch (err) {
      console.log(err);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Adicionar quota"
      centered
      footer={null}
      visible={visible}
      onCancel={() => setVisible(false)}
    >
      <QuotaForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </Modal>
  );
}
