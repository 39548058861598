import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  getAllCnaeNatureza: null,
  getAllCnaeNaturezaSuccess: ['cnaes', 'naturezas'],
  getAllCnaeNaturezaFailure: ['error'],
});

const INITIAL_STATE = {
  cnaes: [], naturezas: [], loading: false, error: '',
};

/**
 * REGISTER REQUESTS BELLOW
 */

const getAllCnaeNatureza = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const getAllCnaeNaturezaSucess = (state = INITIAL_STATE, action) => ({
  ...state,
  cnaes: action.cnaes,
  naturezas: action.naturezas,
  loading: false,
});

/**
 * Request falure
 */
const getAllCnaeNaturezaFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  cnaes: [],
  naturezas: [],
  error: action.error,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_ALL_CNAE_NATUREZA]: getAllCnaeNatureza,
  [Types.GET_ALL_CNAE_NATUREZA_SUCCESS]: getAllCnaeNaturezaSucess,

  [Types.GET_ALL_CNAE_NATUREZA_FAILURE]: getAllCnaeNaturezaFailure,
});
