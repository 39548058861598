import locationApi from '../locationApi';

const fetchAllStates = async () => {
  const { data } = await locationApi.get('estados');
  data.sort((a, b) => {
    if (a.nome < b.nome) {
      return -1;
    }
    if (a.nome > b.nome) {
      return 1;
    }
    return 0;
  });
  return data;
};

const fetchAllDistricts = async () => {
  const { data: districts } = await locationApi.get(
    'municipios',
  );
  return districts;
};

const fetchAllCities = async (value) => {
  const data = await fetchAllStates();
  const state = data.find((elm) => elm.sigla === value);
  if (state) {
    const { data: citiesData } = await locationApi.get(
      `estados/${state.id}/municipios`,
    );
    return citiesData;
  }
  return [];
};

export { fetchAllStates, fetchAllCities, fetchAllDistricts };
