import { Popconfirm, Space, Table, Tag, Input } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { UserContext } from "../../../context";
import { SITUACOES } from "../../../models/solicitacoes";
import history from "../../../routes/history";
import { abrirComprovanteSolicitacao } from "../../../services/solicitacoes";

const situacoesStyle = {
  LANÇADO: "yellow",
  CANCELADO: "red",
  APROVADO: "green",
  NAO_REALIZADO: "red",
  REALIZADO: "blue",
};

const prioridadeStyle = {
  ELETIVO: "#73d13d",
  PRIORITARIO: "#fadb14",
  URGENCIA: "#cf1322",
};
const { TextArea } = Input;
const { Column } = Table;
export default function SolicitacoesTable({
  solicitacoes,
  loading,
  handleTableChange,
  total,
  confirm,
  realizar,
  naoRealizar
}) {
  const [observacao, setObservacao] = useState();
  function cancel(e) {
    console.log(e);
  }

  const onChangeObservacao = (text) => {
    setObservacao(text.target.value)
  }
  const user = useContext(UserContext);
  return (
    <div>
      <Table
        loading={loading}
        size="small"
        pagination={{ pageSize: 10, total }}
        onChange={handleTableChange}
        style={{ width: "100%" }}
        dataSource={solicitacoes}
        rowKey="id"
      >
        <Column title="Código" dataIndex="id" key="id" />
        <Column
          title="Situação"
          dataIndex="situacao"
          key="situacao"
          render={(situacao) => (
            <Tag color={situacoesStyle[situacao]} key={situacao}>
              {situacao}
            </Tag>
          )}
        />
        <Column
          title="Data"
          dataIndex="data"
          render={(data) => moment(data).format("DD/MM/YYYY")}
          key="data"
        />
        
        {user?.type !== "CLINICA" && <Column
          title="Clinica"
          dataIndex={["clinica", "pessoa_juridica", "nome"]}
          key="clinica_nome"
        /> }
        <Column
          title="Paciente"
          dataIndex={["paciente", "pessoa_fisica", "nome"]}
          key="paciente_nome"
        />
        <Column
          title="Profissional realizador"
          dataIndex={["profissional_clinica", "profissional"]}
          key="profissional_realizador"
        />
        <Column
          title="Prioridade"
          dataIndex="prioridade"
          key="prioridade"
          render={(prioridade) => (
            <Tag color={prioridadeStyle[prioridade]} key={prioridade}>
              {prioridade}
            </Tag>
          )}
        />
        <Column
          title="Ação"
          key="action"
          render={(text, record) => (
            <>
              {user?.type === "CLINICA" ? 
                <> {record.situacao !== SITUACOES.NAO_REALIZADO && record.situacao !== SITUACOES.REALIZADO && <Space size="middle">
                  <Popconfirm
                    title="Deseja confirma a realização"
                    onConfirm={() => realizar(record)}
                    onCancel={cancel}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <a>Realizar</a>
                  </Popconfirm>
                  <></>
                  <Popconfirm
                    title={<div>
                      <div>Deseja confirmar a <b>não realização</b>? Digite a observação:</div>
                      <TextArea onChange={onChangeObservacao} rows={4} />
                    </div>}
                    onConfirm={() => naoRealizar(record, observacao)}
                    onCancel={cancel}
                    okButtonProps={{disabled: !observacao}}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <a>Não realizar</a>
                  </Popconfirm>
                </Space>} </>
               : <>
                    <Space size="middle">
                      <a onClick={() => history.push(`/solicitacoes/${record.id}`)}>
                        Visualizar
                      </a>
                      
                      {record.situacao !== SITUACOES.LANCADO &&
                        record.situacao !== SITUACOES.PENDENTE &&
                        record.situacao !== SITUACOES.CANCELADO && (
                          <a onClick={() => abrirComprovanteSolicitacao(record.id)}>
                            Comprovante
                          </a>
                        )}
                      {user?.type === "ADM" && (
                        <Popconfirm
                          title="Deseja deletar a solicitação?"
                          onConfirm={() => confirm(record)}
                          onCancel={cancel}
                          okText="Sim"
                          cancelText="Não"
                        >
                          <a>Deletar</a>
                        </Popconfirm>
                      )}
                    </Space>
                </>
              }
          </>
          )}
        />
      </Table>
    </div>
  );
}
