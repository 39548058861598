import React from 'react';

import { Collapse } from 'antd';
import { Provider } from 'react-redux';

import store from '../../../store';
import SolicitacaoForm from '../forms';

function callback(key) {
  console.log(key);
}

const { Panel } = Collapse;

export default function ListModelSolicitacoes({ text, solicitacoes }) {
  return (
    <div syle={{ marginTop: '50px!important' }}>
      <br />
      <h4 syle={{ marginBottom: '50px!important' }}>{text}</h4>
      <Collapse onChange={callback}>
        {solicitacoes.map((solicitacao) => (
          <Panel
            header={`Solicitação - ${solicitacao.numero}`}
            key={solicitacao.id}
          >
            <Provider store={store}>
              <SolicitacaoForm
                initialValues={{ ...solicitacao }}
                viewMode
                disableButtons
              />
            </Provider>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
}
