import { notification } from 'antd';

import history from '../../routes/history';
import api from '../api';
import ApiException from '../exception/ApiException';

const registerLayout = async (values) => {
  try {
    await api.post('layout', values);
    history.push('/');
    notification.open({
      message: 'Layout atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const getLayout = async () => {
  const { data } = await api.get('layout');
  return data;
};

const updateLayout = async (layout, values) => {
  try {
    values.id = layout.id;
    await api.put('layout', values);
    history.push('/');
    notification.open({
      message: 'Layout atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export { registerLayout, getLayout, updateLayout };
