import React, { useEffect, useState } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  DatePicker,
  Form,
  InputNumber,
  Radio,
  Select,
  Tooltip,
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";

import { getProcedimentos } from "../../../services/procedimentos";
import { getCotaByProcedimentoId } from "../../../services/quotas";
import { VerticalContainer } from "../../styled";
import "moment/locale/pt-br";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
export default function QuotaForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
}) {
  const [form] = Form.useForm();
  const [procedimentos, setProcedimentos] = useState([]);
  const [value, setValue] = React.useState(1);
  const onEdit = () => {
    setError();
  };
  const getAllProcedimentos = async () => {
    const data = await getProcedimentos("", "", false);
    setProcedimentos(data);
  };
  const onChangeProcedimento = () => {
    setValue(1);
    setError();
    form.setFieldsValue({ quantidade: null });
  };
  const getProcedimento = async () => {
    try {
      const { id } = JSON.parse(form.getFieldValue("procedimento"));
      const data = await getCotaByProcedimentoId(id);
      form.setFieldsValue({
        quantidade: data.quantidade,
      });
    } catch (err) {
      setError(
        "O procedimento ainda não teve cotas cadastradas, por favor, cadestre novas cotas."
      );
      setValue(1);
    }
  };

  useEffect(() => {
    getAllProcedimentos();
  }, []);

  const onChange = async (e) => {
    const valor = e.target.value;
    setValue(valor);
    if (valor !== 1) {
      await getProcedimento();
    }
  };

  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={initialValues}
      >
        <Form.Item
          {...formItemLayout}
          name="procedimento"
          label="Procedimento"
          rules={[
            {
              required: true,
              message: "Procedimento é obrigatório!",
            },
          ]}
        >
          <Select
            onChange={onChangeProcedimento}
            disabled={viewMode}
            showSearch
          >
            {procedimentos.length > 0 &&
              procedimentos.map((proc) => (
                <Option key={proc.id} value={JSON.stringify(proc)}>
                  {proc.nome}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <VerticalContainer>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>Adicionar novas quotas</Radio>
            <br />
            <Radio value={2}>Usar quantidade do mês anterior</Radio>
            <br />
            <Radio value={3}>Utilizar cotas restantes do último mês</Radio>
            <br />
          </Radio.Group>
        </VerticalContainer>
        <Form.Item
          {...formItemLayout}
          name="quantidade"
          label={
            <span>
              Quantidade&nbsp;
              <Tooltip title="Digite a quantidade de quotas para o mês">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Quantidade é obrigatório!",
            },
          ]}
        >
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            disabled={viewMode || value !== 1}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="quantidade_emergencial"
          label={
            <span>
              Saldo emergêncial&nbsp;
              <Tooltip title="Digite a quantidade de quotas para emergência">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Quantidade emergêncial é obrigatório!",
            },
          ]}
        >
          <InputNumber
            min={0}
            style={{ width: "100%" }}
            disabled={viewMode || value !== 1}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="mes"
          label="Mês para lançamento das quotas"
          initialValue={moment()}
          rules={[
            {
              required: true,
              message: "Mês para lançamento é obrigatório!",
            },
          ]}
        >
          <DatePicker
            picker="month"
            format="MMM [de] YYYY"
            placeholder="Seleciona o mês"
            locale={locale}
          />
        </Form.Item>
        <br />
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
