import React from "react";

import { Router, Switch } from "react-router-dom";

import NotFound from "../pages/404";
import AccountDetail from "../pages/account/account-detail";
import ChangePassword from "../pages/account/change-password";
import ForgotPassword from "../pages/account/forgot-passoword";
import AddAtendimento from "../pages/atendimentos/add";
import EditAtendimento from "../pages/atendimentos/edit";
import ListAtendimentos from "../pages/atendimentos/list";
import AddClinica from "../pages/clinicas/add";
import EditClinica from "../pages/clinicas/edit";
import ListClinicas from "../pages/clinicas/list";
import ListConfigs from "../pages/configuracaoarquivos/list";
import ViewConfig from "../pages/configuracaoarquivos/view";
import DashBoard from "../pages/dashboard";
import ListDistricts from "../pages/district/list";
import ViewDistrito from "../pages/district/view";
import ListTypes from "../pages/tipodocumentos/list";
import ViewTipo from "../pages/tipodocumentos/view";
import EntityDetail from "../pages/entity/detail";
import AddFilaEspera from "../pages/fila_espera/add";
import EditFilaEspera from "../pages/fila_espera/edit";
import ListFilaEspera from "../pages/fila_espera/list";
import AddGestante from "../pages/gestantes/add";
import EditGestante from "../pages/gestantes/edit";
import ListGestantes from "../pages/gestantes/list";
import LayoutDetail from "../pages/layout/detail";
import Login from "../pages/login";
import AddPaciente from "../pages/pacientes/add";
import EditPaciente from "../pages/pacientes/edit";
import ListPacientes from "../pages/pacientes/list";
import ListProcedimentos from "../pages/procedimentos/list";
import AddProfissional from "../pages/profissional/add";
import EditProfissional from "../pages/profissional/edit";
import ListProfissionais from "../pages/profissional/list";
import ListProtocolos from "../pages/protocolos/list";
import ViewProtocolo from "../pages/protocolos/view";
import ListQuotas from "../pages/quotas/list";
import RelatorioAtendimentos from "../pages/relatorios/atendimentos";
import RelatorioClinicas from "../pages/relatorios/clinicas";
import RelatorioDistritos from "../pages/relatorios/distritos";
import RelatorioPacientes from "../pages/relatorios/pacientes";
import RelatorioProcedimentos from "../pages/relatorios/procedimentos";
import RelatorioProfissionais from "../pages/relatorios/profissionais";
import RelatorioSolicitacoes from "../pages/relatorios/solicitacoes";
import RelatorioUnidades from "../pages/relatorios/unidades";
import AddSolicitacao from "../pages/solicitacoes/add";
import EditSolicitacao from "../pages/solicitacoes/edit";
import ListSolicitacoes from "../pages/solicitacoes/list";
import AddUnidade from "../pages/unidade/add";
import EditUnidade from "../pages/unidade/edit";
import ListUnidades from "../pages/unidade/list";
// import RegistrationForm from '../pages/users/adm';
import ListUsers from "../pages/users/list";
import history from "./history";
import Route from "./route";
import RelatorioQuotas from "../pages/relatorios/quotas";
import ListChaves from "../pages/chavesConfiguracoes/list";
import ViewChave from "../pages/chavesConfiguracoes/view";

const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={Login} />
      <Route
        exact
        path="/dashboard"
        component={DashBoard}
        title="Dashboard"
        subtitle="Resumo do sistema"
        isPrivate
      />
      <Route exact path="/resetar-senha" component={ForgotPassword} />
      <Route exact path="/recuperar-senha/:hash" component={ChangePassword} />

      <Route
        exact
        path="/users"
        title="Usuários"
        subtitle="Gerenciamento dos usuários do sistema"
        component={ListUsers}
        isPrivate
        isAdm
      />
      <Route
        exact
        path="/relatorios/solicitacoes"
        title="Relatórios"
        subtitle="Geração de relatórios dos dados das solicitações"
        component={RelatorioSolicitacoes}
        isPrivate
      />
      <Route
        exact
        path="/relatorios/quotas"
        title="Relatorios"
        subtitle= "Geração de relatórios dos dados das quotas"
        component={RelatorioQuotas}
        isPrivate
      />
      <Route
        exact
        path="/relatorios/procedimentos"
        title="Relatórios"
        subtitle="Geração de relatórios dos dados dos procedimentos"
        component={RelatorioProcedimentos}
        isPrivate
      />
      <Route
        exact
        path="/relatorios/atendimentos"
        title="Relatórios"
        subtitle="Geração de relatórios dos dados dos atendimentos"
        component={RelatorioAtendimentos}
        isPrivate
      />
      <Route
        exact
        path="/relatorios/pacientes"
        title="Relatórios"
        subtitle="Geração de relatórios dos dados dos pacientes"
        component={RelatorioPacientes}
        isPrivate
      />
      <Route
        exact
        path="/relatorios/profissionais"
        title="Relatórios"
        subtitle="Geração de relatórios dos dados dos profissionais"
        component={RelatorioProfissionais}
        isPrivate
      />
      <Route
        exact
        path="/relatorios/distritos"
        title="Relatórios"
        subtitle="Geração de relatórios dos dados dos distritos"
        component={RelatorioDistritos}
        isPrivate
      />
      <Route
        exact
        path="/layout"
        title="Estilização"
        subtitle="Altere a tela de fundo do login"
        component={LayoutDetail}
        isPrivate
        isAdm
      />
      <Route
        exact
        title="Relatórios"
        subtitle="Geração de relatórios dos dados das unidades"
        path="/relatorios/unidades"
        component={RelatorioUnidades}
        isPrivate
      />
      <Route
        exact
        title="Relatórios"
        subtitle="Geração de relatórios dos dados das clínicas"
        path="/relatorios/clinicas"
        component={RelatorioClinicas}
        isPrivate
      />

      <Route
        exact
        path="/home"
        title="Solicitações"
        subtitle="Gerenciamento das solicitações do sistema"
        component={ListSolicitacoes}
        isPrivate
      />
      {/* <Route exact path="/register" component={RegistrationForm} /> */}
      <Route
        exact
        path="/conta"
        title="Minha conta"
        subtitle="Gerenciamento dos seus dados"
        component={AccountDetail}
        isPrivate
      />
      <Route
        exact
        path="/quotas"
        title="Quotas"
        subtitle="Gerenciamento de quotas do sistema"
        component={ListQuotas}
        isPrivate
        isAdm
      />

      <Route
        exact
        path="/adicionar-paciente"
        title="Pacientes"
        subtitle="Adicionar paciente"
        component={AddPaciente}
        isPrivate
      />
      <Route
        exact
        path="/pacientes"
        title="Pacientes"
        subtitle="Gerenciamento de pacientes"
        component={ListPacientes}
        isPrivate
      />
      <Route
        exact
        path="/pacientes/:id"
        title="Pacientes"
        subtitle="Gerenciamento do paciente"
        component={EditPaciente}
        isPrivate
      />

      <Route
        exact
        title="Solicitações"
        subtitle="Adicionar solicitação"
        path="/adicionar-solicitacao"
        component={AddSolicitacao}
        isPrivate
      />
      <Route
        exact
        path="/solicitacoes"
        title="Solicitações"
        subtitle="Gerenciamento das solicitações"
        component={ListSolicitacoes}
        isPrivate
      />
      <Route
        exact
        path="/solicitacoes/:id"
        title="Solicitações"
        subtitle="Gerenciar solicitação"
        component={EditSolicitacao}
        isPrivate
      />

      <Route
        exact
        title="Atendimento"
        subtitle="Adicionar atendimento"
        path="/adicionar-atendimento"
        component={AddAtendimento}
        isPrivate
      />
      <Route
        exact
        path="/atendimentos"
        title="Atendimentos"
        subtitle="Gerenciamento dos atendimentos"
        component={ListAtendimentos}
        isPrivate
      />
      <Route
        exact
        title="Atendimentos"
        subtitle="Gerenciar atendimento"
        path="/atendimentos/:id"
        component={EditAtendimento}
        isPrivate
      />

      <Route
        exact
        title="Profissionais"
        subtitle="Adicionar profisional"
        path="/adicionar-profissional"
        component={AddProfissional}
        isPrivate
      />
      <Route
        exact
        title="Profissionais"
        subtitle="Gerenciamento dos profissionais"
        path="/profissionais"
        component={ListProfissionais}
        isPrivate
      />
      <Route
        exact
        title="Profissionais"
        subtitle="Gerenciamento do profissional"
        path="/profissionais/:id"
        component={EditProfissional}
        isPrivate
      />

      <Route
        exact
        path="/adicionar-unidade"
        title="Unidades de saúde"
        subtitle="Adicionar unidade de saúde"
        component={AddUnidade}
        isPrivate
      />
      <Route
        exact
        path="/unidades/:id"
        title="Unidades de Saúde"
        subtitle="Gerenciamento da unidade"
        component={EditUnidade}
        isPrivate
      />
      <Route
        exact
        path="/unidades"
        title="Unidades de Saúde"
        subtitle="Gerenciamento das unidades de saúde"
        component={ListUnidades}
        isPrivate
      />

      <Route
        exact
        path="/adicionar-clinica"
        title="Clínicas"
        subtitle="Adicionar clínica"
        component={AddClinica}
        isPrivate
      />
      <Route
        exact
        path="/clinicas/:id"
        title="Clínicas"
        subtitle="Gerenciamento da clínica"
        component={EditClinica}
        isPrivate
      />
      <Route
        exact
        path="/clinicas"
        title="Clínicas"
        subtitle="Gerenciamento das clínicas do sistema"
        component={ListClinicas}
        isPrivate
      />

      <Route
        exact
        path="/adicionar-fila-espera"
        title="Fila espera"
        subtitle="Adicionar novo"
        component={AddFilaEspera}
        isPrivate
      />
      <Route
        exact
        path="/fila-espera/:id"
        title="Fila Espera"
        subtitle=""
        component={EditFilaEspera}
        isPrivate
      />
      <Route
        exact
        path="/fila-espera"
        title="Fila de espera"
        subtitle="Gerenciamento das fila de espera"
        component={ListFilaEspera}
        isPrivate
      />

      <Route
        exact
        path="/entidade"
        title="Entidade"
        subtitle="Dados da entidade"
        component={EntityDetail}
        isPrivate
        isAdm
      />
      <Route
        exact
        path="/distritos"
        title="Distritos"
        subtitle="Gerenciamento dos distritos"
        component={ListDistricts}
        isPrivate
      />
      <Route
        exact
        path="/distritos/:id"
        title="Distritos"
        subtitle="Gerenciamento do distrito"
        component={ViewDistrito}
        isPrivate
      />

      <Route
        exact
        path="/tipos"
        title="Tipos"
        subtitle="Gerenciamento do tipo de documento"
        component={ListTypes}
        isPrivate
      />
      <Route
        exact
        path="/tipos/:id"
        title="Tipos"
        subtitle="Gerenciamento do tipo de documento"
        component={ViewTipo}
        isPrivate
      />

      <Route
        exact
        path="/configuracao-arquivos"
        title="Configuração de arquivos"
        subtitle="Gerenciamento da configuração de arquivos"
        component={ListConfigs}
        isPrivate
      />
      <Route
        exact
        path="/configuracao-arquivos/:id"
        title="Configuração de arquivos"
        subtitle="Gerenciamento da configuração de arquivos"
        component={ViewConfig}
        isPrivate
      />
      <Route
        exact
        path="/configuracoes"
        title="Chaves de configurações"
        subtitle= "Gerenciamento das chaves de configurações"
        component={ListChaves}
        isAdm
        isPrivate
      />
      <Route
        exact
        path="/configuracoes/:id"
        title="Chave de configuração"
        subtitle="Gerenciamento da chave de configuração"
        component={ViewChave}
        isPrivate
      />
      <Route
        exact
        path="/protocolos"
        title="Protocolos"
        subtitle="Gerenciamento de protocolos"
        component={ListProtocolos}
        isPrivate
      />
      <Route
        exact
        path="/protocolos/:id"
        title="Protocolos"
        subtitle="Gerenciamento do protocolo"
        component={ViewProtocolo}
        isPrivate
      />
      <Route
        exact
        title="Procedimentos"
        subtitle="Gerenciamento dos procedimentos"
        path="/procedimentos"
        component={ListProcedimentos}
        isPrivate
      />

      <Route
        exact
        path="/adicionar-gestante"
        title="Gestantes"
        subtitle="Adicionar novo"
        component={AddGestante}
        isPrivate
      />
      <Route
        exact
        path="/gestantes/:id"
        title="Gestantes"
        subtitle="Gerenciamento do gestante"
        component={EditGestante}
        isPrivate
      />
      <Route
        exact
        title="Gestantes"
        subtitle="Gerenciamento dos gestantes"
        path="/gestantes"
        component={ListGestantes}
        isPrivate
      />
      <Route path="/" component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
