import React, { useEffect, useState } from 'react';

import EditButton from '../../../components/buttons/edit';
import LayoutForm from '../../../components/layout/form';
import {
  InlineContainer,
  VerticalContainer,
} from '../../../components/styled';
import {
  getLayout,
  registerLayout,
  updateLayout,
} from '../../../services/layout';

export default function LayoutDetail() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [layout, setLayout] = useState(false);
  const [viewMode, setViewMode] = useState(true);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await getLayout();
      setLayout(data);
    } finally {
      setLoading(false);
    }
  };
  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (!layout.id) {
        await registerLayout(values);
      } else {
        await updateLayout(layout, values);
      }
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <VerticalContainer>
      <InlineContainer>
        <div />
        <EditButton viewMode={viewMode} setViewMode={setViewMode} />
      </InlineContainer>
      {!loading
        ? (
          <LayoutForm
            initialValues={{ ...layout }}
            onFinish={onFinish}
            error={error}
            setError={setError}
            loading={loading}
            viewMode={viewMode}
          />
        )
        : ''}
    </VerticalContainer>
  );
}
