import React, { useState } from 'react';

import AtendimentoForm from '../../../components/atendimentos/forms';
import { VerticalContainer } from '../../../components/styled';
import { registerAtendimento } from '../../../services/atendimentos';

export default function AddAtendimento() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await registerAtendimento(values);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <VerticalContainer>
      <div />
      <AtendimentoForm
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </VerticalContainer>
  );
}
