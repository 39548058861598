import { notification } from "antd";

import history from "../../routes/history";
import api from "../api";
import ApiException from "../exception/ApiException";

const registerType = async (values) => {
  try {
    await api.post("tipos", values);
    history.push("/tipos");
    notification.open({
      message: "Tipo de Documento adicionado com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteType = async (id) => {
  try {
    await api.delete(`tipos/${id}`);
    notification.open({
      message: "Tipo de Documento deletado com sucesso",
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getTypes = async (page, filter = "", paginated = "") => {
  const { data: districts } = await api.get(
    `tipos?page=${page}&filter=${filter}&paginated=${paginated}`
  );
  return districts;
};

const getType = async (id) => {
  try {
    const { data } = await api.get(`tipos/${id}`);
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data);
  }
};

const updateType = async (type, values) => {
  try {
    values.id = type.id;
    await api.put("tipos", values);
    history.push("/tipos");
    notification.open({
      message: "Tipo de Documento atualizado com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export { registerType, deleteType, getTypes, updateType, getType };
