import { all } from 'redux-saga/effects';

import { rootAuthSaga } from './auth';
import { rootClinicaSaga } from './clinica';
import { rootCnaeNaturezaSaga } from './cnae-natureza';
import { rootEntidadeSaga } from './entidade';
import { rootEstadosSaga } from './estados';
import { rootPacienteSaga } from './paciente';
import { rootProfissionalSaga } from './profissional';
import { rootUnidadeSaga } from './unidade';
import { rootUserSaga } from './users';

export default function* rootSaga() {
  yield all([
    rootAuthSaga(),
    rootUserSaga(),
    rootCnaeNaturezaSaga(),
    rootPacienteSaga(),
    rootProfissionalSaga(),
    rootUnidadeSaga(),
    rootClinicaSaga(),
    rootEntidadeSaga(),
    rootEstadosSaga(),
  ]);
}
