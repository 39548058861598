import React, { useEffect, useState } from "react";

import GestanteForm from "../../../components/gestantes/forms";
import { VerticalContainer } from "../../../components/styled";
import { registerGestante } from "../../../services/gestantes";

export default function AddGestante(props) {
  const gestante = props.location.state;

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      return await registerGestante(values);
    } catch (err) {
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <VerticalContainer>
      <div />
      <GestanteForm
        initialValues={gestante}
        onFinish={onFinish}
        error={error}
        setError={setError}
        loading={loading}
      />
    </VerticalContainer>
  );
}
