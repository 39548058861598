import axios from 'axios';

import history from '../routes/history';
import { getToken, unsetToken } from '../utils';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const token = getToken();

  const headers = { ...config.headers };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return { ...config, headers };
});

api.interceptors.response.use((response) => response, (error) => {
  const status = error?.response?.status;
  if (status === 401) {
    unsetToken();
    history.push('/');
  }
  return Promise.reject(error);
});

export default api;
