import { Popconfirm, Space, Table, Tag, Radio } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context";
import history from "../../../routes/history";
import { SelectOutlined } from "@ant-design/icons";

const situacoes = {
  LANÇADO: "yellow",
  CANCELADO: "red",
  APROVADO: "green",
};
const { Column } = Table;
export default function FilaEsperaTable({
  filasEspera,
  loading,
  handleTableChange,
  total,
  confirm,
  showDeletar = true,
  showAprovar = false,
  paciente = {}
}) {
  const [pacientePivot, setPacientePivot] = useState();
  function cancel(e) {
    console.log(e);
  }
  const procuraPaciente = (record) =>{
    let pacientePivot = {};
    if(paciente != null){
      return pacientePivot = record.pacientes.find((p) => p.id === paciente.id);
    }
    return;
  }

  const user = useContext(UserContext);
  return (
    <div>
      <Table
        loading={loading}
        size="small"
        pagination={{ pageSize: 10, total }}
        onChange={handleTableChange}
        style={{ width: "100%" }}
        dataSource={filasEspera}
        rowKey="id"
      >
        <Column title="Código" dataIndex="id" key="id" />

        <Column
          title="Procedimento"
          dataIndex={["procedimento", "nome"]}
          key="procedimento_nome"
        />
        <Column
          title="Data"
          dataIndex="data"
          render={(data) => moment(data).format("DD/MM/YYYY")}
          key="data"
        />
         
        {/* <Column
          title="Situação"
          dataIndex="situacao"
          key="situacao"
          render={(situacao) => (
            <Tag color={situacoes[situacao]} key={situacao}>
              {situacao}
            </Tag>
          )}
        /> */}
        <Column
          title="Ação"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => history.push(`/fila-espera/${record.id}`)}>
                Visualizar
              </a>
              {user?.type === "ADM" && !!showDeletar && (
                <Popconfirm
                  title="Deseja deletar?"
                  onConfirm={() => confirm(record)}
                  onCancel={cancel}
                  okText="Sim"
                  cancelText="Não"
                >
                  <a>Deletar</a>
                </Popconfirm>
              )}
              {showAprovar ? (
                procuraPaciente(record)?.pivot?.aprovado ? (
                  <div>
                    <Tag color="green" key="aprovado">
                      Aprovado
                    </Tag>
                    <Tag color="yellow" key="aprovad">
                      <a
                      onClick={() => history.push(`/solicitacoes/${procuraPaciente(record).pivot.solicitacao_id}`)}>
  
                      <SelectOutlined/> Solicitação
                      </a>
                      </Tag>
                  </div>
  
                ) : (
                  <div>
                    <Popconfirm
                    title= "Deseja aprovar a solicitação?"
                    onConfirm={()=> confirm(record)}
                    onCancel={()=> console.log()}
                    okText= "Sim"
                    cancelText= "Não"
                    >
                      <a>Aprovar</a>
                    </Popconfirm>
                  </div>
                )) : (
                  <div></div>
                )
              }
            </Space>
          )}
        />
      </Table>
    </div>
  );
}
