import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  registerUserRequest: ['user'],
  registerUserSuccess: ['user'],

  updateUserRequest: ['user'],
  updateUserSuccess: ['user'],

  getAllUserRequest: null,
  getAllUserSuccess: ['users'],

  deleteUserRequest: ['user'],
  deleteUserSuccess: null,

  requestUserFailure: ['error'],
});

const INITIAL_STATE = { users: [], loading: false, error: '' };

/**
 * REGISTER REQUESTS BELLOW
 */

const registerUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const registerUserSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * UPDATE REQUESTS BELLOW
 */

const deleteUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const deleteUserSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});
/**
 * UPDATE REQUESTS BELLOW
 */

const updateUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const updateUserSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * GET SERVICES
 */

const getAllUserRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
  users: [],
});

const getAllUserSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: '',
  loading: false,
  users: action.users,
});

/**
 * Request falure
 */
const requestUserFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_USER_REQUEST]: registerUserRequest,
  [Types.REGISTER_USER_SUCCESS]: registerUserSuccess,

  [Types.REQUEST_USER_FAILURE]: requestUserFailure,

  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,

  [Types.DELETE_USER_REQUEST]: deleteUserRequest,
  [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,

  [Types.GET_ALL_USER_REQUEST]: getAllUserRequest,
  [Types.GET_ALL_USER_SUCCESS]: getAllUserSuccess,
});
