import { notification } from 'antd';
import moment from 'moment';

import history from '../../routes/history';
import api from '../api';
import ApiException from '../exception/ApiException';

const normalizeAtendimento = (values) => {
  values.paciente_id = JSON.parse(values.paciente).id;
  values.profissional_id = JSON.parse(values.profissional).id;
  values.data = moment(values.data).format('Y-M-D hh:mm');
  delete values.paciente;
  delete values.profissional;
  delete values.created_by;
  delete values.updated_by;
};

const onGetAtendimento = (values) => {
  values.clinica = JSON.stringify(values.clinica);
  values.paciente = JSON.stringify(values.paciente);
  values.profissional = JSON.stringify(values.profissional);
  values.data = moment(values.data, 'Y-M-D hh:mm');
};

const registerAtendimento = async (values) => {
  try {
    normalizeAtendimento(values);
    await api.post('atendimentos', values);
    history.push('/atendimentos');
    notification.open({
      message: 'Atendimento adicionado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteAtendimento = async (id) => {
  try {
    await api.delete(`atendimentos/${id}`);
    notification.open({
      message: 'Atendimento deletado com sucesso',
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getAtendimentos = async (page, filter = '') => {
  const { data: atendimentos } = await api.get(
    `atendimentos?page=${page}&filter=${filter}`,
  );
  return atendimentos;
};

const getAtendimentosByPaciente = async (pacienteId) => {
  const { data: atendimentos } = await api.get(
    `atendimentos?paciente_id=${pacienteId}`,
  );
  return atendimentos;
};

const getAtendimento = async (id) => {
  try {
    const { data } = await api.get(`atendimentos/${id}`);
    onGetAtendimento(data);
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const abrirComprovanteAtendimento = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/atendimentos/comprovante/${id}`;
  window.open(url, 'PRINT', 'height=720,width=900');
};

const getByCurrentMonthAndPaciente = async (id, procedimentoId) => {
  try {
    const { data } = await api.get(
      `atendimentos/paciente/${id}/${procedimentoId}`,
    );
    data.forEach((solicitaao) => onGetAtendimento(solicitaao));
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const updateAtendimento = async (atendimento, values) => {
  try {
    normalizeAtendimento(values);
    values.id = atendimento.id;
    await api.put('atendimentos', values);
    history.push('/atendimentos');
    notification.open({
      message: 'Atendimento atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerAtendimento,
  deleteAtendimento,
  getAtendimentos,
  updateAtendimento,
  getAtendimentosByPaciente,
  getAtendimento,
  abrirComprovanteAtendimento,
  getByCurrentMonthAndPaciente,
};
