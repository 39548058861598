import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  registerProfissionalRequest: ['profissional'],
  registerProfissionalSuccess: null,

  updateProfissionalRequest: ['profissional', 'values'],
  updateProfissionalSuccess: null,

  getAllProfissionalRequest: null,
  getAllProfissionalSuccess: ['profissionais'],

  deleteProfissionalRequest: ['id'],
  deleteProfissionalSuccess: null,

  requestProfissionalFailure: ['error'],
});

const INITIAL_STATE = { profissionais: [], loading: false, error: '' };

/**
 * REGISTER REQUESTS BELLOW
 */

const registerProfissionalRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const registerProfissionalSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * UPDATE REQUESTS BELLOW
 */

const deleteProfissionalRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const deleteProfissionalSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});
/**
 * UPDATE REQUESTS BELLOW
 */

const updateProfissionalRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const updateProfissionalSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * GET SERVICES
 */

const getAllProfissionalRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
  profissionais: [],
});

const getAllProfissionalSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: '',
  loading: false,
  profissionais: action.profissionais,
});

/**
 * Request falure
 */
const requestProfissionalFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_PROFISSIONAL_REQUEST]: registerProfissionalRequest,
  [Types.REGISTER_PROFISSIONAL_SUCCESS]: registerProfissionalSuccess,

  [Types.REQUEST_PROFISSIONAL_FAILURE]: requestProfissionalFailure,

  [Types.UPDATE_PROFISSIONAL_REQUEST]: updateProfissionalRequest,
  [Types.UPDATE_PROFISSIONAL_SUCCESS]: updateProfissionalSuccess,

  [Types.DELETE_PROFISSIONAL_REQUEST]: deleteProfissionalRequest,
  [Types.DELETE_PROFISSIONAL_SUCCESS]: deleteProfissionalSuccess,

  [Types.GET_ALL_PROFISSIONAL_REQUEST]: getAllProfissionalRequest,
  [Types.GET_ALL_PROFISSIONAL_SUCCESS]: getAllProfissionalSuccess,
});
