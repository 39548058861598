import React, { useEffect, useState } from 'react';

import { Column } from '@ant-design/charts';
import { Card, Col, Row } from 'antd';

import SkeletonContainer from '../../components/skeleton-container';
import { SecondaryText, SecondaryTextJustify } from '../../components/styled';
import {
  gerarDashboardPaciente, gerarDashboardSolicitacao, gerarDashboardProfissionais,
  gerarDashboardAtendimentos,
} from '../../services/relatorios';

export default function DashBoard() {
  const [data, setData] = useState([]);
  const [totalPacientes, setTotalPacientes] = useState();
  const [totalProfissionais, setTotalProfissionais] = useState();
  const [totalAtendimentos, setTotalAtendimentos] = useState();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const result = await gerarDashboardSolicitacao();
      const resultPacientes = await gerarDashboardPaciente();
      const resultProfissionais = await gerarDashboardProfissionais();
      const resultAtendimentos = await gerarDashboardAtendimentos();
      setTotalPacientes(resultPacientes);
      setTotalAtendimentos(resultAtendimentos);
      setTotalProfissionais(resultProfissionais);
      setData(result);
    } finally {
      setLoading(false);
    }
  };

  const getConfig = () => ({
    data,
    xField: 'month',
    yField: 'quantidade',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    meta: {
      month: { alias: 'Mês' },
      quantidade: { alias: 'Quantidade' },
    },
  });

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="site-card-wrapper" style={{ width: '100%' }}>
      <Row gutter={24}>
        <Col span={8}>
          <Card bodyStyle={{ marginTop: '0' }}>
            <SecondaryTextJustify>Total pacientes</SecondaryTextJustify>
            <div style={{ fontSize: '25px' }}>{totalPacientes}</div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bodyStyle={{ marginTop: '0' }}>

            <SecondaryTextJustify>Total profissionais</SecondaryTextJustify>
            <div style={{ fontSize: '25px' }}>
              {' '}
              {totalProfissionais}
              {' '}
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bodyStyle={{ marginTop: '0' }}>
            <SecondaryTextJustify>Total atendimentos</SecondaryTextJustify>
            <div style={{ fontSize: '25px' }}>
              {' '}
              {totalAtendimentos}
            </div>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
      <Row gutter={24} style={{ width: '100%' }}>
        <SkeletonContainer loading={loading}>
          {data
          && (
          <Col span={24}>
            <Card title="Solicitações/Mês" bordered={false}>
              <Column
                {...getConfig()}
                onReady={(plot) => {
                  plot.on('plot:click', (evt) => {
                    const { x, y } = evt;
                    const tooltipData = plot.chart.getTooltipItems({ x, y });
                    console.log(tooltipData);
                  });
                }}
              />
            </Card>
          </Col>
          ) }
        </SkeletonContainer>
      </Row>
    </div>
  );
}
