import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { Provider } from 'react-redux';

import store from '../../../store';
import PacienteForm from '../forms';

const { Panel } = Collapse;

export function showPacientes(pacientes, document) {
  confirm({
    width: '1200px',
    title: `Atenção! Um ou mais pacientes com o ${document} informado foram encontrados.`,
    icon: <ExclamationCircleOutlined />,
    content: (
      <PacienteModalView
        pacientes={pacientes}
        text="Os dados informados já existem em outros pacientes cadastrados."
      />
    ),
    okText: 'Continuar',
    cancelText: 'Cancelar',
    onOk() {
      console.log('Continuar');
    },
    onCancel() {
      console.log('Cancel');
    }
  });
}
function PacienteModalView({ pacientes, text }) {
  return (
    <div syle={{ marginTop: '50px!important' }}>
      <br />
      <h4 syle={{ marginBottom: '50px!important' }}>{text}</h4>
      <Collapse>
        {pacientes.map((paciente) => (
          <Panel
            header={`Paciente - (Código ${paciente.id}) - ${paciente?.pessoa_fisica?.nome}`}
            key={paciente.id}
          >
            <Provider store={store}>
              <PacienteForm
                initialValues={{ ...paciente }}
                viewMode
                disableButtons
              />
            </Provider>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
}

export default PacienteModalView;
