import React, { useState } from 'react';

import { Layout, PageHeader } from 'antd';

import Header from '../header';
import Sidebar from '../sidebar';

const { Content } = Layout;

export default function Container({
  children, title, subtitle,
}) {
  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Inicio',
    },
    {
      path: 'first',
      breadcrumbName: title,
    },
  ];
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar collapsed={collapsed} />
      <Layout className="site-layout">
        <Header collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
        {title && (
        <PageHeader
          className="site-page-header"
          title={title}
          breadcrumb={{ routes }}
          subTitle={subtitle}
        />
        )}
        <Content
          className="site-layout-background"
          style={{
            margin: '0px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}
