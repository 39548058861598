import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import history from "../../../routes/history";
import { getPacientes } from "../../../services/pacientes";
import { getProcedimentos } from "../../../services/procedimentos";
import { getQuotas } from "../../../services/quotas";
import { failureNotification } from "../../../utils";
import { FormItemSelect } from "../../ant-wrapper/form-item";
import DetailPacientBox from "../../pacientes/datail-box";
import ProcedimentosListBox from "../../procedimentos/list-box";
import SkeletonContainer from "../../skeleton-container";
import confirmProcedimentos from "../modal-solicitacoes";
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};
const cardStyle = { borderColor: "white" };

export default function SolicitacaoForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
  disableButtons = false,
}) {
  const [form] = Form.useForm();

  const [pacientes, setPacientes] = useState([]);
  const [procedimentos, setProcedimentos] = useState([]);
  const [procedimentosAdicionados, setProcedimentosAdicionados] = useState([]);
  const [paciente, setPaciente] = useState();
  const [loadingDados, setloadingDados] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const onEdit = () => {
    setError();
  };

  const onSearch = async (result) => {
    const pacientesData = await getPacientes(0, result);
    setPacientes(pacientesData.data);
  };

  const getData = async () => {
    let filter = "";
    if (initialValues?.paciente) {
      const pac = JSON.parse(initialValues?.paciente);
      filter = pac.pessoa_fisica?.nome;
    }

    const pacientesData = await getPacientes(0, filter);
    setPacientes(pacientesData.data);
    setloadingDados(false);
    try {
      setProcedimentos(await getProcedimentos("", "", false));
      if (initialValues.procedimentos?.length > 0) {
        for (let procedimento of initialValues.procedimentos) {
          const mes = moment(initialValues.data).format("MM");
          const ano = moment(initialValues.data).format("YYYY");

          const { data: quotas } = await getQuotas(
            "",
            mes,
            ano,
            procedimento.nome
          );

          var quota = quotas.find((quota) => quota.saldo !== null);

          procedimento.saldo = quota?.saldo;
          procedimento.saldo_emergencial = quota?.saldo_emergencial;
        }

        setProcedimentosAdicionados(initialValues.procedimentos);
      }
      if (initialValues?.paciente) {
        setPaciente(JSON.parse(initialValues?.paciente));
      }
    } catch (err) {
      console.log(err);
      setError(err.error);
    } finally {
      setloadingDados(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const onChangePaciente = async (value) => {
    const pacienteAux = JSON.parse(value);
    setPaciente(pacienteAux);
  };

  const onChangeProcedimento = async (value) => {
    const dataProcedimento = form.getFieldValue("data");

    if (!paciente) {
      setError("Preencha o paciente antes de adicionar os procedimentos");
      return;
    }
    setError("");
    const procedimento = JSON.parse(value);
    const mes = moment(dataProcedimento).format("MM");
    const ano = moment(dataProcedimento).format("YYYY");

    const { data: quotas } = await getQuotas("", mes, ano, procedimento.nome);

    var quota = quotas.find((quota) => quota.saldo !== null);

    procedimento.saldo = quota?.saldo;
    procedimento.saldo_emergencial = quota?.saldo_emergencial;

    if (!procedimentosAdicionados.find((p) => p.id === procedimento.id)) {
      setProcedimentosAdicionados([...procedimentosAdicionados, procedimento]);
    }
  };

  const onDeleteProcedimento = (value) => {
    const procedimentosAux = procedimentosAdicionados.filter(
      (p) => p.id !== value.id
    );
    setProcedimentosAdicionados(procedimentosAux);
  };

  const onOk = (values) => {
    let solicitacao = {
      paciente: initialValues.paciente,
      procedimentos: procedimentos.filter((proc) => values.includes(proc.nome)),
    };
    history.push("/adicionar-solicitacao", solicitacao);
    console.log(solicitacao);
  };
  const finish = async (values) => {
    try {
      await onFinish(values);
    } catch (err) {
      console.log(err);
      failureNotification(
        "Gestante não atualizado, por favor ajustar manualmente."
      );
    }
  };

  const onSubmit = async (values) => {
    setLoadingButton(true);
    try {
      values.procedimentos = procedimentosAdicionados;
      values.paciente_id = JSON.parse(values.paciente)?.id;
      if (!values.procedimentos.length) {
        setError("Selecione pelo menos um procedimento");
        return;
      }
      finish(values);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onSubmit}
        scrollToFirstError
        {...formItemLayout}
        initialValues={initialValues}
      >
        <Row>
          <SkeletonContainer loading={loadingDados}>
            <Col span={12}>
              <Card title="Dados básicos" style={{ ...cardStyle }}>
                <FormItemSelect
                  name="paciente"
                  label="Gestante"
                  onSearch={onSearch}
                  items={pacientes}
                  itemsLabel={["pessoa_fisica", "nome"]}
                  stringfyValue
                  required
                  onChangeSelect={onChangePaciente}
                  viewMode={viewMode}
                />

                <FormItemSelect
                  name="trimestre"
                  label="Trimestre"
                  items={[
                    { nome: "1º trimestre ", value: "PRIMEIRO_TRIMESTRE" },
                    { nome: "2º trimestre ", value: "SEGUNDO_TRIMESTRE" },
                    { nome: "3º trimestre ", value: "TERCEIRO_TRIMESTRE" },
                  ]}
                  itemsLabel="nome"
                  itemsValue="value"
                  required
                  viewMode={viewMode}
                />

                <DetailPacientBox paciente={paciente} />
              </Card>
            </Col>
          </SkeletonContainer>

          <SkeletonContainer loading={loadingDados}>
            <Col span={10}>
              <Card
                title={viewMode ? "Procedimentos" : "Adicionar procedimentos"}
                style={{ ...cardStyle }}
              >
                {!viewMode && (
                  <Select
                    value={null}
                    placeholder="Adicionar procedimentos"
                    style={{ width: "100%", marginBottom: "20px" }}
                    onChange={onChangeProcedimento}
                    disabled={viewMode}
                    showSearch
                  >
                    {procedimentos.length > 0 &&
                      procedimentos.map((cli) => (
                        <Option key={cli.id} value={JSON.stringify(cli)}>
                          {cli.nome}
                        </Option>
                      ))}
                  </Select>
                )}
                <ProcedimentosListBox
                  disabled={viewMode}
                  onDelete={onDeleteProcedimento}
                  procedimentos={procedimentosAdicionados}
                  disableExtraFields
                  viewMode={viewMode}
                />
                <br />
                {viewMode && (
                  <Button
                    loading={loading || loadingButton}
                    type="primary"
                    onClick={() =>
                      confirmProcedimentos(procedimentosAdicionados, onOk)
                    }
                  >
                    Criar solicitação
                  </Button>
                )}
              </Card>
            </Col>
          </SkeletonContainer>
        </Row>
        <br />
        <br />
        {!disableButtons && (
          <>
            <SkeletonContainer loading={loadingDados}>
              <div style={{ marginLeft: "25px" }}>
                {!viewMode && (
                  <Form.Item>
                    <Button
                      loading={loading || loadingButton}
                      type="primary"
                      htmlType="submit"
                    >
                      Enviar
                    </Button>
                  </Form.Item>
                )}
              </div>
            </SkeletonContainer>
          </>
        )}
      </Form>
    </>
  );
}
