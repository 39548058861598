import { notification } from 'antd';

import history from '../../routes/history';
import { toDigitsOnly } from '../../utils';
import api from '../api';
import ApiException from '../exception/ApiException';
import { normalizePessoaFisica, preparePessoaFisica } from '../utils';

const registerProfissional = async (values) => {
  try {
    values.pessoa_fisica.endereco.cep = toDigitsOnly(
      values.pessoa_fisica.endereco.cep,
    );
    await api.post('profissionais', values);
    history.push('/profissionais');
    notification.open({
      message: 'Profissional adicionado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteProfissional = async (id) => {
  try {
    await api.delete(`profissionais/${id}`);
    notification.open({
      message: 'Profissional deletado com sucesso',
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getProfissionais = async (page, filter = '', paginated = '') => {
  const { data: profissionais } = await api.get(
    `profissionais?page=${page}&filter=${filter}&paginated=${paginated}`,
  );
  return profissionais;
};

const getProfissional = async (id) => {
  try {
    const { data } = await api.get(`profissionais/${id}`);
    normalizePessoaFisica(data.pessoa_fisica);
    return data;
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const updateProfissional = async (profissional, values) => {
  try {
    values.id = profissional.id;
    preparePessoaFisica(values, profissional);
    await api.put('profissionais', values);
    history.push('/profissionais');
    notification.open({
      message: 'Profissional atualizado com sucesso!',
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export {
  registerProfissional,
  deleteProfissional,
  getProfissionais,
  getProfissional,
  updateProfissional,
};
