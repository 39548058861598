import { notification } from "antd";

import history from "../../routes/history";
import api from "../api";
import ApiException from "../exception/ApiException";

const typeAdm = "ADM";

const normalizeUser = (values) => {
  values.clinica_id = JSON.parse(values.clinica).id;
  delete values.clinica;
};

const registerAdm = async (values) => {
  try {
    values.type = typeAdm;
    await api.post("users", values);
    history.push("/");
    notification.open({
      message: "Administrador cadastrado com sucesso!",
      description: "Realize o login para finalizar o cadastro",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const registerUser = async (values) => {
  try {
    if (values.type == "CLINICA") {
      normalizeUser(values);
    }
    await api.post("users", values);
    history.push("/users");
    notification.open({
      message: "Usuário adicionado com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

const deleteUser = async (id) => {
  try {
    await api.delete(`users/${id}`);
    notification.open({
      message: "Usuário deletado com sucesso",
    });
  } catch (error) {
    notification.open({
      message: error.response.data.error,
    });
  }
};

const getUsers = async (page) => {
  const { data: users } = await api.get(`users?page=${page}`);
  users.data.forEach((user) => {
    if (user.clinica) user.clinica = JSON.stringify(user.clinica);
  });
  return users;
};

const updateUser = async (user, values) => {
  try {
    values.id = user.id;
    if (values.type == "CLINICA") {
      normalizeUser(values);
    }
    await api.put("users", values);
    history.push("/users");
    notification.open({
      message: "Usuário atualizado com sucesso!",
    });
  } catch (error) {
    throw new ApiException(error?.response?.data?.error);
  }
};

export { registerAdm, registerUser, deleteUser, getUsers, updateUser };
