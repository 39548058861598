import { Button, Card, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import EditButton from "../../../components/buttons/edit";
import GestanteForm from "../../../components/gestantes/forms";
import {
  InlineContainer,
  TitleHeader,
  VerticalContainer,
} from "../../../components/styled";
import ViewContainer from "../../../components/view_container";
import { getGestante, updateGestante } from "../../../services/gestantes";

export default function EditGestante({ computedMatch }) {
  const [error, setError] = useState();
  const [gestante, setGestante] = useState();
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(true);
  const { params } = computedMatch;
  const onFinish = async (values) => {
    try {
      setLoading(true);
      await updateGestante(gestante, values);
    } catch (err) {
      console.log(err);
      setError(err.error);
    } finally {
      setLoading(false);
    }
  };

  const handleGestante = async () => {
    const data = await getGestante(params.id);
    setGestante(data);
  };

  useEffect(() => {
    handleGestante();
  }, []);

  return (
    <>
      {gestante ? (
        <VerticalContainer>
          <InlineContainer>
            <TitleHeader></TitleHeader>
            <EditButton viewMode={viewMode} setViewMode={setViewMode} />
          </InlineContainer>

          <ViewContainer item={gestante}>
            <GestanteForm
              initialValues={{ ...gestante }}
              onFinish={onFinish}
              error={error}
              setError={setError}
              loading={loading}
              viewMode={viewMode}
            />
          </ViewContainer>
        </VerticalContainer>
      ) : (
        <Spin />
      )}
    </>
  );
}
