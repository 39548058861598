import React, { useEffect, useState } from 'react';

import { MedicineBoxOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Spin, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import EditButton from '../../../components/buttons/edit';
import ClinicaForm from '../../../components/clinicas/forms';
import SolicitacoesTable from '../../../components/solicitacoes/table';
import {
  InlineContainer,
  VerticalContainer,
} from '../../../components/styled';
import ViewContainer from '../../../components/view_container';
import { getClinica } from '../../../services/clinicas';
import { gerarRelatorioSolicitacao } from '../../../services/relatorios';
import { deleteSolicitacao, getSolicitacoesByClinica } from '../../../services/solicitacoes';
import { Creators as ClinicaActions } from '../../../store/ducks/clinica';
import ListProfissionalClinicas from '../profissional/list';
import { useContext } from 'react';
import { UserContext } from '../../../context';

const { TabPane } = Tabs;

export default function EditClinica({ computedMatch }) {
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const [clinica, setClinica] = useState();
  const [solicitacoes, setSolicitacoes] = useState();
  const [viewMode, setViewMode] = useState(true);
  const { params } = computedMatch;
  const { loading, error: erro } = useSelector((state) => state.clinica);
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  const onFinish = async (values) => {
    dispatch(ClinicaActions.updateClinicaRequest(clinica, values));
  };
  useEffect(() => {
    setError(erro);
  }, [erro]);

  const getSolicitacoes = async () => {
    const solicitacoesData = await getSolicitacoesByClinica(params.id, page);
    setSolicitacoes(solicitacoesData.data);
    setTotal(solicitacoesData.total);
  };
  async function confirmSolicitacao(record) {
    await deleteSolicitacao(record.id);
    await getSolicitacoes();
  }
  const handleClinica = async () => {
    const data = await getClinica(params.id);
    await getSolicitacoes();
    setClinica(data);
  };

  const imprimirRelatorioSolicitacao = async () => {
    await gerarRelatorioSolicitacao({ clinica: JSON.stringify(clinica) });
  };

  useEffect(() => {
    handleClinica();
  }, []);

  useEffect(() => {
    getSolicitacoes();
  }, [page]);

  return (
    <Tabs defaultActiveKey="1" style={{ width: '100%' }}>
      <TabPane
        tab={(
          <span>
            <MedicineBoxOutlined />
            Detalhes
          </span>
        )}
        key="1"
      >
        <VerticalContainer>
          <InlineContainer>
            <div />
            {clinica?.permissao_editar ? <EditButton viewMode={viewMode} setViewMode={setViewMode} /> : ""}
          </InlineContainer>
          {clinica ? (
            <ViewContainer item={clinica}>
              <ClinicaForm
                initialValues={{ ...clinica }}
                onFinish={onFinish}
                error={error}
                setError={setError}
                loading={loading}
                viewMode={viewMode}
              />
            </ViewContainer>
          ) : (
            <Spin />
          )}
        </VerticalContainer>
      </TabPane>
      <TabPane
        tab={(
          <span>
            <TeamOutlined />
            Profissionais
          </span>
        )}
        key="2"
      >
        <ListProfissionalClinicas clinica={clinica} />
      </TabPane>
      <TabPane
        tab={(
          <span>
            <TeamOutlined />
            Solicitações
          </span>
        )}
        key="3"
      >
        <VerticalContainer>
          <InlineContainer>
            <div />
            <Button onClick={() => imprimirRelatorioSolicitacao()}>Imprimir</Button>
          </InlineContainer>

          <SolicitacoesTable
            handleTableChange={handleTableChange}
            solicitacoes={solicitacoes}
            total={total}
            confirm={confirmSolicitacao}
          />
        </VerticalContainer>
      </TabPane>
    </Tabs>
  );
}
