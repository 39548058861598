import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  registerUnidadeRequest: ['unidade'],
  registerUnidadeSuccess: null,

  updateUnidadeRequest: ['unidade', 'values'],
  updateUnidadeSuccess: null,

  getAllUnidadeRequest: null,
  getAllUnidadeSuccess: ['unidades'],

  deleteUnidadeRequest: ['id'],
  deleteUnidadeSuccess: null,

  requestUnidadeFailure: ['error'],
});

const INITIAL_STATE = { unidades: [], loading: false, error: '' };

/**
 * REGISTER REQUESTS BELLOW
 */

const registerUnidadeRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const registerUnidadeSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * UPDATE REQUESTS BELLOW
 */

const deleteUnidadeRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const deleteUnidadeSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});
/**
 * UPDATE REQUESTS BELLOW
 */

const updateUnidadeRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const updateUnidadeSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * GET SERVICES
 */

const getAllUnidadeRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
  unidades: [],
});

const getAllUnidadeSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: '',
  loading: false,
  unidades: action.unidades,
});

/**
 * Request falure
 */
const requestUnidadeFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_UNIDADE_REQUEST]: registerUnidadeRequest,
  [Types.REGISTER_UNIDADE_SUCCESS]: registerUnidadeSuccess,

  [Types.REQUEST_UNIDADE_FAILURE]: requestUnidadeFailure,

  [Types.UPDATE_UNIDADE_REQUEST]: updateUnidadeRequest,
  [Types.UPDATE_UNIDADE_SUCCESS]: updateUnidadeSuccess,

  [Types.DELETE_UNIDADE_REQUEST]: deleteUnidadeRequest,
  [Types.DELETE_UNIDADE_SUCCESS]: deleteUnidadeSuccess,

  [Types.GET_ALL_UNIDADE_REQUEST]: getAllUnidadeRequest,
  [Types.GET_ALL_UNIDADE_SUCCESS]: getAllUnidadeSuccess,
});
