import React, { useEffect, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Table, Space, Popconfirm, Button, Input, Tag,
} from 'antd';
import moment from 'moment';

import AtendimentosTable from '../../../components/atendimentos/table';
import { VerticalContainer } from '../../../components/styled';
import history from '../../../routes/history';
import {
  getAtendimentos,
  deleteAtendimento,
  abrirComprovanteAtendimento,
} from '../../../services/atendimentos';

const { Search } = Input;
const { Column } = Table;

const situacoesStyle = {
  LANÇADO: 'yellow',
  CANCELADO: 'red',
  APROVADO: 'green',
};

export default function ListAtendimentos() {
  const [filter, setFilter] = useState('');
  const [atendimentos, setAtendimentos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(10);
  const getAtendimentosPaginated = async () => {
    try {
      setLoading(true);
      const atendimentosData = await getAtendimentos(page, filter);
      setAtendimentos(atendimentosData.data);
      setTotal(atendimentosData.total);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAtendimentosPaginated();
  }, [page, filter]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };
  async function confirm(record) {
    await deleteAtendimento(record.id);
    await getAtendimentosPaginated();
  }

  function cancel(e) {
    console.log(e);
  }

  const onSearch = (value) => {
    setFilter(value);
  };
  const onChange = (e) => {
    if (!e.target.value) setFilter('');
  };

  return (
    <>
      <VerticalContainer>
        <Search
          onChange={onChange}
          style={{ width: '50%' }}
          placeholder="Pesquisar atendimento por paciente, profissional..."
          enterButton="Pesquisar"
          onSearch={onSearch}
          size="middle"
        />
        <br />
        <Button
          type="dashed"
          onClick={() => {
            history.push('/adicionar-atendimento');
          }}
          style={{ width: '100%!important' }}
          block
        >
          <PlusOutlined />
          Adicionar atendimento
        </Button>
        <br />
        <AtendimentosTable
          atendimentos={atendimentos}
          confirm={confirm}
          loading={loading}
          total={total}
          handleTableChange={handleTableChange}
        />
      </VerticalContainer>
    </>
  );
}
