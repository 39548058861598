import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  registerEntidadeRequest: ['entidade'],
  registerEntidadeSuccess: null,

  updateEntidadeRequest: ['entidade'],
  updateEntidadeSuccess: null,

  getAllEntidadeRequest: null,
  getAllEntidadeSuccess: ['entidade'],

  requestEntidadeFailure: ['error'],
});

const INITIAL_STATE = { entidade: [], loading: false, error: '' };

/**
 * REGISTER REQUESTS BELLOW
 */

const registerEntidadeRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const registerEntidadeSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * UPDATE REQUESTS BELLOW
 */

const updateEntidadeRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
});

const updateEntidadeSuccess = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: false,
});

/**
 * GET SERVICES
 */

const getAllEntidadeRequest = (state = INITIAL_STATE) => ({
  ...state,
  error: '',
  loading: true,
  entidade: [],
});

const getAllEntidadeSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  error: '',
  loading: false,
  entidade: action.entidade,
});

/**
 * Request falure
 */
const requestEntidadeFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_ENTIDADE_REQUEST]: registerEntidadeRequest,
  [Types.REGISTER_ENTIDADE_SUCCESS]: registerEntidadeSuccess,

  [Types.REQUEST_ENTIDADE_FAILURE]: requestEntidadeFailure,

  [Types.UPDATE_ENTIDADE_REQUEST]: updateEntidadeRequest,
  [Types.UPDATE_ENTIDADE_SUCCESS]: updateEntidadeSuccess,

  [Types.GET_ALL_ENTIDADE_REQUEST]: getAllEntidadeRequest,
  [Types.GET_ALL_ENTIDADE_SUCCESS]: getAllEntidadeSuccess,
});
