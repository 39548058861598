import React from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Alert, Button, Form, Input, Tooltip,
} from 'antd';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
export default function ProfissionalClinicaForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  viewMode = false,
}) {
  const [form] = Form.useForm();
  const onEdit = () => {
    setError();
  };
  return (
    <>
      {error && (
        <>
          <Alert
            message={error}
            type="error"
          />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={initialValues}
      >
        <Form.Item
          {...formItemLayout}
          name="registro"
          label={(
            <span>
              Registro&nbsp;
              <Tooltip title="Digite seu registro de profissional">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          )}
          rules={[
            {
              required: true,
              message: 'Registro é obrigatório!',
              whitespace: true,
            },
          ]}
        >
          <Input disabled={viewMode} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="profissional"
          label="Profissional"
          rules={[
            {
              required: true,
              message: 'Profissional é obrigatório!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="especialidade"
          label="Especialidade"
          rules={[
            {
              required: true,
              message: 'Especialidade é obrigatório!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
