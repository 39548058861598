import React, { useState } from "react";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Select, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { FormItemSelect } from "../../ant-wrapper/form-item";
import { registerUser } from "../../../services/users";

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const { Option } = Select;

export default function UserForm({
  onFinish,
  setError,
  error,
  initialValues = {},
  loading = false,
  isAdmin = false,
  isEdit = false,
  viewMode = false,
}) {
  const [form] = Form.useForm();
  const onEdit = () => {
    setError();
  };

  const { clinicas } = useSelector((state) => state.clinica);
  const [selectedRole, setSelectedRole] = useState(
    !!initialValues.clinica_id ? "CLINICA" : undefined
  );

  const handleUserRoleChange = (value) => {
    setSelectedRole(value);
  };

  return (
    <>
      {error && (
        <>
          <Alert message={error} type="error" />
          <br />
        </>
      )}
      <Form
        form={form}
        onChange={onEdit}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={initialValues}
      >
        <Form.Item
          {...formItemLayout}
          name="name"
          label={
            <span>
              Nome&nbsp;
              <Tooltip title="Digite seu nome de usuário">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
          rules={[
            {
              required: true,
              message: "Nome é obrigatório!",
              whitespace: true,
            },
          ]}
        >
          <Input disabled={viewMode} />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "Email inválido!",
            },
            {
              required: true,
              message: "Email é obrigatório!",
            },
          ]}
        >
          <Input disabled={isEdit} />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          name="type"
          label="Tipo"
          rules={[
            {
              required: true,
              message: "Tipo de usuário é obrigatório!",
            },
          ]}
          hasFeedback
        >
          <Select
            disabled={isAdmin || viewMode}
            onChange={handleUserRoleChange}
          >
            <Option value="ADM">Administrador</Option>
            <Option value="MARCADOR">Marcador</Option>
            <Option value="SUPERVISOR">Supervisor</Option>
            <Option value="CLINICA">Clínica</Option>
          </Select>
        </Form.Item>

        {selectedRole === "CLINICA" && (
          <FormItemSelect
            name="clinica"
            label="Clinica"
            items={clinicas}
            itemsLabel={["pessoa_juridica", "nome"]}
            stringfyValue
            viewMode={viewMode}
            required
          />
        )}

        {!viewMode && (
          <>
            {" "}
            <Form.Item
              {...formItemLayout}
              name="password"
              label="Senha"
              rules={[
                {
                  required: true,
                  message: "Senha é obrigatória!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="password_confirmation"
              label="Confirmação da senha"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Confirmação da senha é obrigatória!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      "As senhas informadas não são iguais!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
