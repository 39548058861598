import React, { useState } from 'react';

import { Button, Col, Form, Row } from 'antd';

import { FormItemCheckbox, FormItemRangePicker } from '../../../components/ant-wrapper/form-item';
import { gerarRelatorioProcedimentos } from '../../../services/relatorios';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 }
};

export default function RelatorioProcedimentos() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)

  const handleDistritos = async (values) => {
    setLoading(true);
    try{
      await gerarRelatorioProcedimentos(values);
    }finally{
      setLoading(false)
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Form
        form={form}
        name="register"
        onFinish={handleDistritos}
        scrollToFirstError
        {...formItemLayout}
      >
        <Row>
          <Col span={8}>
            <FormItemRangePicker
              name="datas"
              label="Período de datas solicitadas"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <FormItemCheckbox
            name='ordena_mes'
            checkboxLabel='Ordenar por mês'
            valuePropName = 'checked'
            />
          </Col>
        </Row>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            {!loading ? "Gerar relatórios dos procedimentos" : "Gerando relatório"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
